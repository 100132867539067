import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Headline, ButtonAction } from 'components/UI';
import { makeStyles } from '@mui/styles';

type pageOptionType = 'edit' | 'show' | 'new';

interface IProps {
  pageAction: pageOptionType | any;
  deleteHandler: () => void;
  pagePrefix: string; //  clients , projects, users ect.
}

const useStyles = makeStyles(() => ({
  headline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export { useStyles };

const HeadlineActions = ({ pageAction, deleteHandler, pagePrefix }: IProps) => {
  const navigate = useNavigate();
  const params = useParams<any>();
  const classes = useStyles();
  const { t } = useTranslation();
  if (pageAction === 'edit') {
    // @ts-ignore
    const id = params.id;
    return (
      <div className={classes.headline}>
        <Headline title={t('common.edit')} goBack={true} />
        <div>
          <ButtonAction type={'delete'} onClick={deleteHandler} />{' '}
          <ButtonAction
            type={'show'}
            onClick={() => navigate(`/${pagePrefix}/${id}?view=show`)}
          />
        </div>
      </div>
    );
  } else if (pageAction === 'show') {
    // @ts-ignore
    const id = params.id;
    return (
      <div className={classes.headline}>
        <Headline title={t('common.show')} goBack={true} />
        <div>
          <ButtonAction type={'delete'} onClick={deleteHandler} />{' '}
          <ButtonAction
            type={'edit'}
            onClick={() => navigate(`/${pagePrefix}/${id}?view=edit`)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.headline}>
        <Headline title={t('common.createNew')} goBack={true} />
      </div>
    );
  }
};

export default HeadlineActions;
