import axiosInstance from 'libs/axiosInstance';
import { enqueueSnackbar as enqueueSnackbarAction } from './notifierAction';
import { USER_ROLE } from '../../interfaces/enum';
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from './types';
import {
  IAuthFunction,
  IConfigHeaders,
} from '../../interfaces/store.interfaces';
import AuthService from 'shared/services/auth.services'; //eslint-disable-line

export const loadUserFromApi =
  () => (dispatch: Function, getState: Function) => {
    dispatch({ type: USER_LOADING });
    axiosInstance
      .get('/api/v1/users/me', tokenConfig(getState))
      .then((res) => {
        if (res.status !== 200) throw new Error('greska');
        if (res.data.roleId == USER_ROLE.CARETAKER)
          throw new Error('Caretaker nema pristup aplikaciji!');
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      })
      .catch((err) => {
        // dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: AUTH_ERROR,
        });
      });
  };

// Register User
export const register =
  ({ userName, email, password }: IAuthFunction) =>
  (dispatch: Function) => {
    // Headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // Request body
    const body = JSON.stringify({ userName, email, password });
    axiosInstance
      .post('/api/auth/register', body, config)
      .then((res) =>
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        })
      )
      .catch((err) => {
        // dispatch();
        // returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL')
        dispatch({
          type: REGISTER_FAIL,
        });
      });
  };

export const login =
  ({ userName, password }: IAuthFunction, history: any) =>
  async (dispatch: Function) => {
    try {
      const result = await axiosInstance.post('/api/v1/auth/login', {
        userName,
        password,
      });
      // console.log(result);
      if (result.status === 200 || result.status === 201) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: result.data.token,
        });
        dispatch(loadUserFromApi());
        history('/');
      }
    } catch (error: any) {
      dispatch(
        enqueueSnackbarAction({
          message: error.response ? error.response.data.msg : error.message,
          options: { variant: 'error' },
        })
      );
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

// Logout User
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

// Setup config/headers and token
export const tokenConfig = (getState: Function) => {
  const token = getState().auth.token;
  // Headers
  const config: IConfigHeaders = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};
