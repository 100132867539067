import React, { useState } from 'react';

import patientService from 'shared/services/patient.service';
import { CreateNewPatientInterface } from 'interfaces/patient.interfaces';
export const useCreatePatientHook = () => {
  const create = async (data: CreateNewPatientInterface, cb: Function) => {
    try {
      const res = await patientService.create(data);
    } catch (error) {}
  };
  return {
    create,
  };
};
