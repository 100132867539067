import BaseHttpService from './base-http.service';

const URL_PATH_PREFIX = 'users_role';

const RolesService = () => {
  const baseHttp = BaseHttpService();

  const all = async (params: any = null) => {
    const url = params ? `${URL_PATH_PREFIX}${params}` : URL_PATH_PREFIX;
    return await baseHttp.get(url);
  };

  const getById = async (id: number) => {
    const result = await baseHttp.get(`/${URL_PATH_PREFIX}/${id}`);
    return result;
  };
  const create = async (data: any) => {
    return await baseHttp.post(`/${URL_PATH_PREFIX}/create`, data);
  };
  const update = async (id: number, data: any) => {
    return await baseHttp.patch(`${URL_PATH_PREFIX}/${id}`, data);
  };
  const remove = async (id: number) => {
    return await baseHttp.remove(`${URL_PATH_PREFIX}/${id}`);
  };

  return {
    all,
    getById,
    create,
    update,
    remove,
  };
};

export default RolesService();
