export enum E_ERROR {
  LOGIN_FAIL = 'LOGIN_FAIL',
  REGISTER_FAIL = 'REGISTER_FAIL',
}

export enum USER_ROLE {
  ADMIN = 1,
  MAIN_DOCTOR = 2,
  DOCTOR = 3,
  CARETAKER = 4,
  MEDICAL_STUFF = 5,
}

export enum IndicatorTypeEnum {
  NUMERIC = 1,
  DESCRIPTIVE = 2,
}

export enum Roles {
  MAIN_DOCTOR = 'MAIN_DOCTOR',
  DOCTOR = 'DOCTOR',
  MEDICAL_STUFF = 'MEDICAL_STUFF',
}
