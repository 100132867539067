import React from 'react'
import { useStyles } from './styles'

interface IProps {
    onChange?:(e:React.FormEvent<HTMLSelectElement>) => any
    options?:any
    name?:any
    index?:string
    title?:any
    value?:any
    label?:string
    disabled?:boolean,
    error?:boolean,
    errorText?:string | undefined
}
const titleFormated = (option:any, title:any) => {
    let output = '';
    if(typeof title === 'object' && title.length > 0) {
       title.forEach((item:any) => {
           output += `${option[item]} `;
        })
    }
    else {
        output =  title;
    }
    return output;
}

const SelectList =({onChange,options, name, index, title, value, label, disabled, error, errorText}:IProps) => {
    const classes =  useStyles()
    if(!options && options.length === 0) return <span></span>
    return (
        <div className={classes.formGroup}>
        {label && <label className={classes.label} >{label}</label> }
        <select onChange={onChange}  value={value} name={name} style={{minHeight:'35px'}} className={classes.input}   disabled={disabled}>
                <option  value=""></option>
                {options && options.length > 0 &&
                    options.map((option:any, i:number) => {
                        if(!index)  return null;
                        return <option key={i} value={option[index]}>{titleFormated(option, title)}</option>
                    })
                }
            </select>
            { error && errorText && <span className={classes.error}>{errorText}</span> }
    </div>
    )

}


export default SelectList