import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Input } from 'components/UI';
import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { sidemodalExpand } from 'store/actions/globalActions';
import { toggleModal, handleModalReset } from 'store/actions/confirmModalActions';


const validationSchema = yup.object({
  comment: yup
    .string()
    .required('Komentar je obavezan!')
    .max(1255, 'Maksimalno karaktera 255'),
});

const initialValues = {
  comment: '',
};

interface IProps {
  setOpen?: any;
  onSubmitHandler: (data: any, formik: any, setOpen: Function) => void;
  label?: string;
  comment?: string;
}

const CloseTreatmentForm: React.FC<IProps> = ({
  onSubmitHandler,
  setOpen,
  label,
  comment,
}) => {
  const dispatch = useDispatch();
  initialValues.comment = comment || '';
  const { confirmActionName, confirmed, values } = useSelector((store: any) => store.confirmModal || null);

  useEffect(() => {
    if (confirmed && confirmActionName === 'closeTreatmant') {
      onSubmitHandler(values.data, values.formik, setOpen);
      dispatch(handleModalReset());
    }
  }, [confirmed])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: async (values, formik) => {
      const data = { ...values };
      dispatch(toggleModal(true, 'Da li ste sigurni da želite da zatvorite tretman?', 'closeTreatmant', {
        data,
        formik
      }));
    },
  });
  return (
    <div className={styles.wrapper}>
    <h2>Zaključenje lečenja</h2>
    <div className={styles.divider} />
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <Input
            label={label || `Završni komentar lečenja`}
            type='textarea'
            onChange={formik.handleChange}
            name={'comment'}
            value={formik.values.comment}
            disabled={false}
            error={formik.touched.comment && Boolean(formik.errors.comment)}
            errorText={formik.touched.comment && formik.errors.comment}
          />
        </div>
        <div className={styles.buttons}>
         
            <button className='btn' onClick={() => {
              dispatch(sidemodalExpand(false));
            }}>
              Otkaži
            </button>
            <button className='btn' type='submit' >  
              Zaključi
            </button>
        </div>
      </div>
    </form>
    </div>
  );
};

export default CloseTreatmentForm;
