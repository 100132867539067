import { combineReducers } from 'redux';
import authReducer from './authReducer';
import notifierReducer from './notifierReducer';
import patientReducer from './patientReducer';
import treatmentReducer from './treatmentReducer';
import diagnosesReducer from './diagnosesReducer';
import themeReducer from './themeReducer';
import globalReducer from './globalReducer';
import indicatorReducer from './indicatorReducer';
import usersReducer from './usersReducer';
import confirmModalReducer from './confirmModalReducer';

export default combineReducers({
  auth: authReducer,
  notifications: notifierReducer,
  patient: patientReducer,
  treatment: treatmentReducer,
  diagnoses: diagnosesReducer,
  theme: themeReducer,
  global: globalReducer,
  indicator: indicatorReducer,
  users: usersReducer,
  confirmModal: confirmModalReducer,
});
