import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./style.module.scss";
import { toggleModal, handleModalSuccess } from "store/actions/confirmModalActions";

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const { message, opened } = useSelector((store: any) => store.confirmModal);
  return (
    opened && (
      <div className={styles.modalWrapper}>
        <div className={styles.modalWrapper__inner}>
          <div className={styles.modalWrapper__inner__content}>
            <h3>{message}</h3>
            <div style={{ display: 'flex'}}>
            <button
              className='btn close-confirm-modal-btn'
              style={{ marginRight: 0}}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(toggleModal(false, ''))
              }}
            >
              Otkaži
            </button>
            <button
              className='btn'
              style={{ marginLeft: 16}}
              onClick={() => {
                dispatch(handleModalSuccess());
              }}
            >
              Potvrdi
            </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ConfirmModal;
