import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import projectsService from 'shared/services/projects.service';
import Layout from 'components/layout';
import { ConfirmationModal } from 'components/UI';
import { Button, Grid, Paper } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';
import FormFields from './FormFields';
import HeadlineActions from 'components/common/HeadlineActions';
import userService from 'shared/services/user.service';

//*TODO - must finish validation
const requiredString = 'Polje je obavezno';
const maxLengthMessage = (num: number) =>
  `Polje mora da sadrzi manje od ${num} karaktera!`;
const validationSchema = yup.object({
  userName: yup.string().required(requiredString),
  firstName: yup
    .string()
    .max(255, maxLengthMessage(255))
    .required(requiredString),
  lastName: yup
    .string()
    .max(255, maxLengthMessage(255))
    .required(requiredString),
  phone: yup.string().max(255, maxLengthMessage(255)),
  password: yup.string().max(255, maxLengthMessage(255)).required(),
  roleId: yup.number().required(requiredString),
  licenceNumber: yup.string().max(50),
});

const initialValues = {
  userName: '',
  firstName: '',
  lastName: '',
  phone: '',
  roleId: '',
  password: '',
  licenceNumber: '',
};

const replacedNullableFields = (data: any) => {
  if (Object.keys(data).length > 0) {
    let output: any = {};
    for (let key in data) {
      if (data[key] === null) {
        output[key] = '';
      } else {
        output[key] = data[key];
      }
    }
    return output;
  }
};

const FormsProjects = () => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams<{ id: any }>();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>(initialValues);
  const pageAction: string = location.search.split('=')[1];
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  useEffect(() => {
    if (pageAction === 'show' || pageAction === 'edit') {
      fetchFormData();
    } else if (pageAction === 'new' || pageAction === undefined) {
      setData(initialValues);
    } else {
      fetchFormData();
    }
  }, []); //eslint-disable-line

  const fetchFormData = async () => {
    try {
      //@ts-ignore
      const id = params.id ? params.id : false;
      //@ts-ignore
      if (!id) return;
      const response = await userService.getById(id);

      if (response.status === 200) {
        delete response.data.createdAt;
        delete response.data.updatedAt;
        let formatedData = replacedNullableFields(response.data);
        delete formatedData.deletedAt;
        formatedData = {
          ...formatedData,
          licenceNumber:
            (formatedData &&
              formatedData.doctor &&
              formatedData.doctor.licenceNumber) ||
            '',
        };
        setData(formatedData);
      }
    } catch (error: any) {
      dispatch(
        enqueueSnackbarAction({
          message: error.message,
          options: { variant: 'error' },
        })
      );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const data = { ...values };
      data.roleId = Number(values.roleId);
      data.licenceNumber = Number(values.licenceNumber);
      if (pageAction === 'edit') {
        updateClient(data);
      } else {
        createNew(data);
      }
    },
  });

  const createNew = async (values: any) => {
    try {
      const response = await userService.create(values);
      if (response.status === 201) {
        navigate(`/osoblje`);
        setData(initialValues);
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspesno ste dodali doktora!',
            options: { variant: 'success' },
          })
        );
      } else {
        dispatch(
          enqueueSnackbarAction({
            message: response.data.mgs,
            options: { variant: 'error' },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        enqueueSnackbarAction({
          message: error.message,
          options: { variant: 'error' },
        })
      );
    }
  };
  const updateClient = async (values: object) => {
    try {
      const response = await userService.update(data.id, values);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: 'Succesfully updated Project!',
            options: { variant: 'success' },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

  const deleteHandler = async () => {
    try {
      const response = await userService.remove(params.id);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: 'Succesfully deleted Project!',
            options: { variant: 'success' },
          })
        );
        navigate('/osoblje');
      }
      if (response.data.error) {
        dispatch(
          enqueueSnackbarAction({
            message: response.data.error,
            options: { variant: 'error' },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        enqueueSnackbarAction({
          message: error.message,
          options: { variant: 'error' },
        })
      );
    }
  };
  const submitButton = () => {
    if (pageAction === 'edit' || pageAction === 'new') {
      return (
        <Button variant='contained' color='secondary' type='submit'>
          {pageAction === 'edit' ? 'Update' : 'Save'}
        </Button>
      );
    } else if (pageAction === undefined) {
      return (
        <Button variant='contained' color='secondary' type='submit'>
          {'Save'}
        </Button>
      );
    } else {
      return (
        <Button variant='contained' color='secondary' type='submit'>
          {'Save'}
        </Button>
      );
    }
  };

  const onChangeCb = useCallback(
    (e) => {
      formik.handleChange(e);
    },
    [formik, formik.handleChange] //eslint-disable-line
  ); //eslint-disable-line

  return (
    <Layout>
      <HeadlineActions
        pageAction={pageAction}
        pagePrefix={'osoblje'}
        deleteHandler={() => setOpenDeleteModal(true)}
      />
      <ConfirmationModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        confirmHandler={(status) => {
          if (status) deleteHandler();
        }}
      />
      <Paper style={{ background: 'transparent' }}>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          className={classes.form}
        >
          <Grid container>
            <FormFields
              data={formik.values}
              formik={formik}
              handleChange={onChangeCb}
              disabled={pageAction === 'show' ? true : false}
              pageAction={pageAction}
            />
            <Grid item sm={12}>
              {submitButton()}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Layout>
  );
};

export default FormsProjects;
