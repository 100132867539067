import jwtDecode from 'jwt-decode';

class Auth {
  public token: string | any;
  private user: any;
  constructor() {
    this.token = window.localStorage.getItem('token') || null;
    this.parseToken();
  }
  parseToken() {
    let decodedData: any = null;

    if (this.token) {
      decodedData = jwtDecode(this.token);
      this.user = decodedData;
    }
  }
  currentUser() {
    if (this.user) {
      return this.user;
    } else {
      return null;
    }
  }
  isAuthorized() {
    if (this.token) {
      return true;
    } else {
      return false;
    }
  }
}

export default Auth;
