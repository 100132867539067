import styles from "./style.module.scss";
import * as yup from "yup";
import { useFormik, Field } from "formik";
import { useDispatch } from "react-redux";
import { useCreatePatientHook } from "./useCreatePatientHook";
import { Input } from "components/UI";
import { CreateNewPatientInterface } from "../../../../interfaces/patient.interfaces";
import { addNewPatient } from "store/actions/patientAction";
import * as treatmentActions from 'store/actions/treatmentActions';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("Ime je obavezno")
    .max(255, "Maksimalno karaktera 255"),
  lastName: yup
    .string()
    .required("Prezime je obavezno")
    .max(255, "title must be at less 255  characters"),
  middleName: yup
    .string()
    .required("Srednje ime je obavezno")
    .max(255, "title must be at less 255  characters"),
  dateOfBirth: yup
    .string()
    // .required('Datum rodjena je obavezan')
    .required()
    .max(255, "title must be at less 255  characters"),
});



const CreatePatientV2 = ({
  edit = false,
  data
}: any) => {
  const dispatch = useDispatch();
  const patientHook = useCreatePatientHook();

  const initialValues: CreateNewPatientInterface = {
    firstName: edit ? data.firstName : "",
    lastName: edit ? data.lastName : "",
    middleName: edit ? data.middleName : "",
    dateOfBirth: edit ? data.dateOfBirth : "",
    gender: edit ? data.gender : "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,

    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      let dataToSend: any = { ...values };
      if (edit) {
        dataToSend = {
          ...dataToSend,
          id: data.id
        }
        dispatch(treatmentActions.updatePatient(dataToSend));
        return;
      }
      if (values) dispatch(addNewPatient(dataToSend));
      resetForm();
    },
  });
  return (
    <div className={styles.wrapper}>
      <h2>{edit ? 'Izmena pacijenta' : 'Novi Pacijent'}</h2>
      <div className={styles.divider}></div>
      <div className={styles.wrapper__formWrapper}>
        <form onSubmit={formik.handleSubmit}>
          <Input
            label="Ime"
            type="text"
            onChange={formik.handleChange}
            name={"firstName"}
            value={formik.values.firstName}
            disabled={false}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            errorText={formik.touched.firstName && formik.errors.firstName}
          />
          <Input
            label="Prezime"
            type="text"
            onChange={formik.handleChange}
            name={"lastName"}
            value={formik.values.lastName}
            disabled={false}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            errorText={formik.touched.lastName && formik.errors.lastName}
          />
          <Input
            label="Ime roditelja"
            type="text"
            onChange={formik.handleChange}
            name={"middleName"}
            value={formik.values.middleName}
            disabled={false}
            error={
              formik.touched.middleName && Boolean(formik.errors.middleName)
            }
            errorText={formik.touched.middleName && formik.errors.middleName}
          />
          <Input
            type={"date"}
            label="Datum rodjena"
            style={{ width: "170px" }}
            onChange={formik.handleChange}
            name={"dateOfBirth"}
            value={formik.values.dateOfBirth}
            disabled={false}
            error={
              formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
            }
            errorText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
          />
          <div className="formControl">
            <label>Pol:</label>
            <div>
              <label
                className="radioLabel"
                htmlFor="muski"
                style={{ marginRight: "10px" }}
              >
                <input
                  // onChange={() => formik.setFieldValue('gender', 'M')}
                  onChange={(e) => {
                    formik.setFieldValue("gender", "M");
                  }}
                  id={"muski"}
                  type="radio"
                  name="gender"
                  value={"M"}
                  checked={formik.values.gender === "M"}
                />
                <div className="custom"></div>
                {"Muški"}
              </label>

              <label htmlFor="zenski" className="radioLabel">
                <input
                  onChange={(e) => {
                    formik.setFieldValue("gender", "F");
                  }}
                  id={"zenski"}
                  type="radio"
                  name="gender"
                  value={"F"}
                  checked={formik.values.gender === "F"}
                />
                <div className="custom"></div>
                {"Ženski"}
              </label>
            </div>
          </div>
          
          <button className="btn" type="submit">
            {edit ? 'Izmeni' : 'Kreiraj'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreatePatientV2;
