import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Layout from '../../../components/layout';
// import { Headline } from '../../../components/UI';
import { useTranslation } from 'react-i18next';
import treatmemtServices from 'shared/services/treatments.services';
import Table from '../../../components/common/Table';
import useUrlQueryHook from 'shared/hooks/useUrlQueryHook';
// import { ButtonAction } from 'components/UI';
// import FilterTable from 'components/common/FilterTable/FilterTable';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import Moment from 'moment';
import styles from './style.module.scss';
import CreatePatient from './CreatePatient';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PatientForm from 'components/layout/PatientForm';
import Loader from 'components/UI/Loader';
import { setActiveModal, sidemodalExpand } from 'store/actions/globalActions';
import { fetchClients } from 'store/actions/treatmentActions';
import { socketClient } from 'libs/socketIoClient';
import WarningIcon from 'components/UI/WarningIcon';
// const filters = {
//   id: { label: 'ID', value: '' },
//   email: { value: '' },
//   Straße: { label: 'Straße', value: '' },
//   Postleitzahl: { label: 'Postleitzahl', value: '' },
//   title: { label: 'BVH Kurzbezeichnung', value: '' },
// };

const ActivePatientsList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const auth = useSelector((state: any) => state.auth);
  const [formOpened, setFormOpened] = useState(false);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const navigate = useNavigate();
  const treatments = useSelector((store: any) => store.treatment.patients);
  const paginate = useSelector((store: any) => store.treatment.paginate);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { urlQuery, setUrlQueryHandler } = useUrlQueryHook({
    page: '1',
    limit: '10',
    orderBy: 'actualScore',
    direction: 'DESC',
    // orderBy: 'BVHNummer',
  });
  console.log(urlQuery, 'urlQu');
  const [pagination, setPagination] = useState({});
  useEffect(() => {
    if (auth && auth.user) {
      socketClient.on(
        `refreshListOfActivePatients_${auth.user.officeId}`,
        (data) => {
          console.log('refresh list');
          dispatch(fetchClients(urlQuery));
        }
      );
    }
  }, [auth]);
  //@ts-ignore
  useEffect(() => {
    dispatch(fetchClients(urlQuery));
    // fetchClients();
    // return () => {
    //   setUrlQueryHandler(page:1)
    // };
  }, [urlQuery]); //eslint-disable-line

  const headers = useMemo(() => {
    return {
      id: {
        headerName: 'R.b.',
        // sortable: true,
        cellCallback: (params: any, index: number) => {
          return index + 1;
        },
      },
      ['patient.firstName']: {
        headerName: 'Ime i prezime',
        // sortable: true,
        cellCallback: (params: any) => {
          const { firstName, lastName, middleName } = params.patient;
          return `${firstName} ${middleName && `(${middleName})`} ${lastName}`;
        },
      },
      ['starost']: {
        headerName: 'Starost',
        cellCallback: (params: any) => {
          const { dateOfBirth } = params.patient;
          const year = Moment().diff(dateOfBirth, 'years');
          return year;
        },
      },
      ['started']: {
        headerName: 'Početak lečenja',
        cellCallback: (params: any) => {
          const { started } = params;
          return Moment(started).format('DD.MM.YYYY. HH:mm');
        },
      },

      ['pda']: {
        headerName: 'Dijagnoza',
        cellCallback: (params: any) => {
          return params.diagnose.shortName;
        },
      },
      ['zadnijiunos']: {
        headerName: 'Zadnji status',
        cellCallback: (params: any) => {
          const { scoreUpdatedAt } = params;
          const time = scoreUpdatedAt
            ? Moment(scoreUpdatedAt).format('DD.MM.YYYY. HH:mm')
            : '-';
          return time;
        },
      },
      worning: {
        headerName: ' ',
        cellCallback: (params: any) => {
          return Number(params.actualScore) >= 40 ? (
            <div
              className={`${styles.warningIcon} ${
                params.actualScore < 70 ? styles.warning : styles.error
              }`}
            >
              <WarningIcon />
            </div>
          ) : (
            <span />
          );
        },
      },

      ['actualScore']: {
        headerName: 'Skor rizika',
        sortable: true,
        cellCallback: (params: any) => {
          const color =
            params.actualScore < 40
              ? '#b7e898'
              : params.actualScore >= 40 && params.actualScore < 70
              ? 'yellow'
              : '#ffa8a8';
          return (
            <div style={{ position: 'relative' }}>{params.actualScore}</div>
          );
        },
      },
      ['lekar']: {
        headerName: 'Otvorio',
        cellCallback: (params: any) => {
          const { firstName, lastName } = params.doctor.user;
          return `${firstName}  ${lastName}`;
        },
      },
      ['hasAnyoneSee']: {
        headerName:
          '<div style="font-size:11px;max-width:50px;display:block;">Staratelj Aktiviran?</div>',
        cellCallback: (params: any) => {
          return params.patient &&
            params.patient.takeCare &&
            params.patient.takeCare.length > 0
            ? 'DA'
            : 'NE';
        },
      },
    };
  }, []); //eslint-disable-line

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Stack direction='row' spacing={2}>
          <button
            className={`${styles.button} ${styles.newPatientButton} btn`}
            onClick={() => {
              dispatch(sidemodalExpand(true));
              dispatch(setActiveModal('create-patient-form'));
            }}
          >
            Kreiraj novog pacijenta
          </button>
          <button
            className={`${styles.button} btn`}
            onClick={() => {
              dispatch(setActiveModal('create-new-treatmant'));
              dispatch(sidemodalExpand(true));
            }}
          >
            Otvori lečenje
          </button>
          {
            <div>
              <div
                className={styles.newModalWrapper}
                style={{ display: `${openPatientModal ? 'block' : 'none'}` }}
              >
                <div className={styles.newPatientModal}>
                  <div className={styles.patientModalHeader}>
                    Novi Pacijent
                    <CancelOutlinedIcon
                      onClick={() => setOpenPatientModal(false)}
                    />
                  </div>
                  <div className={styles.patientModalContent}>
                    <CreatePatient close={() => setOpenPatientModal(false)} />
                  </div>
                </div>
              </div>
            </div>
          }
        </Stack>
      </div>
      <div className={styles.patientsLayoutWrapper}>
        <div className={styles.patientsLayoutWrapper__table}>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              data={treatments}
              headers={headers}
              columnClickHandler={(data: any) => {
                //console.log(data);
              }}
              rowClickHandler={(data: any) => {
                navigate('/treatment/' + data.id);
                //console.log(data);
              }}
              singlePageRedirection={true}
              sortTableHandler={(data) => {
                setUrlQueryHandler(data);
              }}
              pagination={paginate}
              changePage={(page) => {
                setUrlQueryHandler({ page });
              }}
            />
          )}
        </div>
        <div
          className={`${styles.patientsLayoutWrapper__form} ${
            formOpened ? styles.opened : ''
          }`}
        ></div>
      </div>
    </>
  );
};

export default ActivePatientsList;
