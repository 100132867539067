import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { Input, SelectList } from 'components/UI';

import rolesService from 'shared/services/roles.service';
import { useNavigate } from 'react-router-dom';
import { USER_ROLE } from 'interfaces/enum';

interface IProps {
  data: any;
  formik: any;
  handleChange: (e: any) => void;
  disabled: boolean;
  pageAction: string;
}
const FormFieldsUser = ({
  data,
  formik,
  handleChange,
  disabled,
  pageAction,
}: IProps) => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  useEffect(() => {
    fetchClients();
  }, []);
  const fetchClients = async () => {
    try {
      const response = await rolesService.all();

      if (response.status === 201 || response.status === 200) {
        setClients(response.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const doctorRoleField = () => {
    if (pageAction === undefined || USER_ROLE.DOCTOR)
      return (
        <Grid item sm={12} xs={12}>
          <Input
            label={'Broj Licence'}
            onChange={handleChange}
            name={'licenceNumber'}
            value={data.licenceNumber}
            disabled={disabled}
            error={
              formik.touched.licenceNumber &&
              Boolean(formik.errors.licenceNumber)
            }
            errorText={
              formik.touched.licenceNumber && formik.errors.licenceNumber
            }
          />
        </Grid>
      );
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Input
          label={'E mail'}
          onChange={handleChange}
          name={'userName'}
          value={data.userName}
          disabled={disabled}
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          errorText={formik.touched.userName && formik.errors.userName}
        />
      </Grid>

      <Grid item sm={12} xs={12}>
        <Input
          label='Ime'
          type='text'
          onChange={handleChange}
          name={'firstName'}
          value={data.firstName}
          disabled={disabled}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          errorText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Input
          label={'Prezime'}
          onChange={handleChange}
          name={'lastName'}
          value={data.lastName}
          disabled={disabled}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          errorText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Input
          label={'Telefon'}
          onChange={handleChange}
          name={'phone'}
          value={data.phone}
          disabled={disabled}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          errorText={formik.touched.phone && formik.errors.phone}
        />
      </Grid>
      {pageAction === undefined && (
        <Grid item sm={12} xs={12}>
          <Input
            label={'Sifra'}
            onChange={handleChange}
            name={'password'}
            value={data.password}
            disabled={disabled}
            error={formik.touched.password && Boolean(formik.errors.password)}
            errorText={formik.touched.password && formik.errors.password}
          />
        </Grid>
      )}

      <Grid item sm={12}>
        {data && data.role && <h4>{data.role.roleName}</h4>}
        <SelectList
          label='Rola'
          onChange={(e) => handleChange(e)}
          name={'roleId'}
          index={'id'}
          title={['roleName']}
          value={data.roleId}
          disabled={disabled}
          options={clients}
        />
      </Grid>
      {doctorRoleField()}
    </Grid>
  );
};

export default FormFieldsUser;
