import { memo } from 'react';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  icon: {
    color: '#4268F6',
    padding: '10px',
    margin: '5px',
    marginRight: '10px',
    border: '1px solid #4268F6',
    borderRadius: '50%',
    height: '35px',
    width: '35px',
    cursor: 'pointer',
    transition: '500ms all',
    '&:hover': {
      backgroundColor: '#4268F6',
      color: '#fff',
    },
  },
});

const BackButton = memo(({ onClick }: any) => {
  const classes = useStyles();
  return (
    <ArrowBackIosSharpIcon
      className={classes.icon}
      width={200}
      height={200}
      onClick={onClick}
    />
  );
});

export default BackButton;
