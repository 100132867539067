import React, { useState, memo } from 'react';
// import { Link as RouterLink } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, IconButton, Toolbar, Hidden } from '@mui/material'; //eslint-disable-line
import MenuIcon from '@mui/icons-material/Menu';
//import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import Logo from '../Logo';
import ProfileBar from '../ProfileBar';
import Button from '@mui/material/Button';
import { NavBar } from '../NavBar/NavBar';
import styles from './styles.module.scss';
import Search from '../../../common/Search';
import ThemeSwitcher from '../../../UI/ThemeSwitcher';
interface Props {
  className?: any;
  handleDrawerOpen: () => void;
  open: boolean;
  treatmentPage?: boolean;
  rest?: any;
}

const TopBar: React.FC<Props> = ({
  className,
  handleDrawerOpen,
  open,
  treatmentPage,
  ...rest
}) => {
  const { i18n } = useTranslation();

  const classes = useStyles();
  //eslint-disable-next-line
  const [notifications] = useState([]);
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <NavBar />
      <div
        className={`${styles.topBar} ${
          treatmentPage ? styles.treatmantLayout : ''
        }`}
      >
        <div>{/* <Search /> */}</div>

        <div className={styles.topBar__user}>
          <div className={styles.themeSwitcherWrapper}>
            <ThemeSwitcher />
          </div>
          <ProfileBar />
        </div>
      </div>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default memo(TopBar);
