import { makeStyles } from '@mui/styles';
const drawerWidth = 250;
const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      background: 'green',
    },
    avatar: {
      width: 60,
      height: 60,
      maxHeight: '100%',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      display: 'flex',
      justifyContent: 'space-between',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: theme.palette.info.main,
      maxHeight: '64px',

      color: '#263238',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      width: '100%',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: '#fff',
      color: '#263238',
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    langBtn: {
      color: '#263238',
      minWidth: '40px',
      background: 'green',
    },
  };
});

export { useStyles };
