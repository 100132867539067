import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccountCircle } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom'; //eslint-disable-line
import AuthService from 'shared/services/auth.services';
import Auth from 'utils/auth-utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/actions/authActions';
import styles from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
  },
  userModal: {
    top: '37px',
  },
}));

const ProfileBar = () => {
  const user = useSelector((store: any) => store.auth.user || []);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authService = AuthService();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    // Todo move this logic into Redux;
    await authService.signout();
    dispatch(logout());
    navigate('/signin');
  };
  return (
    <div className={classes.root}>
      <div className={styles.profileBar}>
        <div onClick={handleMenu}>
          <span className={styles.triangle} />
          <span>
            {user.firstName} {user.lastName}
          </span>
        </div>
        <div>
          <IconButton
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            color='inherit'
          >
            <AccountCircle style={{ color: 'gray', fontSize: '48px' }} />
          </IconButton>
        </div>
      </div>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>My Profile</MenuItem> */}
        {new Auth().isAuthorized() && (
          <MenuItem onClick={handleLogout}> Logout</MenuItem>
        )}
        <MenuItem
          onClick={() => {
            navigate('/profile/change-password');
          }}
        >
          {' '}
          {t('common.change_password')}{' '}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileBar;
