import React, { memo } from 'react';
import BackButton from '../BackButton';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
interface Props {
  title: string;
  description?: string;
  goBack?: boolean;
}
const Headline: React.FC<Props> = ({ title, description, goBack = false }) => {
  const navigate = useNavigate();
  return (
    <div data-test='headlineComponent' style={{ display: 'inline-block' }} className={styles.headlineComponent}>
      <h1 data-testid='title' style={{ display: 'flex', alignItems: 'center' }}>
        {goBack && <BackButton onClick={() => navigate(-1)} />}
        {title}
      </h1>

      {description && <p data-testid='description'>{description}</p>}
    </div>
  );
};

export default memo(Headline);
