import React, { useEffect, useState } from 'react';
import PatientInfoSectionV2 from './components/PatientInfoSectionV2';
import Layout from 'components/layout/AppLayout';
import { Grid } from '@mui/material';
import TreatmentInfoSectionV2 from './components/TreatmentInfoSectionV2';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSingleTreatment,
  getTreatmentByIdFull,
} from 'store/actions/treatmentActions';
import { useParams } from 'react-router-dom';
import styles from './style.module.scss';

import IndicatorsSectionV2 from './components/IndicatorsSectionV2';

const TreatmentSingleView = () => {
  const treatment = useSelector((store: any) => store.treatment.selected);
  const [formExpanded, setFormExpanded] = useState(false);
  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) dispatch(getTreatmentByIdFull(Number(id)));
    return () => {
      dispatch(clearSingleTreatment());
    };
  }, [id]);

  return (
    <Layout>
      <PatientInfoSectionV2 data={treatment.patient} />
      <TreatmentInfoSectionV2 patientData={treatment.patient} />
      <div
        className={`${styles.grid} ${formExpanded ? styles.formExpanded : ''}`}
        style={{ position: 'relative' }}
      >
        <div className={`${styles.tableGrid}`} style={{  overflow: 'hidden' }}>
          <div className='formControl' style={{ position: 'static' }}>
            <label>Indikatori stanja pacijenta:</label>
            <IndicatorsSectionV2
              indicatorValues={treatment.indicatorValues}
              indicators={treatment.indicators}
              setFormExpanded={setFormExpanded}
              formExpanded={formExpanded}
            />
          </div>
        </div>
        <div></div>
      </div>
      <div></div>
      <div></div>
      <Grid container spacing={2}></Grid>
    </Layout>
  );
};

export default TreatmentSingleView;
