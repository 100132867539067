import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useStyles } from './styles';
import styles from './styles.module.scss';

type DirectionType = 'ASC' | 'DESC';
interface ISortHandler {
  orderBy?: string;
  direction: DirectionType;
}

interface IProps {
  orderBy: string;
  sortTableHandler?: (data: ISortHandler) => void;
}

const TableSorter: React.FC<IProps> = ({
  orderBy,
  sortTableHandler,
}: IProps) => {
  const [direction, setDirection] = useState<String>('DESC');
  const classes = useStyles();

  const onClickHandler = (newDirection: DirectionType): void => {
    setDirection(newDirection);
    const data = {
      direction: newDirection,
      orderBy,
    };
    if (typeof sortTableHandler !== 'undefined') sortTableHandler(data);
  };
  return (
    <div className={`table-sorter`}>
      {direction === 'ASC' ? (
        <KeyboardArrowUp
          className={`${classes.arrow} ${styles.arrow}`}
          onClick={() => onClickHandler('DESC')}
        />
      ) : (
        <KeyboardArrowDown
          className={`${classes.arrow} ${styles.arrow}`}
          onClick={() => onClickHandler('ASC')}
        />
      )}
    </div>
  );
};

export default TableSorter;
