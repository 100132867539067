import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DvrIcon from '@mui/icons-material/Dvr';
import TocIcon from '@mui/icons-material/Toc';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '130px',
    padding: '4px 10px',
    fontSize: '12px',
  },
  titleWrapp: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '17px',
    marginRight: '10px',
  },
}));

// eslint-disable-next-line react/prop-types
const ButtonAction = ({
  children,
  className,
  text,
  onClick,
  type,
  ...props
}: any) => {
  const classes = useStyles();

  return (
    <Button
      data-test='button'
      className={classes.root}
      onClick={onClick}
      variant='outlined'
      color='secondary'
      {...props}
    >
      <ButtonTitle type={type} text={text} />
    </Button>
  );
};

const ButtonTitle = ({ type, text }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (type === 'edit') {
    return (
      <span className={classes.titleWrapp}>
        <EditIcon className={classes.icon} /> {t('common.edit')}
      </span>
    );
  } else if (type === 'delete') {
    return (
      <span className={classes.titleWrapp}>
        <DeleteForeverIcon className={classes.icon} /> {t('common.delete')}
      </span>
    );
  } else if (type === 'filter') {
    return (
      <span className={classes.titleWrapp}>
        <TuneIcon className={classes.icon} /> {t('common.filter')}
      </span>
    );
  } else if (type === 'new') {
    return (
      <span className={classes.titleWrapp}>
        <AddIcon className={classes.icon} /> {t('common.createNew')}
      </span>
    );
  } else if (type === 'show') {
    return (
      <span className={classes.titleWrapp}>
        <DvrIcon className={classes.icon} /> {t('common.show')}
      </span>
    );
  } else if (type === 'pdf') {
    return (
      <span className={classes.titleWrapp}>
        <PictureAsPdfIcon className={classes.icon} />{' '}
        {t('buttons.transferToPdf')}
      </span>
    );
  } else if (type === 'excel') {
    return (
      <span className={classes.titleWrapp}>
        <TocIcon className={classes.icon} /> {t('common.show')}
      </span>
    );
  } else {
    return text;
  }
};

export default ButtonAction;
