import React, { useState } from 'react';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import styles from './style.module.scss';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';
import { RoughNotation } from 'react-rough-notation';
import DeleteIcon from 'components/UI/DeleteIcon';
import { useDispatch } from 'react-redux';

interface IProps {
  items: any;
  selectedItems: any[];
  selected: any;
  setSelected: (data: any) => void;
  onDelete: (data: any) => void;
  onClick: (data: any) => void;
  setPrimary: (data: any) => void;
}
const AutoCompleteWithChip: React.FC<IProps> = ({
  items,
  selectedItems,
  selected,
  setSelected,
  onDelete,
  onClick,
  setPrimary,
  classes,
}: any) => {
  const dispatch = useDispatch();
  if (!items || items.length === 0) return <span />;
  return (
    <div style={{ width: '100%' }} className={classes}>
      <div className={styles.diagnosesResult}>
        {selectedItems &&
          selectedItems.length > 0 &&
          selectedItems.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={styles.diagnoseChip}
                style={{ color: '#000' }}
              >
                <div className={styles.chipContent}>
                  <div
                    onClick={() => {
                      setPrimary(item.id);
                    }}
                  >
                    <RoughNotation
                      type='underline'
                      show={item.primary}
                      color='#3fc3d0'
                      animationDelay={200}
                    >
                      {' '}
                      {item.diagnosis.shortName}
                    </RoughNotation>
                  </div>
                  <div
                    className={styles.remove}
                    onClick={() => {
                      if (selectedItems.length < 2) {
                        dispatch(
                          enqueueSnackbarAction({
                            message: 'Mora postojati bar jedna dijagnoza.',
                            options: { variant: 'error' },
                          })
                        );
                      } else if (item.primary) {
                        dispatch(
                          enqueueSnackbarAction({
                            message: 'Ne možete obrisati primarnu dijagnozu.',
                            options: { variant: 'error' },
                          })
                        );
                      } else {
                        onDelete(item.id);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className={styles.autocompleteWrapper}>
        <Autocomplete
          getOptionLabel={(option: any) => option.shortName}
          options={items}
          onChange={(el, value) => {
            setSelected(value);
          }}
          className={styles.autocomplete}
          style={{ width: '100%' }}
          renderInput={(params: any) => {
            return <TextField {...params} variant='standard' />;
          }}
        />
        <button
          className={`${styles.btn} btn`}
          onClick={onClick}
          disabled={!selected}
        >
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default AutoCompleteWithChip;
