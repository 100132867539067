import Layout from 'components/layout/AppLayout';
import { Headline } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { ActivePatientsList } from '../../components/common/ActivePatientsLayout';
import { USER_ROLE } from 'interfaces/enum';
const Home = () => {
  //console.clear()
  const { t } = useTranslation();
  const auth = useSelector((state: any) => state.auth);

  return (
    <Layout>
      <>
        <Headline title={'Lista aktivnih pacijenata'} />
        {/* <Paper> */}
          {/* <div
            style={{
              width: '100%',
              height: 150,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
            }}
          > */}
            {auth && auth.roleId === USER_ROLE.ADMIN ? (
              <img
                src='../../images/logo.png'
                style={{ height: 150 }}
                alt={'Logo'}
              />
            ) : (
              <ActivePatientsList />
            )}
          {/* </div> */}
        {/* </Paper> */}
      </>
    </Layout>
  );
};

export default Home;
