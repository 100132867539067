import employeesService from 'shared/services/user.service';
import { useTranslation } from 'react-i18next';
import Layout from 'components/layout/AppLayout';
import { makeStyles } from '@mui/styles';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';

import { Paper, TextField, FormControl, Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  form: {
    padding: '30px',
    width: '100%',
    maxWidth: '550px',
  },
  saveBtn: {
    marginTop: '10px',
  },
}));

const validationSchema = yup.object({
  password: yup
    .string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
});

const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await employeesService.updateOwnProfile({
          Passwort: values.password,
        });
        if (result.status === 200) {
          dispatch(
            enqueueSnackbarAction({
              message: 'Password is succesfuly updated!',
              options: { variant: 'success' },
            })
          );
        } else {
          throw new Error('Server errir');
        }
      } catch (error: any) {
        dispatch(
          enqueueSnackbarAction({
            message: error.message,
            options: { variant: 'error' },
          })
        );
      }
    },
  });

  return (
    <Layout>
      <div className={classes.root}>
        <Paper className={classes.form}>
          <form
            className={classes.form}
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <FormControl fullWidth>
              <h4>{t('common.add_new_password')}</h4>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='password'
                name='password'
                autoComplete='password'
                autoFocus
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
            <br />
            <Button
              className={classes.saveBtn}
              variant='contained'
              color='primary'
              type='submit'
            >
              {t('buttons.save')}
            </Button>
          </form>
        </Paper>
      </div>
    </Layout>
  );
};

export default ChangePassword;
