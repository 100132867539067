const Staff = () => {
  return (
    <svg
      viewBox="27.379 -217.943 503.56 506.007"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1, 0, 0, 1, 23.158512, -220.942917)">
        <g>
          <g>
            <g>
              <path d="M 180.604 185.071 C 200.086 204.553 226.376 216.351 255.999 216.351 C 285.623 216.351 311.958 204.598 331.393 185.072 C 350.92 165.636 362.674 139.3 362.674 109.676 C 362.674 80.052 350.922 53.717 331.395 34.282 C 311.96 14.754 285.624 3 255.999 3 C 226.374 3 200.039 14.753 180.605 34.28 C 161.077 53.714 149.324 80.052 149.324 109.676 C 149.324 139.3 161.123 165.59 180.604 185.071 Z M 255.999 37.124 C 275.789 37.124 294.263 45.473 307.278 58.398 C 320.203 71.415 328.55 89.885 328.55 109.676 C 328.55 129.467 320.249 147.983 307.277 160.955 C 294.306 173.926 275.789 182.227 255.999 182.227 C 236.209 182.227 217.735 173.878 204.72 160.953 C 191.795 147.937 183.448 129.466 183.448 109.676 C 183.448 89.886 191.749 71.37 204.721 58.398 C 217.693 45.426 236.209 37.124 255.999 37.124 Z" />
              <path d="M 361.095 263.092 C 276.965 224.13 188.149 235.998 119.697 280.415 C 51.142 324.667 4.22 400.805 4.22 491.938 C 4.22 496.867 5.918 500.796 9.212 503.998 C 12.426 507.299 16.351 509.135 21.257 509 L 490.718 509 C 495.624 509.135 499.565 507.308 502.778 504.007 C 506.073 500.806 507.78 496.867 507.78 491.938 C 507.78 389.664 447.286 302.534 361.095 263.092 Z M 255.999 274.281 C 277.58 274.281 298.643 277.506 318.33 283.384 L 323.202 284.842 L 255.999 350.686 L 188.796 284.842 L 190.42 284.356 L 193.669 283.384 C 213.355 277.507 234.418 274.281 255.999 274.281 Z M 39.284 471.595 C 46.349 397.817 90.705 334.092 152.824 300.329 L 154.77 299.278 L 155.297 299.793 L 155.824 300.31 L 244.075 386.779 C 247.434 390.254 251.446 391.636 255.998 391.636 C 260.551 391.636 264.58 390.237 267.938 386.762 L 357.225 299.278 L 357.874 299.628 L 358.525 299.979 L 359.174 300.331 C 421.294 334.095 465.647 397.821 472.712 471.597 L 473.018 474.876 L 38.978 474.876 L 39.182 472.69 L 39.284 471.595 Z" />
              <path d="M 404.477 386.94 C 409.404 386.813 413.42 388.64 416.556 391.967 C 419.88 395.095 421.524 399.059 421.524 404.002 C 421.524 408.944 419.821 412.967 416.497 416.097 C 413.361 419.423 409.39 421.191 404.462 421.064 L 392.1 421.064 L 392.1 422.064 L 392.1 433.441 C 392.227 438.368 390.4 442.384 387.073 445.52 C 383.945 448.844 379.981 450.488 375.038 450.488 C 370.096 450.488 366.073 448.785 362.943 445.461 C 359.617 442.325 357.849 438.354 357.976 433.426 L 357.976 421.064 L 356.976 421.064 L 345.599 421.064 C 340.672 421.191 336.656 419.364 333.52 416.037 C 330.196 412.909 328.552 408.945 328.552 404.002 C 328.552 399.06 330.255 395.037 333.579 391.907 C 336.715 388.581 340.686 386.813 345.614 386.94 L 357.976 386.94 L 357.976 385.94 L 357.976 374.563 C 357.849 369.636 359.676 365.62 363.003 362.484 C 366.131 359.16 370.095 357.516 375.038 357.516 C 379.98 357.516 384.003 359.219 387.133 362.543 C 390.459 365.679 392.227 369.65 392.1 374.578 L 392.1 386.94 L 393.1 386.94 L 404.477 386.94 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Staff;
