const WarningIcon = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="20px"
      height="20px"
      fill="currentColor"
      viewBox="0 0 491.537 491.537"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M488.117,459.466l-223.1-447.2c-10.4-17.4-32-13.1-37.5,0l-225.2,449.3c-8,15.6,6.3,29.2,18.8,29.2h449.6c0,0,0.3,0,0.8,0
			C487.517,490.766,497.017,472.466,488.117,459.466z M54.417,450.066l191.8-383.6l190.8,383.7h-382.6V450.066z"
          />
          <path
            d="M225.417,206.166v104.3c0,11.5,9.4,20.9,20.9,20.9c11.5,0,19.8-8.3,20.9-19.8v-105.4c0-11.5-9.4-20.9-20.9-20.9
			C234.817,185.266,225.417,194.666,225.417,206.166z"
          />
          <circle cx="246.217" cy="388.066" r="20.5" />
        </g>
      </g>
    </svg>
  );
};

export default WarningIcon;
