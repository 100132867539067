import { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Input } from 'components/UI';
import { getAllPatients } from 'store/actions/patientAction';
import treatmentsServices from 'shared/services/treatments.services';
import DiagnosesSelectList from '../../common/ActivePatientsLayout/DiagnosesSelectList';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';
import { calculateIntervalScoreValue } from '../../../utils/calculateIntervalScoreValue';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IndicatorSelectList from 'components/common/ActivePatientsLayout/IndicatorSelectList';
import { sidemodalExpand, setActiveModal } from 'store/actions/globalActions';
import { fetchClients } from 'store/actions/treatmentActions';
// import { getAllPatients} from 'store/actions/patientAction';

const PatientForm = ({}: any) => {
  const dispatch = useDispatch();
  const [indicators, setIndicators] = useState([]);
  const [diagnoses, setDiagnoses] = useState<any[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<any>('');
  const [diagnosesIndicators, setDiagnosesIndicators] = useState([]);
  const [currentDiagnose, setCurrentDiagnose] = useState<any>(null);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState<any>([]); //eslint-disable-line
  const [selectedIndicators, setSelectedIndicators] = useState<any>([]);
  const [currentIndicator, setCurrentIndicator] = useState<any>(null);
  const [isPatientActive, setIsPatientActive] = useState(true);
  const [comment, setComment] = useState<string>('');
  const patients = useSelector((store: any) => store.patient.patients);
  const [key, setKey] = useState((Math.random() + 1).toString(36).substring(7));

  useEffect(() => {
    fetchFormAssets();
    dispatch(getAllPatients());
  }, []);

  //  useEffect(() => {}, [patients]);

  useEffect(() => {
    return () => {
      setComment('');
      setCurrentDiagnose(null);
      setSelectedDiagnoses([]);
      setSelectedPatient('');
      setSelectedIndicators([]);
      setCurrentIndicator(null);
      setIsPatientActive(true);
    };
  }, []);

  const addDiagnose = () => {
    if (!currentDiagnose) return false;

    const isExist = selectedDiagnoses.some(
      (item: any) => item.id === currentDiagnose.id
    );
    if (isExist) {
      dispatch(
        enqueueSnackbarAction({
          message: `Dijagnoza "${currentDiagnose.shortName} je vec selektovana"!`,
          options: { variant: 'warning' },
        })
      );
      return false;
    }

    // make first selected diagnosys primary
    if (selectedDiagnoses.length === 0) {
      currentDiagnose.primary = true;
    }
    setSelectedDiagnoses([...selectedDiagnoses, currentDiagnose]);
    const uniqueSelected = diagnosesIndicators.filter((item: any) => {
      return item.diagnosysId === currentDiagnose.id;
    });

    let selected: any[] = [];
    uniqueSelected.forEach((item: any) => {
      if (item && selectedIndicators.length === 0) {
        selected.push(item);
      } else {
        countDuplicateIndicators(item, selectedIndicators);
        const prevIndex = selectedIndicators.findIndex(
          (ind: any) => ind.indicatorId === item.indicatorId
        );
        const prevIndicator = selectedIndicators[prevIndex];

        if (prevIndicator === undefined) {
          selected.push(item);
        } else if (
          prevIndicator.refreshTime > item.refreshTime &&
          prevIndicator.changed === false
        ) {
          const updated = selectedIndicators;
          updated.splice(prevIndex, 1, item);
          setSelectedIndicators(updated);
        } else {
          // console.log(prevIndicator);
          // console.log('vec postojiiii');
        }
      }
    });

    setSelectedIndicators([...selectedIndicators, ...selected]);
  };

  const countDuplicateIndicators = (indic: any, selectedIndicators: any) => {
    const updated = [...selectedIndicators];
    const index = updated.findIndex((item: any) => {
      return item.indicatorId === indic.indicatorId;
    });

    if (index !== -1) {
      updated[index].count = updated[index].count + 1;
      setSelectedIndicators(updated);
    }
  };

  const removeDiagnose = (id: number) => {
    //console.log(selectedIndicators, 'selected');
    setSelectedDiagnoses((prev: any) => {
      return prev.filter((item: any) => item.id !== id);
    });
    const updatedIndicators = selectedIndicators.filter((item: any) => {
      if (selectedDiagnoses.length === 2) {
        return (
          item.changed === true || (item.diagnosysId !== id && item.count === 1)
        );
      }
      return (
        item.changed === true ||
        (item.diagnosysId !== id && item.count == 1) ||
        item.count > 1
      );
    });

    setSelectedIndicators(updatedIndicators);
  };

  const fetchFormAssets = async () => {
    try {
      const response = await treatmentsServices.formAssets();
      if (response.status === 200) {
        if (response.data.diagnoses.length > 0) {
          const data = response.data.diagnoses.map((item: any) => {
            return { ...item, primary: false };
          });
          setDiagnoses(data);
        }

        if (response.data.indicators.length > 0) {
          const data = response.data.indicators.map((item: any) => {
            return { ...item };
          });
          setIndicators(data);
        }

        if (response.data.diagnosesIndicator.length > 0) {
          const data = response.data.diagnosesIndicator.map((item: any) => {
            return {
              ...item,
              changed: false,
              value: '',
              valueScore: 0,
              count: 1,
              descriptiveIndicatorId: null,
            };
          });
          setDiagnosesIndicators(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    if (disabledSubmit()) return false;

    const primaryDiagnoseObj = selectedDiagnoses.find(
      (item: any) => item.primary
    );
    const primaryDiagnosisId = primaryDiagnoseObj
      ? primaryDiagnoseObj.id
      : null;
    const data: any = {
      comment: comment,
      patientId: selectedPatient.id,
      primaryDiagnosisId: primaryDiagnosisId,
      diagnoses: selectedDiagnoses.map((item: any) => ({
        id: item.id,
        primary: item.primary,
      })),
      indicators: selectedIndicators,
    };

    try {
      const res = await treatmentsServices.create(data);
      if (res.data.status === 400) {
        dispatch(
          enqueueSnackbarAction({
            message: res.data.message,
            options: { variant: 'error' },
          })
        );
        return;
      }
      if (res.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: `Tretman je uspešno kreiran.`,
            options: { variant: 'success' },
          })
        );
        dispatch(fetchClients('?page=1'));
        dispatch(sidemodalExpand(false));

        clearForm();
        // succesfulySaveTreatment();
        return true;
      } else {
        dispatch(
          enqueueSnackbarAction({
            message: res.data.msg,
            options: { variant: 'error' },
          })
        );
        return false;
      }
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbarAction({
          message: `Problem sa serverom, pokusajte malo kasnije"!`,
          options: { variant: 'error' },
        })
      );
    }
  };

  const disabledSubmit = () => {
    if (
      !selectedPatient ||
      selectedDiagnoses.length === 0 ||
      selectedIndicators.length === 0
    )
      return true;
    return false;
  };

  const clearForm = () => {
    setComment('');
    setCurrentDiagnose(null);
    setSelectedDiagnoses([]);
    setSelectedPatient('');
    setSelectedIndicators([]);
    setCurrentIndicator(null);
    setIsPatientActive(true);
  };

  return (
    <div className={styles.wrapper}>
      <h2>Otvaranje lečenja</h2>
      <div className={styles.divider}></div>
      <div className={styles.wrapper__inner}>
        <div className={styles.wrapper__inner__form}>
          <div className={'formControl'}>
            <Autocomplete
              key={key}
              size='small'
              disablePortal
              id='combo-box-demo'
              className={styles.customInput}
              // classes={classes.test}
              options={patients}
              onChange={(event: any, newValue: any) => {
                setSelectedPatient(newValue);
              }}
              getOptionLabel={(option: any) => {
                return `${option.firstName} ${
                  option.lastName
                } - ${Moment().diff(option.dateOfBirth, 'years')}g.`;
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => {
                return (
                  <>
                    <label>Ime i prezime</label>
                    <TextField {...params} style={{ background: '#fff' }} />
                  </>
                );
              }}
            />
          </div>
          <div className={styles.formControl}>
            <Input
              fullWidth
              type='text'
              label={'Jedinstveni aktivacioni kod'}
              name={'activationCode'}
              onChange={() => {}}
              value={(selectedPatient && selectedPatient.activationCode) || ''}
              style={{ width: '200px', height: '50px', fontSize: '30px' }}
              disabled={false}
            />
          </div>
          <div className={styles.formControl}>
            {diagnoses && diagnoses.length > 0 && (
              <DiagnosesSelectList
                keyProp={key}
                diagnoses={diagnoses}
                selected={currentDiagnose}
                selectedItems={selectedDiagnoses}
                setSelected={setCurrentDiagnose}
                onClick={addDiagnose}
                onDelete={removeDiagnose}
                setPrimary={(id: number) => {
                  let updated = [...selectedDiagnoses];
                  const index = updated.findIndex(
                    (item: any) => item.id === id
                  );
                  if (index === -1) return false;
                  updated = updated.map((item: any) => ({
                    ...item,
                    primary: false,
                  }));
                  updated[index].primary = !updated[index].primary;
                  setSelectedDiagnoses(updated);
                }}
              />
            )}
            <div className={styles.formControl}>
              <label>Indikatori za pracenje pacijenta</label>
              <IndicatorSelectList
                keyProp={key}
                indicators={indicators}
                selectedIndiators={selectedIndicators}
                setSelected={(value: any) => {
                  const isExist = selectedIndicators.some(
                    (item: any) => item.indicatorId === value.id
                  );
                  if (isExist) {
                    dispatch(
                      enqueueSnackbarAction({
                        message: `Selektovani indikator se vec nalazi na spisku!`,
                        options: { variant: 'warning' },
                      })
                    );
                    return false;
                  }
                  const selected: any = diagnosesIndicators.find(
                    (item: any) => item.indicatorId === value.id
                  );
                  if (!selected) return false;
                  selected.changed = false;
                  selected.value = '';
                  selected.descriptiveIndicatorId = null;
                  setCurrentIndicator(selected);
                }}
                onClick={(index: number, value: any) => {
                  if (!currentIndicator) {
                    dispatch(
                      enqueueSnackbarAction({
                        message: `Indikator mnora biti selektovan!`,
                        options: { variant: 'warning' },
                      })
                    );
                    return false;
                  }
                  const isExist = selectedIndicators.some(
                    (item: any) =>
                      item.indicatorId === currentIndicator.indicatorId
                  );
                  if (isExist) {
                    dispatch(
                      enqueueSnackbarAction({
                        message: `Selektovani indikator se vec nalazi na spisku!`,
                        options: { variant: 'warning' },
                      })
                    );
                    return false;
                  }
                  setSelectedIndicators([
                    ...selectedIndicators,
                    currentIndicator,
                  ]);
                  // setCurrentIndicator(null);
                }}
                onDelete={(id: number) => {
                  const updated = selectedIndicators.filter(
                    (item: any) => item.indicatorId !== id
                  );
                  setSelectedIndicators(updated);
                }}
                onChangeInterval={(index: number, value: any) => {
                  const updated = [...selectedIndicators];

                  updated[index].changed = true;
                  updated[index].refreshTime = Number(value);
                  setSelectedIndicators(updated);
                }}
                onChangeValue={(
                  index: number,
                  value: number | any,
                  type: string
                ) => {
                  const updated = [...selectedIndicators];
                  updated[index].changed = true;

                  if (type === 'DESCRIPTIVE') {
                    updated[index].descriptiveIndicatorId = value.id;
                    updated[index].valueScore = value.valueScore;
                    // console.log(updated[index]);
                  }
                  if (type === 'NUMERIC') {
                    updated[index].value = Number(value);
                    updated[index].valueScore = calculateIntervalScoreValue(
                      Number(value),
                      updated[index].indicator.intervalScores
                    );
                    // console.log(updated[index], 'indic');
                  }
                  setSelectedIndicators(updated);
                }}
              />
            </div>
            <div className={styles.formControl}>
              <label>Instrukcija lekara</label>
              <TextareaAutosize
                value={comment}
                onChange={(e: any) => setComment(e.target.value)}
                minRows={4}
                maxRows={6}
                className={styles.textarea}
                aria-label='maximum height'
                placeholder='Komentar'
                style={{ width: '100%' }}
              />
            </div>
            <div className={styles.btns}>
              {/* <button
                className={styles.btn}
                onClick={() => {
                  // setFormOpened(false);
                  setKey((Math.random() + 1).toString(36).substring(7));
                  clearForm();
                }}
              >
                Otkaži
              </button> */}
              <button
                disabled={disabledSubmit()}
                className={`btn ${styles.btn}`}
                onClick={handleSave}
              >
                Kreiraj
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientForm;
