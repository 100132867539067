import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR, INotificationState  } from '../actions/notifierAction';



const defaultState:INotificationState = {
    notifications:[],
};

export default (state = defaultState, action:any) => { //eslint-disable-line
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };

        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.length > 0 && state.notifications.map((notification:{key:any}) => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications:  state.notifications.length > 0 && state.notifications.filter(
                    (notification:{key:any}) => notification.key !== action.key,
                ),
            };

        default:
            return state;
    }
};
