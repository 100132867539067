import { ADD_NEW_PATIENT, GET_PATIENT_LIST } from '../actions/types';

const initialState = {
  patients: [],
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_PATIENT_LIST:
      return {
        ...state,
        patients: [...action.payload],
      };

    case ADD_NEW_PATIENT:
      return {
        ...state,
        patients: [action.payload, ...state.patients],
      };

    default:
      return state;
  }
}
