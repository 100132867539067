import { HANDLE_MODAL_TOGGLE, HANDLE_CONFIRM_MODAL_SUCCESS, HANDLE_CONFIRM_MODAL_RESET } from "./types"

export const toggleModal = (opened: boolean, message='', confirmActionName='', values= {}) => (dispatch: Function) => {
  dispatch({ type: HANDLE_MODAL_TOGGLE, payload: {
    opened,
    message,
    confirmActionName,
    values
  } })  
}

export const handleModalSuccess = () => (dispatch: Function) => {
  dispatch({ type: HANDLE_CONFIRM_MODAL_SUCCESS })  
}

export const handleModalReset = () => (dispatch: Function) => {
  dispatch({ type: HANDLE_CONFIRM_MODAL_RESET })  
}

