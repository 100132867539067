import { useState, useEffect } from 'react';
import styles from './style.module.scss';
import Input from 'components/UI/Input';
import EditIcon from 'components/UI/EditIcon';
import { useParams } from 'react-router-dom';
import DeleteIcon from 'components/UI/DeleteIcon';
import moment from 'moment';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';
import { useDispatch, useSelector } from 'react-redux';
import useComments from './useComments';
import Chip from '@mui/material/Chip';
import {
  toggleModal,
  handleModalReset,
} from 'store/actions/confirmModalActions';
import treatmentFunc from 'views/treatment/treatmentFunc';
import treatmentsServices from 'shared/services/treatments.services';
import {
  getTreatmentByIdFull,
  getCaretakersByTreatment,
  getInstructionsByTreatment,
} from 'store/actions/treatmentActions';
import EyeIcon from 'components/UI/EyeIcon';
export interface CommentI {
  id: number | null;
  note: string;
  noteTime: Date | null;
  treatmentId: number | null;
}

const TakecareCommentsV2 = ({}: any) => {
  const state = useSelector((state: any) => state.treatment.selected);
  const data = state.takeCareNotes;

  const dispatch = useDispatch();
  const [comment, setComment] = useState<CommentI>({
    id: null,
    note: '',
    noteTime: null,
    treatmentId: null,
  });
  const [edit, setEdit] = useState(false);

  let { id } = useParams();

  const resetComment = () => {
    setComment({
      id: null,
      note: '',
      noteTime: null,
      treatmentId: null,
    });
  };

  // Jeste li sigurni da želite da izmenite komentar?

  const { addComment, updateComment, removeComment } = useComments();

  const { confirmActionName, confirmed, values } = useSelector(
    (store: any) => store.confirmModal || null
  );

  useEffect(() => {
    if (confirmed && confirmActionName === 'editComment') {
      updateComment(values.comment, setComment);
      dispatch(handleModalReset());
    }
  }, [confirmed]);

  const handleSubmit = async () => {
    if (comment.note && comment.note !== '') {
      if (!edit) {
        addComment(id, comment, setComment);
      } else {
        dispatch(
          toggleModal(
            true,
            'Jeste li sigurni da želite da izmenite komentar?',
            'editComment',
            {
              comment,
            }
          )
        );
        setEdit(false);
        // updateComment(comment, setComment);
      }
    } else {
      dispatch(
        enqueueSnackbarAction({
          message: 'Morate uneti komentar!',
          options: { variant: 'error' },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getCaretakersByTreatment(Number(id)));
    dispatch(getInstructionsByTreatment(Number(id)));
  }, []);

  return (
    <div className={styles.wrapper}>
      <h2>Instrukcije lekara ({data ? data.length : 0})</h2>
      <div className={styles.divider} />
      {/* {edit && (
        <h3 style={{ marginBottom: '0', fontWeight: 600 }}>
          Izmenite komentar
        </h3>
      )} */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Input
          name='comment'
          value={comment.note}
          label={!edit ? 'Tekst instrukcije' : 'Izmenite komentar'}
          onChange={(e) =>
            setComment((prevState) => ({ ...prevState, note: e.target.value }))
          }
          type='textarea'
        />
        <div className={styles.buttonsWrapper}>
          {edit && (
            <button
              type='button'
              className='btn'
              onClick={() => {
                resetComment();
                setEdit(false);
              }}
            >
              Otkaži
            </button>
          )}
          <button type='submit' className='btn'>
            {edit ? 'Izmeni' : 'Objavi'}
          </button>
        </div>
      </form>
      <div className={styles.divider}></div>
      {data && data.length > 0 && (
        <div style={{ height: '60vh', overflowY: 'auto' }}>
          {data.map((row: any, index: number) => {
            return (
              <Comment
                key={row.id}
                data={row}
                setComment={setComment}
                selectedDate={comment.noteTime}
                setEdit={setEdit}
                removeComment={removeComment}
                caretakers={state.caretakers}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const Comment = ({
  data,
  setComment,
  setEdit,
  selectedDate,
  removeComment,
  caretakers,
}: any) => {
  const dispatch = useDispatch();
  const { noteTime: date, note: comment, id: commentId } = data;

  const { confirmActionName, confirmed, values } = useSelector(
    (store: any) => store.confirmModal || null
  );

  useEffect(() => {
    if (confirmed && confirmActionName === 'removeComment') {
      removeComment(values.commentId);
      dispatch(handleModalReset());
    }
  }, [confirmed]);

  return (
    <div
      className={`${styles.commentWrapper} ${
        selectedDate === date ? styles.selected : ''
      }`}
    >
      <div className={styles.commentWrapper__date}>
        {moment(date).format('DD.MM.YYYY. HH:mm')}
      </div>
      <div className={styles.commentWrapper__comment}>{comment}</div>
      <CaretakerWhoSeenMessage
        caretakers={caretakers}
        treatmentNoteSeen={data.caretakerTreatmentNote}
      />
      <div
        className={`${styles.icon} ${styles.editIconWrapper}`}
        onClick={() => {
          setComment(data);
          setEdit(true);
        }}
      >
        <EditIcon />
      </div>
      <div
        className={`${styles.icon} ${styles.deleteIconWrapper}`}
        onClick={() => {
          dispatch(
            toggleModal(
              true,
              'Jeste li sigurni da želite da obrišete komentar?',
              'removeComment',
              {
                commentId,
              }
            )
          );
        }}
      >
        <DeleteIcon />
      </div>

      <div className={styles.commentDivider}></div>
    </div>
  );
};

const CaretakerWhoSeenMessage = ({ caretakers, treatmentNoteSeen }: any) => {
  const showCaretaker = (item: any, index: number) => {
    const hasSeen = treatmentNoteSeen.find((note: any) => {
      return note.caretaker.id === item.caretaker.id;
    });
    return (
      // <div key={index} className={`${styles.customChip} ${hasSeen ? styles.seen : ''}`}>
      //  {hasSeen && <EyeIcon />}  <div className={styles.fullName}>{item.caretaker.user.firstName} {item.caretaker.user.lastName}</div>
      // </div>
      <Chip
        key={index}
        color={hasSeen ? 'primary' : 'default'}
        size='small'
        avatar={hasSeen && <EyeIcon />}
        style={{ marginRight: 4, marginBottom: 3 }}
        label={`${item.caretaker.user.firstName} ${item.caretaker.user.lastName}`}
      />
    );
  };
  if (!caretakers || caretakers.length === 0) {
    return <span />;
  }
  return (
    <div style={{ marginTop: '10px' }}>
      {caretakers.map((item: any, index: number) => {
        return showCaretaker(item, index);
      })}
    </div>
  );
};

export default TakecareCommentsV2;
