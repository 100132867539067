import BaseHttpService from './base-http.service';

const URL_PATH_PREFIX = 'diagnosis';

const DiagnosisService = () => {
  const baseHttp = BaseHttpService();


  /**
   * create new diagnoses, but without indicators. Needs to be setup in db
*/
 const create = async(body:any) => {
   const url = `/${URL_PATH_PREFIX}`;
   const res = await baseHttp.post(url, body)
   return res;
 }

  /**
   * display all patients by logged user ofice Id for-new-treatment
   */
  const getAll = async (params: any = null) => {
    const url = params ? `${URL_PATH_PREFIX}/${params}` : URL_PATH_PREFIX;
    const res = await baseHttp.get(url);
    return res;
  };

  /**
   * display single diagnosis
   */
   const getOne = async (id: any = null, query?: string) => {
    const url = query ? `${URL_PATH_PREFIX}/${id}?${query}` : `${URL_PATH_PREFIX}/${id}`;
    const res = await baseHttp.get(url);
    return res;
  };


/**
   * edit single diagnosis
   */
 const edit = async (id: number, data: any) => {
  const url = `${URL_PATH_PREFIX}/${id}`;
  const res = await baseHttp.patch(url, data);
  return res;
};

  /**
   * This service return grouped indicators by diagnoses with the smallest refreshTime
   */
  const getAllWithIndcators = async (params: any = null) => {
    const url = params
      ? `${URL_PATH_PREFIX}/for-new-treatment${params}`
      : URL_PATH_PREFIX + '/for-new-treatment';
    const res = await baseHttp.get(url);
    return res;
  };

  const addIndicator = async (data: any) => {
    const url = `/diagnosis_indicator`;

    const res = await baseHttp.post(url, data);
    return res;
  };

  const updateRefreshTime = async (id: number, data: any) => {
    const url = `diagnosis_indicator/${id}`;

    const res = await baseHttp.patch(url, data);
    return res;
  };

  const deleteIndicator = async (id: number) => {
    const url = `diagnosis_indicator/${id}`;

    const res = await baseHttp.remove(url);
    return res;
  };

  return {
    create,
    getAll,
    getAllWithIndcators,
    getOne,
    edit,
    updateRefreshTime,
    deleteIndicator,
    addIndicator
  };
};
export default DiagnosisService();
