import About from '../views/about';
import { SignUp, SignIn, ChangePassword } from '../views/auth';

export const publicRoutes = {
  signin: {
    path: '/signin',
    element: SignIn,
  },
  signup: {
    path: '/signup',
    element: SignUp,
  },
};
