import React, { useEffect } from 'react';
import Auth from 'utils/auth-utils';
import { Route, Routes } from 'react-router-dom';
import { protectedRoute } from './protectedRoutes';
import { SignIn, SignUp } from 'views/auth';
import Home from 'views/home/Home';
import { publicRoutes } from './publicRoutes';
import { Navigate, Outlet } from 'react-router-dom';
import { isTokenExpired } from 'utils/jwtToken';
import { useSelector } from 'react-redux';
import { USER_ROLE } from 'interfaces/enum';
const RoutesView = () => {
  // @ts-ignore
  const auth = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route element={<ProtectedRouteItem />}>
        {Object.values(protectedRoute).map((route, index) => {
          if (route.roles === '*') {
            return (
              <Route
                key={index}
                path={route.path}
                element={<route.element />}
              />
            );
          }
          if (
            auth &&
            auth.user &&
            typeof route.roles === 'object' &&
            route.roles.length > 0 &&
            route.roles.some((role) => auth.user.roleId == role)
          ) {
            return (
              <Route
                key={index}
                path={route.path}
                element={<route.element />}
              />
            );
          }
        })}
        <Route path='*' element={<Home />} />
      </Route>
      {Object.values(publicRoutes).map((item, index) => {
        return (
          <Route key={index} path={item.path} element={<item.element />} />
        );
      })}
      <Route path='*' element={<SignIn />} />
    </Routes>
  );
};

export const ProtectedRouteItem = (props: any) => {
  const auth = new Auth().currentUser();
  return auth && !isTokenExpired() ? <Outlet /> : <Navigate to='/signin' />;
};

export default RoutesView;
