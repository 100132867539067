import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import { useSelector } from "react-redux";
import Loader from "components/UI/Loader";
import Moment from "moment";

const MyResponsiveBar = ({ data }: any) => {
  let formattedData: any = [];
  let formattedLabels = [];

  const { darkTheme } = useSelector((store: any) => store.theme || null);

  const labels = data.map((item: any) => {
    return (
      item.descriptiveIndicator?.descriptiveValue
      ? [
          Moment(item.valueTime).format("DD.MM.YYYY. HH:mm"),
          item.descriptiveIndicator?.descriptiveValue,
        ]
      : [
        Moment(item.valueTime).format("DD.MM.YYYY. HH:mm"),
        item.numericValue
      ]
    )
  }
  );

  if (labels.length < 8) {
    const itemsToCreate = 8 - labels.length;
    formattedLabels = Array(itemsToCreate).fill("").concat(labels.reverse());
  } else {
    formattedLabels = labels.reverse();
  }

  const mappedData = data.map((item: any) =>
    // item.numericValue ? Number(item.numericValue) : Number(item.valueScore)
    Number(item.valueScore)
  );

  if (mappedData.length < 8) {
    const itemsToCreate = 8 - mappedData.length;
    formattedData = Array(itemsToCreate).fill(0).concat(mappedData.reverse());
  } else {
    formattedData = data.reverse();
  }

  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: data[0].indicator.name,
        backgroundColor: darkTheme
          ? "rgba(255, 255, 102, .7)"
          : "rgba(30, 144, 255, .7)",
        borderColor: darkTheme ? "#F3F5F7" : "#3A3A3A",
        data: formattedData,
      },
    ],
  };

  return data.length > 0 ? (
    <div>
      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                color: darkTheme ? "rgba(222, 222, 222, 1)" : "#696b6b",
              },
              grid: {
                color: darkTheme ? "rgba(170, 170, 170, 1)" : "#d7d8d9",
                borderColor: darkTheme ? "rgba(170, 170, 170, 1)" : "#d7d8d9",
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                color: darkTheme ? "rgba(222, 222, 222, 1)" : "#696b6b",
              },
              grid: {
                color: darkTheme ? "rgba(170, 170, 170, 1)" : "#d7d8d9",
                borderColor: darkTheme ? "rgba(170, 170, 170, 1)" : "#d7d8d9",
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                color: darkTheme ? "rgba(222, 222, 222, 1)" : "#696b6b",
              },
            },
          },
        }}
      />
    </div>
  ) : (
    <div>
      <Loader />
    </div>
  );
};

export default MyResponsiveBar;
