import React from 'react';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import TopBar from './TopBar';
import { useStyles } from './styles';
import styles from './styles.module.scss';
import SideModal from 'components/common/SideModal';
import TakecareCommentsV2 from 'views/treatment/components/TakecareCommentsV2';
import CareTakersSectionV2 from 'views/treatment/components/CareTakerSectionV2';
import { useSelector, useDispatch } from 'react-redux';
import CreatePatientV2 from 'components/common/ActivePatientsLayout/CreatePatientV2';
import UpdatePatientForm from 'views/treatment/components/UpdatePatientForm';
import UsersFilterForm from 'views/_resources/users/components/FilterForm';
import CloseTreatmentForm from 'views/treatment/components/CommentForm/CommentForm';
import treatmentsServices from 'shared/services/treatments.services';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';
import * as treatmentActions from 'store/actions/treatmentActions';
import { useParams, useNavigate } from 'react-router-dom';
import { sidemodalExpand } from 'store/actions/globalActions';
import NewIndicatorFormV2 from 'views/treatment/components/NewIndicatorFormV2';
import { addTreatmentIndicatorV2 } from 'store/actions/treatmentActions';
import UserForm from 'views/_resources/users/components/Form';
import IndicatorForm from 'views/indikatori/components/IndicatorForm';
import DiagnosisForm from 'views/dijagnoze/components/DiagnosisForm';
import PatientForm from 'components/layout/PatientForm';
import ConfirmModal from 'components/common/ConfirmModal';
import { toggleModal, handleModalReset } from 'store/actions/confirmModalActions';

const usersFilters = {
  userName: { label: 'Email', value: '' },
  firstName: { label: 'Ime', value: '' },
  lastName: { label: 'Prezime', value: '' },
};

export default function AppLayout({ children }: any) {
  const dispatch = useDispatch();
  const treatment = useSelector((store: any) => store.treatment.selected);
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const location = useLocation();
  let { id }: any = useParams();
  const navigate = useNavigate();

  const { activeModal, modalData } = useSelector((store: any) => store.global);

  const { indicator, indicators } = useSelector(
    (store: any) => store.indicator || null
  );

  const { diagnosis } = useSelector((store: any) => store.diagnoses);

  const closeIndicatorSession = async (
    data: any,
  ) => {
    try {
      const res = await treatmentsServices.closeTreatment(id, data);

      if (res.status === 201) {
        dispatch(treatmentActions.getTreatmentByIdFull(id));
        dispatch(
          enqueueSnackbarAction({
            message: ' Uspešno ste zatvorili tretman!',
            options: { variant: 'success' },
          })
        );
        dispatch(sidemodalExpand(false));
        navigate('/');
      } else {
        dispatch(
          enqueueSnackbarAction({
            message: res.data.msg,
            options: { variant: 'error' },
          })
        );
      }
    } catch (error: any) {
      console.log(error, 'errr');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

  const onSubmitNewIndicatorFormHandler = (
    data: any,
    setKey: any,
    setFormExpanded: any
  ) => {
    data.treatmentId = treatment.treatment.id;
    dispatch(
      addTreatmentIndicatorV2(
        treatment.treatment.id,
        data,
        setKey,
        setFormExpanded
      )
    );
  };

  const modalMap: any = {
    'comment-form': (
      <TakecareCommentsV2
        data={treatment?.takeCareNotes}
        caretakers={treatment?.patient?.takeCare}
      />
    ),
    'caretaker-section': (
      <CareTakersSectionV2 data={treatment?.patient?.takeCare} />
    ),
    'create-patient-form': <CreatePatientV2 />,
    'edit-patient-form': <UpdatePatientForm data={treatment?.patient} />,
    'users-filter-form': (
      <UsersFilterForm
        filters={usersFilters}
        applyFilters={(data) => {
          modalData.queryFunction(data);
        }}
      />
    ),
    'close-treatment-form': (
      <CloseTreatmentForm onSubmitHandler={closeIndicatorSession} />
    ),
    'add-new-indicator-for-diagnosis': (
      <NewIndicatorFormV2 onSubmitHandler={onSubmitNewIndicatorFormHandler} />
    ),
    'add-new-user': <UserForm />,
    'add-or-edit-new-indicator': <IndicatorForm indicator={indicator} />,
    'add-or-edit-diagnosis': (
      <DiagnosisForm indicators={indicators} diagnosis={diagnosis} />
    ),
    'create-new-treatmant': <PatientForm />,
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDawerOpenCb = React.useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <div className={classes.root}>
      <TopBar
        handleDrawerOpen={handleDawerOpenCb}
        open={open}
        treatmentPage={location.pathname.startsWith('/treatment')}
      />

      <main className={`${classes.content} ${styles.main}`}>
        <Container maxWidth='xl' className={classes.container}>
          {children}
        </Container>
        <div className={styles.logo}>Doktor Nikolić</div>
      </main>
      <SideModal>{modalMap[activeModal]}</SideModal>
      <ConfirmModal />
    </div>
  );
}
