import React from 'react';
import { useStyles } from './styles';
import { ITable } from './Table';
import { TableHead, TableRow, TableCell } from '@mui/material';
import styles from './styles.module.scss';
import TableSorter from './TableSorter';

type TableHeaderPreview = Pick<
  ITable,
  'headers' | 'columnClickHandler' | 'sortTableHandler'
>;

// interface IProps extends Pick<ITable, "headers" | "columnClickHandler"> {
//   sortTableHandler?:(data:any) => any
// }

const TableHeader: React.FC<TableHeaderPreview> = ({
  headers,
  columnClickHandler,
  sortTableHandler,
}: TableHeaderPreview) => {
  const classes = useStyles();
  return (
    <TableHead className={`${classes.tableHeader} ${styles.tableHead}`}>
      <TableRow>
        <TableCell></TableCell>
        {Object.entries(headers).map((col: any, key) => (
          <TableCell
            className={`${classes.tableHeaderCell} ${styles.tableHeaderCell}`}
            onClick={() => columnClickHandler(col[1])}
            align={col[1].align || 'right'}
            key={key}
          >
            <div style={{ display: 'flex' }}>
              <span style={{ position: 'relative' }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: col[1].headerName || col[0],
                  }}
                />
                {col[1].sortable && (
                  <span className={styles.tableSorterWrapper}>
                    <TableSorter
                      orderBy={col[0]}
                      sortTableHandler={sortTableHandler}
                    />
                  </span>
                )}
              </span>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
