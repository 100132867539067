import treatmentsServices from 'shared/services/treatments.services';
import { useDispatch } from 'react-redux';
import {
  getTreatmentByIdFull,
  getCaretakersByTreatment,
  getInstructionsByTreatment,
} from 'store/actions/treatmentActions';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';
import { CommentI } from './TakecareCommentsV2';
import { TREATMENT_DELETE_COMMENT } from 'store/actions/types';

const useComments: any = () => {
  const dispatch = useDispatch();

  const addComment = async (
    id: any,
    comment: CommentI,
    setComment: Function
  ) => {
    try {
      const res = await treatmentsServices.addTakeCareComment({
        treatmentId: Number(id),
        note: comment.note,
      });
      if (res.status === 201) {
        dispatch(getCaretakersByTreatment(Number(id)));
        dispatch(getInstructionsByTreatment(Number(id)));
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspesno ste dodali komentar!',
            options: { variant: 'success' },
          })
        );
        setComment({
          id: null,
          note: '',
          date: null,
          treatmentId: null,
        });
      } else {
        dispatch(
          enqueueSnackbarAction({
            message: res.data.msg,
            options: { variant: 'error' },
          })
        );
      }
    } catch (err: any) {
      dispatch(
        enqueueSnackbarAction({
          message: err.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

  const updateComment = async (comment: CommentI, setComment: Function) => {
      try {
        const res = await treatmentsServices.editTakeCareComment(
          Number(comment.id),
          comment
        );
        if (res.status === 200) {
          // dispatch(getTreatmentByIdFull(Number(comment.treatmentId)));
          dispatch(getCaretakersByTreatment(Number(comment.treatmentId)));
          dispatch(getInstructionsByTreatment(Number(comment.treatmentId)));
          setComment({
            id: null,
            note: '',
            noteTime: null,
            treatmentId: null,
          });
          dispatch(
            enqueueSnackbarAction({
              message: 'Uspešno ste ažurirali komentar!',
              options: { variant: 'success' },
            })
          );
        } else {
          dispatch(
            enqueueSnackbarAction({
              message: res.data.msg,
              options: { variant: 'error' },
            })
          );
        }
      } catch (err: any) {
        dispatch(
          enqueueSnackbarAction({
            message: err.data.msg,
            options: { variant: 'error' },
          })
        );
      }
  };
  
  const removeComment = async (id: number) => {
      try {
        const res = await treatmentsServices.removeTakeCareComment(id);
        if (res.status === 200) {
          dispatch({
            type: TREATMENT_DELETE_COMMENT,
            payload: id,
          });
          dispatch(
            enqueueSnackbarAction({
              message: 'Uspešno ste izbrisali komentar!',
              options: { variant: 'success' },
            })
          );
          return true;
        }
      } catch (error: any) {
        console.log(error.message, 'treatmentActions');
        dispatch(
          enqueueSnackbarAction({
            message: error.data.msg,
            options: { variant: 'error' },
          })
        );
      }
  }

  return {
    addComment,
    updateComment,
    removeComment,
  };
};

export default useComments;
