import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'store/actions/notifierAction';
import { Formik, Form, Field } from 'formik';
import DiagnosisService from 'shared/services/diagnosis.service';
import {
  editDiagnosis,
  getAllDiagnoses,
  resetDiagnosis,
} from 'store/actions/diagnosesActions';
import { calculateIntervalScoreValue } from 'utils/calculateIntervalScoreValue';
import IndicatorSelectListDiagnosisView from '../IndicatorSelectListDiagnosisView';
import { isEmpty } from 'libs/helpers/checkObject';
import * as Yup from 'yup';
import { sidemodalExpand } from 'store/actions/globalActions';

const DiagnosisSchema = Yup.object().shape({
  shortName: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  idcCode: Yup.string().required('Required'),
});

const DiagnosisForm = ({ indicators, diagnosis }: any) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState((Math.random() + 1).toString(36).substring(7));
  const [selectedIndicators, setSelectedIndicators] = useState<any>([]);
  const [currentIndicator, setCurrentIndicator] = useState<any>(null);

  useEffect(() => {
    setSelectedIndicators([]);
    setKey((Math.random() + 1).toString(36).substring(7));
  }, [diagnosis]);

  useEffect(() => {
    if (diagnosis) {
      const mappedIndicators = diagnosis.indicators.map((indicatorObj: any) => {
        const { refreshTime } = indicatorObj;
        return {
          ...indicatorObj.indicator,
          isInDb: true,
          refreshTime,
          diagnosisId: diagnosis.id,
          indicatorRelationID: indicatorObj.id,
        };
      });
      setSelectedIndicators(mappedIndicators);
    }
  }, [diagnosis]);

  return (
    <>
      <div className={styles.wrapper}>
        <Formik
          key={key}
          validationSchema={DiagnosisSchema}
          initialValues={
            diagnosis
              ? {
                  id: diagnosis.id,
                  shortName: diagnosis.shortName,
                  idcCode: diagnosis.idcCode,
                  description: diagnosis.description, //ovde fali da dodju indikatori. Cekamo mareta.
                }
              : {
                  shortName: '',
                  idcCode: '',
                  description: '',
                }
          }
          onSubmit={async (values) => {
            selectedIndicators.length > 0 &&
              selectedIndicators.forEach((item: any) => {
                if (item.refreshTime === null || item.refreshTime == 0) {
                  dispatch(
                    enqueueSnackbar({
                      message: `Morate uneti interval za ${item.name}`,
                      options: { variant: 'error' },
                    })
                  );
                }
              });

            const hasAnyEmptyInterval =
              selectedIndicators.length > 0 &&
              selectedIndicators.some((item: any) => {
                return !item.refreshTime;
              });

            if (hasAnyEmptyInterval) return false;
            const { shortName, idcCode, description } = values;
            const mappedSelectedIndicators =
              selectedIndicators.length > 0
                ? selectedIndicators.map((indicator: any) => {
                    return {
                      indicatorId: indicator.id,
                      refreshTime: Number(indicator.interval),
                    };
                  })
                : [];

            const dataToSend = {
              shortName,
              idcCode,
              description,
              diagnoseIndicators: mappedSelectedIndicators,
            };
            if (diagnosis) {
              dispatch(editDiagnosis(values.id, values));
            } else {
              try {
                const res = await DiagnosisService.create(dataToSend);
                if (res.data.errors) {
                  if (
                    res.data.errors.length > 1 &&
                    typeof res.data.errors !== 'string'
                  ) {
                    res.data.errors.forEach((error: string) => {
                      dispatch(
                        enqueueSnackbar({
                          message: error,
                          options: { variant: 'error' },
                        })
                      );
                    });
                  } else {
                    dispatch(
                      enqueueSnackbar({
                        message: res.data.errors,
                        options: { variant: 'error' },
                      })
                    );
                  }
                } else {
                  dispatch(
                    enqueueSnackbar({
                      message: 'Dijagnoza uspešno kreirana.',
                      options: { variant: 'success' },
                    })
                  );
                  dispatch(sidemodalExpand(false));
                  setKey((Math.random() + 1).toString(36).substring(7));
                  dispatch(getAllDiagnoses());
                }
              } catch (error: any) {
                dispatch(
                  enqueueSnackbar({
                    message: error.message,
                    options: { variant: 'error' },
                  })
                );
                console.log(error);
              }
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <h2>{diagnosis ? 'Izmeni dijagnozu' : 'Nova dijagnoza'}</h2>
              <div className={styles.divider}></div>
              <div className={styles.wrapper__inner}>
                <div className={styles.leftSide}>
                  <div className='formControl' style={{ maxWidth: 400 }}>
                    <label htmlFor='shortName'>Ime</label>
                    <Field
                      name='shortName'
                      type='text'
                      id='shortName'
                      placeholder='Unesite ime dijagnoze'
                    />
                  </div>
                  <div className='formControl' style={{ maxWidth: 400 }}>
                    <label htmlFor='description'>Opis</label>
                    <Field
                      as='textarea'
                      name='description'
                      id='description'
                      placeholder='Unesite opis dijagnoze'
                    />
                  </div>
                  <div className='formControl' style={{ maxWidth: 170 }}>
                    <label htmlFor='idcCode'>Kod</label>
                    <Field
                      name='idcCode'
                      id='idcCode'
                      placeholder='L44, P55, K33...'
                    />
                  </div>
                </div>
                <div className={styles.rightSide} style={{ marginTop: 16 }}>
                  <IndicatorSelectListDiagnosisView
                    indicators={indicators}
                    selectedIndiators={selectedIndicators}
                    key={key}
                    diagnosis={diagnosis}
                    currentIndicator={currentIndicator}
                    setSelected={(value: any) => {
                      const isExist = selectedIndicators.some(
                        (item: any) => item.id === value.id
                      );
                      if (isExist) {
                        dispatch(
                          enqueueSnackbar({
                            message: `Selektovani indikator se vec nalazi na spisku!`,
                            options: { variant: 'warning' },
                          })
                        );
                        return false;
                      }
                      const selected: any = indicators.find(
                        (item: any) => item.id === value.id
                      );
                      // console.log(selected, 'selected');
                      if (!selected) return false;
                      setCurrentIndicator(selected);
                    }}
                    onClick={(index: number, value: any) => {
                      if (!currentIndicator) {
                        dispatch(
                          enqueueSnackbar({
                            message: `Indikator mora biti selektovan!`,
                            options: { variant: 'warning' },
                          })
                        );
                        return false;
                      }
                      const isExist = selectedIndicators.some(
                        (item: any) => item.id === currentIndicator.id
                      );
                      if (isExist) {
                        dispatch(
                          enqueueSnackbar({
                            message: `Selektovani indikator se vec nalazi na spisku!`,
                            options: { variant: 'warning' },
                          })
                        );
                        return false;
                      }
                      setSelectedIndicators([
                        ...selectedIndicators,
                        currentIndicator,
                      ]);
                      // setCurrentIndicator(null);
                    }}
                    onDelete={(id: number) => {
                      const updated = selectedIndicators.filter(
                        (item: any) => item.id !== id
                      );
                      setSelectedIndicators(updated);
                    }}
                    onChangeInterval={(index: number, value: any) => {
                      const updated = [...selectedIndicators];

                      updated[index].changed = true;
                      updated[index].refreshTime = Number(value);
                      setSelectedIndicators(updated);
                    }}
                    onChangeValue={(
                      index: number,
                      value: number | any,
                      type: string
                    ) => {
                      const updated = [...selectedIndicators];
                      updated[index].changed = true;

                      if (type === 'DESCRIPTIVE') {
                        updated[index].descriptiveIndicatorId = value.id;
                        updated[index].valueScore = value.valueScore;
                      }
                      if (type === 'NUMERIC') {
                        updated[index].value = Number(value);
                        updated[index].valueScore = calculateIntervalScoreValue(
                          Number(value),
                          updated[index].indicator.intervalScores
                        );
                      }
                      setSelectedIndicators(updated);
                    }}
                  />
                  <div className={styles.btnsWrapper}>
                    <button
                      type='submit'
                      className='btn'
                      disabled={
                        !diagnosis &&
                        (!isEmpty(errors) ||
                          isEmpty(touched) ||
                          selectedIndicators.length < 1)
                      }
                    >
                      {diagnosis ? 'Izmeni' : 'Kreiraj'}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default DiagnosisForm;
