import { SIDEBAR_EXPANDED, SIDEMODAL_EXPANDED, SET_ACTIVE_MODAL, SET_MODAL_DATA } from "store/actions/types";

const sidebarExpandLS: string = localStorage.getItem('sidebarExpanded') || '';

const initialState = {
  sidebarExpanded: sidebarExpandLS ? JSON.parse(sidebarExpandLS) : false,
  sidemodalExpanded: false,
  activeModal: '',
  modalData: {
    queryFunction: null
  },
}

export default function (state = initialState, action: any) {
  switch(action.type) {
    case SIDEBAR_EXPANDED:
      return {
        ...state,
        sidebarExpanded: action.payload
      }
    case SIDEMODAL_EXPANDED:
      return {
        ...state,
        sidemodalExpanded: action.payload
      }
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.payload
      }
    case SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          queryFunction: action.payload,
        }
      }
    default: 
      return state;
  }
}