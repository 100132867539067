import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import { IPaginationComponent } from './interfaces';
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: '10px',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

const PaginationComponent = ({
  pagination,
  changePage,
}: IPaginationComponent) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(pagination.page);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    changePage(value);
  };
  return (
    <div className={classes.root}>
      <Pagination
        count={pagination.totalPages || 1}
        page={page}
        onChange={handleChange}
      />
    </div>
  );
};

export default PaginationComponent;
