import jwtDecode from "jwt-decode";
import moment from "moment";

export const tokenExparationTime = () => {
  const accessToken: any = localStorage.getItem("token");

  if (accessToken === null || accessToken === "undefined") return;
  const jwtToken: { exp: number } = jwtDecode(accessToken);

  const tokenExpiration = jwtToken.exp;
  const expTime: any = moment(Math.floor(Date.now() / 1000));
  const tokenExpirationTimeInSeconds = tokenExpiration - expTime;

  return tokenExpirationTimeInSeconds;
};

export function isTokenExpired() {
  const time: any = tokenExparationTime();

  if (time > 0) {
    return false;
  }
  return true;
}
