import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, TextField, Link, Grid } from '@mui/material'; //eslint-disable-line
import { useStyles } from './styles';
import AuthLayout from 'components/layout/AuthLayout';
import { useDispatch } from 'react-redux';
import { login } from 'store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object({
  userName: yup
    .string()
    // .email('Enter a valid userName')
    .required('userName is required'),
  password: yup
    .string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
});

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // *TODO  move logic into redux
      //const { email, password } = values;
      dispatch(login(values, navigate));
    },
  });
  return (
    <AuthLayout title={'Sign In'}>
      <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='userName'
          label={t('pages.auth.user_code')}
          name='userName'
          autoComplete='userName'
          autoFocus
          value={formik.values.userName}
          onChange={formik.handleChange}
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          helperText={formik.touched.userName && formik.errors.userName}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='password'
          label={t('table_column.password')}
          type='password'
          id='password'
          autoComplete='current-password'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs></Grid>
          <Grid item></Grid>
        </Grid>
      </form>
    </AuthLayout>
  );
};

export default SignIn;
