import React from "react";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LayersIcon from "@mui/icons-material/Layers";
import { useTranslation } from "react-i18next";
import Auth from "utils/auth-utils";
import { protectedRoute } from "routes/protectedRoutes";
import Patient from "./Patient";
import Indicator from "./Indicator";
import Diagnosis from "./Diagnosis";
import Staff from "./Staff";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { sidebarExpand } from "../../../../store/actions/globalActions";
import { useLocation } from "react-router-dom";
import { resetIndicator } from "../../../../store/actions/indicatorActions";
import { resetDiagnosis } from "store/actions/diagnosesActions";
import { resetUser } from "store/actions/usersActions";
interface IFSidebarLinks {
  url: string;
  title: string;
  icon?: any;
  access?: any[];
}
export const NavBar = React.memo(() => {
  const dispatch = useDispatch();
  const expanded = useSelector((store: any) => store.global.sidebarExpanded);
  const darkTheme = useSelector((store: any) => store.theme.darkTheme);
  const { t } = useTranslation();
  const auth = new Auth();

  const hasUserVisibility = (item: any) => {
    if (item.access === undefined) return true;
    return item.access.includes(auth.currentUser().role_id);
  };

  return (
    <>
      <List
        className={`navbar-menu ${styles.navbarMenu} ${
          expanded ? styles.expanded : ""
        }`}
      >
        <div
          className={`${styles.burger}`}
          onClick={() => {
            dispatch(sidebarExpand(!expanded));
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={darkTheme ? "#F1F5F9" : "#1E2528"}
          >
            <path
              // fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              // clipRule="evenodd"
            />
          </svg>
        </div>
        <NavbarItem path={"/"} title={"Pacijenti"} patient />
        {/* <NavbarItem path={'/pacijenti'} title={'Pacijenti'} /> */}
        <NavbarItem path={"/dijagnoze"} title={"Dijagnoze"} diagnosis />
        <NavbarItem path={"/indikatori"} title={"Indikatori"} indicator />
        <NavbarItem path={"/osoblje"} title={"Osoblje"} staff />
        {/* <NavbarItem path={'/pregledi'} title={'Pregledi'} />
      <NavbarItem
        path={protectedRoute.about.path}
        title={protectedRoute.about.title}
      /> */}
      </List>
      {
        !expanded && <div
        className={`${styles.burger} ${styles.mobileBurger}`}
        style={{ zIndex: 99}}
        onClick={() => {
          dispatch(sidebarExpand(true));
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill={darkTheme ? "#F1F5F9" : "#1E2528"}
        >
          <path
            // fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            // clipRule="evenodd"
          />
        </svg>
      </div>
      }
    </>
  );
});

interface INavbarItemProps {
  path?: any;
  title: string;
  patient?: boolean;
  indicator?: boolean;
  diagnosis?: boolean;
  staff?: boolean;
}
const NavbarItem = React.memo(
  ({
    path,
    title,
    patient = false,
    indicator = false,
    diagnosis = false,
    staff = false,
  }: INavbarItemProps) => {
    const dispatch = useDispatch();
    const location = useLocation();

    return (
      <NavLink
        to={path}
        className={`${styles.link} ${
          location.pathname === path ? styles.active : ""
        }`}
        onClick={() => {
          if (window.innerWidth < 800) {
            dispatch(sidebarExpand(false));
          }
        }}
      >
        <ListItem
          className={`${styles.listItem} ${staff ? styles.listItemStaff : ""}`}
          onClick={() => {
            dispatch(resetIndicator());
            dispatch(resetDiagnosis());
            dispatch(resetUser());
          }}
        >
          <div className={`${styles.listItem__icon}`}>
            {patient && <Patient />}
            {indicator && <Indicator />}
            {diagnosis && <Diagnosis />}
            {staff && <Staff />}
          </div>
          <ListItemText
            disableTypography
            primary={title}
            className={styles.listItem__text}
          />
        </ListItem>
      </NavLink>
    );
  }
);
