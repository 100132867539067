export const GET_ITEMS = 'GET_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const ITEMS_LOADING = 'ITEMS_LOADING';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SIDEBAR_EXPANDED = 'SIDEBAR_EXPANDED';
export const SIDEMODAL_EXPANDED = 'SIDEMODAL_EXPANDED';
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';

/**
 * TREATMENT
 */
export const GET_TREATMENT_ASSETS = 'GET_TREATMENT_ASSETS';
export const LOADING_TREATMENT = 'LOADING_TREATMENT';
export const GET_TREATMENT_BY_ID_FULL = 'GET_TREATMENT_BY_ID_FULL';
export const UPDATE_TREATMENT_PATIENT = 'UPDATE_TREATMENT_PATIENT';
export const TREATMENT_DELETE_COMMENT = 'TREATMENT_DELETE_COMMENT';
export const TREATMENT_ADD_COMMENT = 'TREATMENT_ADD_COMMENT';
export const TREATMENT_EDIT_COMMENT = 'TREATMENT_EDIT_COMMENT';

export const TREATMENT_ADD_DIAGNOSE = 'TREATMENT_ADD_DIAGNOSE';
export const TREATMENT_REMOVE_DIAGNOSE = 'TREATMENT_REMOVE_DIAGNOSE';
export const TREATMENT_PRIMARY_DIAGNOSE = 'TREATMENT_PRIMARY_DIAGNOSE';

export const TREATMENT_DELETE_INDICARTOR = 'TREATMENT_DELETE_INDICARTOR';
export const TREATMENT_ADD_INDICARTOR = 'TREATMENT_ADD_INDICARTOR';
export const TREATMENT_UPDATE_REFRESH_TIME = 'TREATMENT_UPDATE_REFRESH_TIME';

export const SET_LOADING = 'SET_LOADING';
export const SET_PATIENTS_DATA = 'SET_PATIENTS_DATA';
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';

export const GET_CARETAKERS_BY_TREATMENT = 'GET_CARETAKERS_BY_TREATMENT';
export const GET_INSTRUCTIONS_BY_TREATMENT = 'GET_INSTRUCTIONS_BY_TREATMENT';
export const CLEAR_TREATMENT = 'CLEAR_TREATMENT';

/**
 * PATIENT
 */
export const GET_PATIENT_LIST = 'GET_PATIENT_LIST';
export const ADD_NEW_PATIENT = 'ADD_NEW_PATIENT';

/**
 * DIAGNOSES
 */
export const GET_ALL_DIAGNOSES = 'GET_ALL_DIAGNOSES';
export const LOADED_FAIL_DIAGNOSES = 'LOADED_FAIL_DIAGNOSES';
export const LOADED_SUCCESS_DIAGNOSES = 'LOADED_SUCCESS_DIAGNOSES';

/**
 * DIAGNOSIS
 */
export const GET_DIAGNOSIS = 'GET_DIAGNOSIS';
export const CREATE_DIAGNOSE = 'CREATE_DIAGNOSE';
export const DIAGNOSIS_LOADING = 'DIAGNOSIS_LOADING';
export const RESET_DIAGNOSIS = 'RESET_DIAGNOSIS';
export const DIAGNOSIS_ERROR = 'DIAGNOSIS_ERROR';

/**
 * EDIT DIAGNOSIS
 */
export const EDIT_DIAGNOSIS = 'EDIT_DIAGNOSIS';
export const EDIT_DIAGNOSIS_LOADING = 'EDIT_DIAGNOSIS_LOADING';
export const EDIT_DIAGNOSIS_ERROR = 'EDIT_DIAGNOSIS_ERROR';
export const DIAGNOSIS_UPDATE_REFRESH_TIME = 'DIAGNOSIS_UPDATE_REFRESH_TIME';
export const CREATE_INDICATOR_FOR_DIAGNOSIS = 'CREATE_INDICATOR_FOR_DIAGNOSIS';

/**
 * INDICATORS
 */
export const GET_ALL_INDICATORS = 'GET_ALL_INDICATORS';
export const INDICATORS_LOADING = 'INDICATORS_LOADING';
export const INDICATORS_ERROR = 'INDICATORS_ERROR';

/**
 * INDICATOR
 */
export const GET_INDICATOR = 'GET_INDICATOR';
export const INDICATOR_LOADING = 'INDICATOR_LOADING';
export const INDICATOR_ERROR = 'INDICATOR_ERROR';
export const RESET_INDICATOR = 'RESET_INDICATOR';

/**
 * EDIT INDICATOR
 */
export const EDIT_INDICATOR = 'EDIT_INDICATOR';
export const EDIT_INDICATOR_LOADING = 'EDIT_INDICATOR_LOADING';
export const EDIT_INDICATOR_ERROR = 'EDIT_INDICATOR_ERROR';

/**
 * CREATE NEW DESCRIPTIVE
 */

export const CREATE_NEW_DESCRIPTIVE_OR_NUMERIC =
  'CREATE_NEW_DESCRIPTIVE_OR_NUMERIC';
export const NEW_DESCRIPTIVE_OR_NUMERIC_LOADING =
  'NEW_DESCRIPTIVE_OR_NUMERIC_LOADING';
export const NEW_DESCRIPTIVE_OR_NUMERIC_ERROR =
  'NEW_DESCRIPTIVE_OR_NUMERIC_ERROR';

/**
 * USER
 */

export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const SET_USER_CALLBACK = 'SET_USER_CALLBACK';

// CONFIRM MODAL
export const HANDLE_MODAL_TOGGLE = 'HANDLE_MODAL_TOGGLE';
export const HANDLE_CONFIRM_MODAL_SUCCESS = 'HANDLE_CONFIRM_MODAL_SUCCESS';
export const HANDLE_CONFIRM_MODAL_RESET = 'HANDLE_CONFIRM_MODAL_RESET';
