import React, { useEffect, useState, useMemo } from "react";
import Layout from "../../../components/layout";
import { Headline } from "../../../components/UI";
import { useTranslation } from "react-i18next";
import clientsService from "shared/services/clients.service";
import Table from "../../../components/common/Table";
import useUrlQueryHook from "shared/hooks/useUrlQueryHook";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import Loader from "components/UI/Loader";
import { Roles } from "interfaces/enum";
import EditIcon from "components/UI/EditIcon";
import DeleteIcon from "components/UI/DeleteIcon";
import Filter from "./components/Filter";
import { useDispatch, useSelector } from "react-redux";
import { setUser, fetchUsersCallback } from "store/actions/usersActions";
import userService from "shared/services/user.service";
import { enqueueSnackbar as enqueueSnackbarAction } from "store/actions/notifierAction";
import { resetUser } from "store/actions/usersActions";
import { setActiveModal, sidemodalExpand } from "store/actions/globalActions";
import { toggleModal, handleModalReset } from 'store/actions/confirmModalActions';

const filters = {
  userName: { label: "Email", value: "" },
  firstName: { label: "Ime", value: "" },
  lastName: { label: "Prezime", value: "" },
};

const UsersView = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const { urlQuery, setUrlQueryHandler } = useUrlQueryHook({
    page: 1,
    limit: 15,
    include: "UsersRole",
    orderBy: "firstName",
    direction: "ASC",
  });
  const [pagination, setPagination] = useState({});

  const { selectedUser } = useSelector((store: any) => store.users || null);

  const { confirmActionName, confirmed, values } = useSelector((store: any) => store.confirmModal || null);

  //@ts-ignore
  useEffect(() => {
    fetchClients();
    return () => {
      setData([]);
    };
  }, [urlQuery]); //eslint-disable-line

  useEffect(() => {
    if (confirmed && confirmActionName === 'deleteUserHandler') {
      deleteHandler(values.id);
      dispatch(handleModalReset());
    }
  }, [confirmed])

  const deleteHandler = async (id: number) => {
    try {
      const response = await userService.remove(id);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: "Succesfully deleted Project!",
            options: { variant: "success" },
          })
        );
        fetchClients();
      }
      if (response.data.code === 403) {
        dispatch(
          enqueueSnackbarAction({
            message: response.data.message,
            options: { variant: "error" },
          })
        );
      }
      if (response.data.error) {
        dispatch(
          enqueueSnackbarAction({
            message: response.data.error,
            options: { variant: "error" },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        enqueueSnackbarAction({
          message: error.message,
          options: { variant: "error" },
        })
      );
    }
  };

  const headers = useMemo(() => {
    return {
      id: {
        headerName: "R.b.",
        // sortable: true,
        cellCallback: (params: any, index: number) => {
          return index + 1;
        },
      },
      firstName: {
        headerName: "Ime",
        sortable: true,
      },
      lastName: {
        headerName: "Prezime",
        sortable: true,
      },
      userName: {
        headerName: "Email",
        sortable: true,
      },
      roles: {
        headerName: "Pozicija",
        sortable: false,
        align: "left",
        cellCallback: (params: any) => {
          const {
            role: { roleName: role },
          } = params;
          if (role === Roles.MAIN_DOCTOR) {
            return "Glavni doktor";
          }
          if (role === Roles.DOCTOR) {
            return "Doktor";
          }
          if (role === Roles.MEDICAL_STUFF) {
            return "Medicinsko osoblje";
          }
        },
        // style: {
        //   minWidth: "180px",
        // },
      },
      [""]: {
        headerName: "",
        cellCallback: (params: any, index: number) => {
          return (
            <div
              className={styles.edit}
              onClick={() => {
                dispatch(setUser(params));
                dispatch(setActiveModal("add-new-user"));
                dispatch(fetchUsersCallback(fetchClients));
                dispatch(sidemodalExpand(true));
              }}
            >
              <EditIcon />
            </div>
          );
        },
      },
      [" "]: {
        headerName: "",
        cellCallback: (params: any, index: number) => {
          return (
            <div
              className={styles.remove}
              onClick={() => {
                dispatch(toggleModal(true, 'Jeste li sigurni da želite da obrišete korisnika?', 'deleteUserHandler', {
                  id: params.id
                }));
              }}
            >
              <DeleteIcon />
            </div>
          );
        },
      },
    };
  }, []); //eslint-disable-line

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const res = await clientsService.all(urlQuery); //eslint-disable-line
      if (res.status === 200) {
        setData(res.data.rows);
        delete res.data.rows;
        setPagination(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("greska");
    }
  };

  return (
    <Layout>
      <>
        <Headline title={"Osoblje"} />
        <div>
          <button
            className={`${styles.addNewBtn} btn`}
            style={{ fontSize: 18 }}
            onClick={() => {
              dispatch(resetUser());
              dispatch(setActiveModal("add-new-user"));
              dispatch(sidemodalExpand(true));
            }}
          >
            Kreiraj novog korisnika
          </button>
          <div className={styles.usersWrapper}>
            <div style={{ position: "relative" }}>
              {isLoading ? (
                <Loader />
              ) : (
                data &&
                data.length > 0 && (
                  <>
                    <div className={styles.filterWrapperOuter}>
                      {Object.keys(filters).length > 0 && (
                        <Filter setUrlQueryHandler={setUrlQueryHandler} />
                      )}
                    </div>
                    {selectedUser && (
                      <button
                        className={`${styles.addNewBtn} btn`}
                        onClick={() => {
                          dispatch(resetUser());
                        }}
                      >
                        Kreiraj novog korisnika
                      </button>
                    )}

                    <Table
                      data={data}
                      headers={headers}
                      customClass="usersTable"
                      columnClickHandler={(data: any) => {
             
                      }}
                      rowClickHandler={(data: any) => {
                      }}
                      singlePageRedirection={true}
                      sortTableHandler={(data) => {
                        setUrlQueryHandler(data);
                      }}
                      pagination={pagination}
                      changePage={(page) => {
                        setUrlQueryHandler({ page });
                      }}
                    />
                  </>
                )
              )}
            </div>

          </div>
        </div>
      </>
    </Layout>
  );
};

export default UsersView;
