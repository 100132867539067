import { makeStyles } from '@mui/styles';

//@ts-ignore
const useStyles = makeStyles((theme: any) => {
  return {
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: `100%`,
      height: `100%`,

      zIndex: 9999,
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
    },

    overlay: {
      background: `rgba(0, 0, 0, 0.6)`,
    },
    modalContainer: {
      overflow: 'hidden',
      minHeight: 240,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      // backgroundColor: theme.palette.info.main,
      color: '#fff',
      borderRadius: '10px',
      boxShadow: 24,
      p: 4,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.primary.main,
      padding: 10,
      color: '#fff',
    },
    content: {
      padding: '10px',
    },
    actions: {},
  };
});

export { useStyles };