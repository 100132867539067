import {
  GET_ALL_INDICATORS,
  INDICATORS_LOADING,
  INDICATORS_ERROR,
  INDICATOR_LOADING,
  GET_INDICATOR,
  INDICATOR_ERROR,
  RESET_INDICATOR,
  EDIT_INDICATOR_LOADING,
  EDIT_INDICATOR,
  EDIT_INDICATOR_ERROR,
  NEW_DESCRIPTIVE_OR_NUMERIC_ERROR,
  NEW_DESCRIPTIVE_OR_NUMERIC_LOADING,
  CREATE_NEW_DESCRIPTIVE_OR_NUMERIC,
} from "./types";
import indicatorServices from "shared/services/indicator.service";
import { enqueueSnackbar } from "./notifierAction";
import { IndicatorTypeEnum } from "interfaces/enum";
import { sidemodalExpand } from "./globalActions";

export const getAllIndicators =
  (setPagination?: Function) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: INDICATORS_LOADING });
    try {
      const res = await indicatorServices.all();
      if (res.status !== 200) throw new Error("greska");
      setPagination && setPagination({
        count: res.data.count,
        rows: res.data.rows,
        totalPages: Math.floor(res.data.count / 6) + 1,
      });
      dispatch({
        type: GET_ALL_INDICATORS,
        payload: res.data.rows,
      });
    } catch (err: any) {
      dispatch(
        enqueueSnackbar({
          message: err.message,
          options: { variant: "error" },
        })
      );
      dispatch({ type: INDICATORS_ERROR });
    }
  };

export const getIndicator =
  (id: number) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: INDICATOR_LOADING });
    try {
      const res = await indicatorServices.getSingle(id);
      if (res.status !== 200) throw new Error("greska");
      dispatch({
        type: GET_INDICATOR,
        payload: res.data,
      });
    } catch (err: any) {
      dispatch(
        enqueueSnackbar({
          message: err.message,
          options: { variant: "error" },
        })
      );
      dispatch({ type: INDICATOR_ERROR });
    }
  };

export const resetIndicator = () => async (dispatch: Function) => {
  dispatch({ type: RESET_INDICATOR });
};

export const editIndicator =
  (id: number, values: any) => async (dispatch: Function) => {
    dispatch({ type: EDIT_INDICATOR_LOADING });
    try {
      const res = await indicatorServices.edit(id, values);
      if (res.data.errors && res.data.errors.length > 0) {
        res?.data?.errors?.forEach((error: string) => {
          dispatch(
            enqueueSnackbar({
              message: error,
              options: { variant: "error" },
            })
          );
        });
      } else {
        dispatch({
          type: EDIT_INDICATOR,
          payload: res.data,
        });
        dispatch(
          enqueueSnackbar({
            message: "Indikator uspešno izmenjen.",
            options: { variant: "success" },
          })
        );
        dispatch(sidemodalExpand(false));
        dispatch(getAllIndicators());
      }
    } catch (error: any) {
      dispatch(
        enqueueSnackbar({
          message: error.message,
          options: { variant: "error" },
        })
      );
      dispatch({ type: EDIT_INDICATOR_ERROR });
    }
  };

export const createNewDescriptiveOrNumeric =
  (indicatorId: number, typeId: number) => async (dispatch: Function) => {
    dispatch({ type: NEW_DESCRIPTIVE_OR_NUMERIC_LOADING });
    try {
      const res = await indicatorServices.createNewDescriptiveOrNumeric(indicatorId, typeId);
      if (res.data.errors && res.data.errors.length > 0) {
        res?.data?.errors?.forEach((error: string) => {
          dispatch(
            enqueueSnackbar({
              message: error,
              options: { variant: "error" },
            })
          );
        });
        dispatch(sidemodalExpand(false));
      } else {
        // dispatch(sidemodalExpand(false));
        dispatch({
          type: CREATE_NEW_DESCRIPTIVE_OR_NUMERIC,
          payload: res.data,
        });
        dispatch(
          enqueueSnackbar({
            message: typeId === IndicatorTypeEnum.DESCRIPTIVE ? "Novi deskriptivni indikator uspešno dodat." : 'Novi numerički indikator uspešno dodat.',
            options: { variant: "success" },
          })
        );
        dispatch(getAllIndicators());
        dispatch(getIndicator(indicatorId));
      }
    } catch (error: any) {
      dispatch(sidemodalExpand(false));
      dispatch(
        enqueueSnackbar({
          message: error.message,
          options: { variant: "error" },
        })
      );
      dispatch({ type: NEW_DESCRIPTIVE_OR_NUMERIC_ERROR });
    }
  };

  export const deleteDescriptiveOrNumeric =
  (id: number, typeId: number, indicatorId: number) => async (dispatch: Function) => {
      dispatch({ type: NEW_DESCRIPTIVE_OR_NUMERIC_LOADING });
      try {
        const res = await indicatorServices.deleteDescriptiveOrNumeric(id, typeId);
        console.log(res);
        if (res.data.errors && res.data.errors.length > 0) {
          res?.data?.errors?.forEach((error: string) => {
            dispatch(
              enqueueSnackbar({
                message: error,
                options: { variant: "error" },
              })
            );
          });
        } else {
          if (res.status === 200) {
            dispatch({
              type: CREATE_NEW_DESCRIPTIVE_OR_NUMERIC,
              payload: res.data,
            });
            dispatch(
              enqueueSnackbar({
                message: typeId === IndicatorTypeEnum.DESCRIPTIVE ? "Deskriptivni indikator uspešno obrisan." : 'Numerički indikator uspešno obrisan.',
                options: { variant: "success" },
              })
            );
            dispatch(getAllIndicators());
            dispatch(getIndicator(indicatorId));
          } else {
            dispatch(
              enqueueSnackbar({
                message: res.data.msg,
                options: { variant: "error" },
              })
            );
          }
        }
      } catch (error: any) {
        dispatch(
          enqueueSnackbar({
            message: error.message,
            options: { variant: "error" },
          })
        );
        dispatch({ type: NEW_DESCRIPTIVE_OR_NUMERIC_ERROR });
      }
  }