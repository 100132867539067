import styles from "./styles.module.scss";
import { toggleTheme } from "store/actions/themeActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const ThemeSwitcher = () => {
  const dispatch = useDispatch();
  const darkTheme = useSelector((store: any) => store.theme.darkTheme);

  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      html.dataset.theme = darkTheme ? 'dark' : 'light'
    }
  }, [darkTheme])

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        dispatch(toggleTheme(!darkTheme));
      }}
    >
      {darkTheme ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="#DFDFDF"
        >
          <path
            fillRule="evenodd"
            d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          width="22"
          height="22"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18" cy="18" r="18" fill="url(#paint0_linear_36_2)" />
          <defs>
            <linearGradient
              id="paint0_linear_36_2"
              x1="36"
              y1="1.69873e-06"
              x2="17.5"
              y2="28.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopOpacity="0.92" />
              <stop offset="1" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default ThemeSwitcher;
