import { useState, useEffect } from 'react';
import styles from './style.module.scss';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { maxWidth } from '@mui/system';
import DeleteIcon from 'components/UI/DeleteIcon';
import EditIcon from 'components/UI/EditIcon';
import SaveIcon from 'components/UI/SaveIcon';
import { useDispatch, useSelector } from 'react-redux';
import * as diagnosesActions from 'store/actions/diagnosesActions';
import {
  toggleModal,
  handleModalReset,
} from 'store/actions/confirmModalActions';

const useStyles = makeStyles({
  customTable: {
    '& .MuiTableCell-sizeMedium': {
      padding: '0px 0px 0px 0px', // <-- arbitrary value
    },
  },
});

const IndicatorSelectListDiagnosisView = ({
  indicators,
  selectedIndiators,
  onClick,
  setSelected,
  onDelete,
  diagnosis,
  currentIndicator,
}: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const createIndicatorForDiagnosis = (data: any) => {
    // console.log(data);
    dispatch(diagnosesActions.addNewIndicatorForDiagnosis(data));
  };

  const { confirmActionName, confirmed, values } = useSelector(
    (store: any) => store.confirmModal || null
  );

  useEffect(() => {
    if (confirmed && confirmActionName === 'deleteIndicatorFromDiagnosis') {
      dispatch(
        diagnosesActions.deleteIndicator(
          values.indicatorRelationID,
          values.diagnosisId
        )
      );
      dispatch(handleModalReset());
    }
  }, [confirmed]);

  return (
    <div style={{ width: '100%' }}>
      <div>
        <label> Indikatori za pracenje pacijenta</label>
      </div>

      <div className={styles.indicatorsWrapp}>
        <Table
          classes={{ root: classes.customTable }}
          className={styles.table}
          sx={{ maxWidth: 550 }}
          aria-label='simple table'
        >
          <TableHead className={styles.tableHead}>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableCell} style={{ width: '45%' }}>
                Naziv
              </TableCell>
              <TableCell
                className={styles.tableCell}
                style={{ width: '20%' }}
                align='center'
              >
                Skracenica
              </TableCell>
              <TableCell
                className={styles.tableCell}
                style={{ width: '20%' }}
                align='center'
              >
                Interval (sat)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {selectedIndiators.map((item: any, index: number) => {
              if (item.refreshTime) {
                item.interval = item.refreshTime;
              }
              return (
                <TableRow
                  key={index}
                  style={{ color: '#000', padding: 0 }}
                  className={styles.tableRow}
                >
                  <TableCell
                    className={styles.tableCell}
                    style={{ padding: '0 !important' }}
                  >
                    {item.name}
                  </TableCell>
                  <TableCell
                    className={styles.tableCell}
                    align='center'
                    style={{ padding: '0 !important' }}
                  >
                    {item.shortName}
                  </TableCell>
                  <TableCell
                    className={styles.tableCell}
                    align='center'
                    style={{ padding: '0 !important' }}
                  >
                    {item.isInDb ? (
                      <EditIndicator item={item} />
                    ) : (
                      <input
                        defaultValue={item.interval || '1'}
                        type={'number'}
                        style={{ textAlign: 'center' }}
                        onChange={(e) => {
                          item.interval = Number(e.target.value);
                          console.log(item);
                        }}
                        name={'interval'}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell
                    align='center'
                    className={`${styles.tableCell} ${styles.remove}`}
                    style={{ padding: '0 !important' }}
                  >
                    <div
                      onClick={
                        !item.isInDb
                          ? () => onDelete(item.id)
                          : () => {
                              console.log('ds');
                              dispatch(
                                toggleModal(
                                  true,
                                  'Da li ste sigurni da želite da obrišete ovaj indikator?',
                                  'deleteIndicatorFromDiagnosis',
                                  item
                                )
                              );
                            }
                      }
                    >
                      <DeleteIcon />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div style={{ display: 'flex', marginTop: 12 }}>
        <Autocomplete
          getOptionLabel={(option: any) => option.shortName}
          // className={styles.autocomplete}
          className={styles.customInput}
          options={indicators}
          onChange={(el, value) => {
            setSelected(value);
          }}
          style={{ width: '100%' }}
          renderInput={(params: any) => {
            return (
              <TextField
                style={{ background: '#fff' }}
                {...params}
                variant='standard'
              />
            );
          }}
        />
        <button
          className={styles.addMoreBtn}
          type='button'
          onClick={() => {
            console.log(diagnosis);
            if (diagnosis) {
              const isExist = selectedIndiators.some(
                (item: any) => item.id === currentIndicator.id
              );
              if (!isExist) {
                createIndicatorForDiagnosis({
                  diagnosysId: diagnosis.id,
                  indicatorId: currentIndicator.id,
                  refreshTime: 0,
                });
              }
            } else {
              onClick();
            }
          }}
        >
          Dodaj
        </button>
      </div>
    </div>
  );
};

const EditIndicator = ({ item }: any) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  return (
    <div
      className={styles.edit}
      onClick={() => {
        setActive(true);
      }}
    >
      {!active ? (
        <>
          <EditIcon />
          <span>{item.interval}</span>
        </>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            defaultValue={item.interval}
            type={'number'}
            style={{ textAlign: 'center' }}
            onChange={(e) => {
              item.interval = e.target.value;
            }}
            name={'interval'}
          ></input>
          <div
            style={{ marginLeft: 4 }}
            onClick={(e: any) => {
              e.stopPropagation();
              dispatch(
                diagnosesActions.updateRefreshTime(
                  item.indicatorRelationID,
                  Number(item.interval),
                  item.diagnosisId
                )
              );
              setActive(false);
              // console.log(active)
            }}
          >
            <SaveIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default IndicatorSelectListDiagnosisView;
