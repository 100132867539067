
import FilterIcon from 'components/UI/FilterIcon';
import styles from "../../style.module.scss";
import { useDispatch } from 'react-redux';
import { sidemodalExpand, setActiveModal } from 'store/actions/globalActions';

const Filter = ({ setUrlQueryHandler }: any) => {
  const dispatch = useDispatch();
  return (
    <div className={styles.filterWrapper} onClick={() => {
      dispatch(setActiveModal('users-filter-form', setUrlQueryHandler))
      dispatch(sidemodalExpand(true));
    }}>
      <FilterIcon />
      <span>
        Filteri
      </span>
    </div>
  )
}

export default Filter;