import { useState } from 'react';
import { queryParser } from 'utils/queryParser';
import { useLocation, useSearchParams } from 'react-router-dom';

const parseObjectData = (searchQuery: string) => {
  let dataArr = [];
  let data: any = {};
  if (searchQuery) {
    dataArr = searchQuery.replace('?', '').split('&');
    if (dataArr.length > 0) {
      dataArr.forEach((item) => {
        const [key, value] = item.split('=');
        data[key] = value;
      });
      return data;
    }
  }
};

const useUrlQueryHook = (params: any = {}) => {
  const location = useLocation();
  const [_, setSearchParams] = useSearchParams();
  const queryParams =
    (location.search && parseObjectData(location.search)) || params;
  const [urlQuery, setUrlQuery] = useState({ ...queryParams }); //eslint-disable-line

  const setUrlQueryHandler = (data: any) => {
    setUrlQuery({ ...urlQuery, ...data });
    setSearchParams({ ...urlQuery, ...data });
  };

  return {
    urlQuery: queryParser(urlQuery),
    urlQueryParser:
      (location.search && parseObjectData(location.search)) || urlQuery,
    setUrlQueryHandler,
  };
};

export default useUrlQueryHook;
