import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, TextField, Link, Grid } from '@mui/material';
import { useStyles } from './styles';
import AuthLayout from 'components/layout/AuthLayout';
//import AuthService from 'shared/services/auth.services'

const validationSchema = yup.object({
  email: yup
    .string()
    // .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

export default function SignUp() {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {},
  });
  return (
    <AuthLayout title={'Sign Up'}>
      <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          autoFocus
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs></Grid>
          <Grid item>
            <Link href='/signin' variant='body2'>
              {'You have account? Sign In'}
            </Link>
          </Grid>
        </Grid>
      </form>
    </AuthLayout>
  );
}
