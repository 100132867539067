import { RESET_USER, SET_USER, SET_USER_CALLBACK } from "./types";

export const setUser = (user: any) => (dispatch: Function) => {
  dispatch({
    type: SET_USER,
    payload: user,
  });
};

export const resetUser = () => (dispatch: Function) => {
  dispatch({
    type: RESET_USER,
  });
};

export const fetchUsersCallback = (fetchUsersFunction= () => {}) => (dispatch: Function) => {
  dispatch({
    type: SET_USER_CALLBACK,
    payload: fetchUsersFunction,
  })
};
