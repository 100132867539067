import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    fontSize: '12px',
    lineHeight: '16px',
    marginBottom: '8px',
  },
  input: {
    padding: '5px 8px',
    border: '1px solid #C0C0CA',
    borderRadius: 'none',
    outline: 'none',
    background: 'transparent',
    fontSize: '14px',
    lineHeight: '24px',
    // width:'99%',
    // width:'-webkit-fill-available',
    width: '100%',
    fontFamily: "'Roboto',sans-serif",
  },
  error: {
    color: 'red',
    fontSize: '11px',
  },
}));

export { useStyles };
