const SaveIcon = ({
  width=20,
  height=20,
}) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      width={width}
      height={height}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 470 470"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M343.887,0H0v470h470V131.226L343.887,0z M98.389,20h200.928v133.697H98.389V20z M450,450H20V20h58.389v153.697h240.928V20
		h16.055L450,139.283V450z"
        />
        <path d="M78.389,399.629h313.213V224.341H78.389V399.629z M98.389,244.341h273.213v135.288H98.389V244.341z" />
      </g>
    </svg>
  );
};

export default SaveIcon;
