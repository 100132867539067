import { createTheme as createThemeV5 } from '@mui/material/styles';
import { common, blueGrey, indigo } from '@mui/material/colors';
import typography from './typography';

export const themeV5 = createThemeV5({
  // palette: {
  //   background: {
  //     default: '#fff',
  //     paper: common.white,
  //   },
  //   secondary: {
  //     main: indigo[500],
  //   },
  //   text: {
  //     primary: blueGrey[900],
  //     secondary: blueGrey[600],
  //   },
  // },
  typography,
});