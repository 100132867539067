import { useState, useEffect } from 'react';
import { getAllDiagnoses } from '../../../../store/actions/diagnosesActions';
import styles from './style.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import AutoCompleteWithChip from 'components/common/AutoCompleteWithChip';
import * as treatmentActions from 'store/actions/treatmentActions';
import CommentIcon from 'components/UI/CommentIcon';
import CaretakerIcon from 'components/UI/CaretakerIcon';
import { sidemodalExpand, setActiveModal } from 'store/actions/globalActions';
import Moment from 'moment';
import { toggleModal, handleModalReset } from 'store/actions/confirmModalActions';

const TreatmentInfoSectionV2 = ({ patientData }: any) => {
  const dispatch = useDispatch();
  const [selectedDiagnoseId, setSelectedDiagnoseId] = useState(null);
  const diagnoses = useSelector((store: any) => store.diagnoses.diagnoses);
  const treatment = useSelector((store: any) => store.treatment.selected);

  const { confirmActionName, confirmed, values } = useSelector((store: any) => store.confirmModal || null);

  const { sidemodalExpanded } = useSelector((store: any) => store.global);

  const dateOfActivation = Moment(patientData?.activaionTime).format(
    'DD.MM.YYYY.'
  );

  useEffect(() => {
    if (confirmed && confirmActionName === 'removeDiagnose') {
      dispatch(treatmentActions.removeDiagnose(values.id));
      dispatch(handleModalReset());
    }
  }, [confirmed])

  useEffect(() => {
    dispatch(getAllDiagnoses());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__top}>
        <div>
          <div className='formControl'>
            <label>Početak lečenja:</label>
            <span className={styles.wrapper__top__date}>
              {dateOfActivation} -{' '}
              <span style={{ visibility: 'hidden' }}>04.01.2022</span>
            </span>
          </div>
        </div>
        <div>
          <div className='formControl'>
            <label>Dijagnoze:</label>
            <span>
              {/* {diagnoses.length > 0 && treatment.diagnoses.length > 0 && ( */}
                <AutoCompleteWithChip
                  selectedItems={treatment.diagnoses}
                  items={diagnoses}
                  setSelected={(data: any) => {
                    setSelectedDiagnoseId(data.id);
                  }}
                  selected={selectedDiagnoseId}
                  onDelete={(id: any) => {
                    dispatch(toggleModal(true, 'Jeste li sigurni da želite da obrišete dijagnozu?', 'removeDiagnose', {
                      id
                    }));
                  }}
                  onClick={(data: any) => {
                    dispatch(
                      treatmentActions.addDiagnose({
                        diagnosisId: selectedDiagnoseId,
                        treatmentId: treatment.treatment.id,
                      })
                    );
                    setSelectedDiagnoseId(null);
                  }}
                  setPrimary={(diagnoseId: any) => {
                    dispatch(
                      treatmentActions.changeActiveDiagnose(
                        treatment.treatment.id,
                        diagnoseId
                      )
                    );
                  }}
                />
              {/* )} */}
            </span>
          </div>
        </div>
        <div>
          <button
            className={`${styles.dialogBtn} btn`}
            onClick={() => {
              dispatch(sidemodalExpand(true));
              dispatch(setActiveModal('comment-form'));
            }}
          >
            <CommentIcon />
            <span>Instrukcije lekara</span>
          </button>
          <button
            className={`${styles.dialogBtn} btn`}
            onClick={() => {
              dispatch(sidemodalExpand(true));
              dispatch(setActiveModal('caretaker-section'));
            }}
          >
            <CaretakerIcon />
            <span>Staratelji pacijenata</span>
          </button>
        </div>
      </div>
      <div className={styles.bottom}></div>
    </div>
  );
};

export default TreatmentInfoSectionV2;
