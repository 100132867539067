import { SET_ACTIVE_MODAL, SIDEBAR_EXPANDED, SIDEMODAL_EXPANDED, SET_MODAL_DATA } from "./types"

export const sidebarExpand = (sidebarExpanded: boolean) => (dispatch: Function) => {
  localStorage.setItem('sidebarExpanded', JSON.stringify(sidebarExpanded))
  dispatch({ type: SIDEBAR_EXPANDED, payload: sidebarExpanded })  
}

export const sidemodalExpand = (sidemodalExpanded: boolean) => (dispatch: Function) => {
  dispatch({ type: SIDEMODAL_EXPANDED, payload: sidemodalExpanded })  
}

export const setActiveModal = (activeModalValue: string, data?: any) => (dispatch: Function) => {
  dispatch({ type: SET_ACTIVE_MODAL, payload: activeModalValue })
  dispatch({ type: SET_MODAL_DATA, payload: data}) 
}