import { useEffect } from 'react';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { themeV5 } from './libs/theme';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Notifier from 'components/UI/Notifier';
import { useDispatch } from 'react-redux';
import { loadUserFromApi } from './store/actions/authActions';
import RoutesView from 'routes';
import { socketClient } from 'libs/socketIoClient';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(loadUserFromApi());
    }
    return () => {
      socketClient.close();
    };
  }, []);
  return (
    <ThemeProviderV5 theme={themeV5}>
      <CssBaseline />
      <RoutesView />
      <Notifier />
    </ThemeProviderV5>
  );
}

export default App;
