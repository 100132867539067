import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
//import Checkbox from "@mui/material/Checkbox";
//import Chip from '@mui/material/Chip';
import { useNavigate, useLocation } from 'react-router-dom';
//import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import TableHeader from './TableHeader';
import Pagination from 'components/UI/Pagination';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import WarningIcon from 'components/UI/WarningIcon';

type AlignType = 'left' | 'center' | 'right' | any;
interface HeaderProp {
  headerName?: string;
  sortable?: boolean;
  align?: AlignType;
  cellCallback?: (params: any, key: number) => any;
  style?: any;
}

//const HeaderValueType = HeaderProp | object
export type TableHeaderType = Record<string, HeaderProp>;
export interface ITable {
  data: any[];
  singlePageRedirection: boolean;
  headers: TableHeaderType;
  rowClickHandler: (row: any, index: number) => any;
  columnClickHandler: (col: any) => any;
  sortTableHandler?: (col: any) => any;
  changePage?: (col: any) => any;
  pagination?: any;
  customClass?: string;
}
const CellTitle = React.memo(({ value }: any) => {
  const { t } = useTranslation();
  if (typeof value !== 'boolean') return <span>{value}</span>;

  return value ? <div> {t('common.yes')}</div> : <div> {t('common.no')}</div>;
});
const DenseTable: React.FC<ITable> = ({
  data,
  headers,
  columnClickHandler,
  rowClickHandler,
  singlePageRedirection = false,
  sortTableHandler,
  changePage,
  pagination,
  customClass,
}: ITable) => {
  const [searchParams] = useSearchParams();

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [data, loading]);

  const { diagnosis } = useSelector((store: any) => store.diagnoses || null);

  const { indicator } = useSelector((store: any) => store.indicator || null);

  return (
    <div>
      <TableContainer>
        <Table
          className={`${styles.table} ${
            customClass ? styles[customClass] : ''
          }`}
          size='small'
          aria-label='a dense table'
        >
          <TableHeader
            headers={headers}
            columnClickHandler={columnClickHandler}
            sortTableHandler={sortTableHandler}
          />
          <TableBody>
            {data.length > 0 &&
              data.map((row: any, key: any) => {
                const itemInStorage = localStorage.getItem(
                  `patient-${row.id}-opened`
                );
                return (
                  <TableRow
                    key={key}
                    hover={true}
                    style={{ animationDelay: `${key * 0.2}s` }}
                    className={`${styles.tableRow} ${
                      indicator && row.id === indicator.id
                        ? styles.selected
                        : ''
                    } ${
                      diagnosis && row.id === diagnosis.id
                        ? styles.selected
                        : ''
                    } ${
                      Number(row.actualScore) >= 70 ? styles.dangerRow : ''
                    } ${
                      Number(row.actualScore) >= 40 &&
                      Number(row.actualScore) < 70
                        ? styles.warningRow
                        : ''
                    } ${itemInStorage ? styles.clicked : ''}`}
                    onClick={() => {
                      rowClickHandler(row, key);
                      localStorage.setItem(
                        `patient-${row.id}-opened`,
                        JSON.stringify(true)
                      );
                    }}
                  >
                    {
                      <TableCell className={styles.tableCell}>
                        {/* <Checkbox 
                    onClick={() => rowClickHandler(row)}
                    color="primary"
                    size="small"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  /> */}
                      </TableCell>
                    }
                    {Object.entries(headers).map((col: any, k) => {
                      return (
                        <TableCell
                          key={k}
                          // onClick={() =>
                          //   singlePageRedirection &&
                          //   navigate(`${location.pathname}/${row.id}?view=show`)
                          // }
                          // onClick={rowClickHandler}
                          className={styles.tableCell}
                          component='th'
                          scope='row'
                          align={col[1].align || 'left'}
                          style={col[1].style || {}}
                        >
                          <div>
                            {col[1].cellCallback ? (
                              col[1].cellCallback(row, key)
                            ) : (
                              <CellTitle value={row[col[0]]} />
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Pagination
          changePage={(page: number) =>
            typeof changePage !== 'undefined' && changePage(page)
          }
          pagination={{
            page: pagination?.page ?? Number(searchParams.get('page')),
            totalPages: pagination?.totalPages,
          }}
        />
      </TableContainer>
    </div>
  );
};

export default DenseTable;
