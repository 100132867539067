import Layout from "components/layout/AppLayout";
import { Headline } from "components/UI";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import {
  getAllDiagnoses,
  getDiagnosis,
  resetDiagnosis,
} from "store/actions/diagnosesActions";
import { getAllIndicators } from "store/actions/indicatorActions";
import styles from "./styles.module.scss";
import Table from "components/common/Table";
import DiagnosisForm from "./components/DiagnosisForm";
import diagnosisService from "shared/services/diagnosis.service";
import { setActiveModal, sidemodalExpand } from "store/actions/globalActions";
import EditIcon from 'components/UI/EditIcon';

const Diagnoses = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});

  const { diagnoses, isLoading, diagnosis } = useSelector(
    (store: any) => store.diagnoses
  );
  const { indicators, isLoading: indicatorIsloading } = useSelector(
    (store: any) => store.indicator
  );

  useEffect(() => {
    dispatch(getAllDiagnoses());
    dispatch(getAllIndicators());
  }, []);

  const headers = useMemo(() => {
    return {
      id: {
        headerName: "R.b.",
        cellCallback: (params: any, index: number) => {
          return index + 1;
        },
      },
      shortName: {
        headerName: "Naziv",
      },
      idcCode: {
        headerName: "Kod",
      },
      description: {
        headerName: "Opis",
      },
      [' ']: {
        headerName: "",
        cellCallback: (params: any, index: number) => {
          return (
            <div
              className={styles.edit}
              onClick={() => {
                dispatch(getDiagnosis(params.id));
                dispatch(setActiveModal("add-or-edit-diagnosis"));
                dispatch(sidemodalExpand(true));
              }}
            >
              <EditIcon />
            </div>
          );
        },
      },
    };
  }, []);

  return (
    <Layout>
      <>
        <Headline title={"Dijagnoze"} />
        <div>
          <div className={styles.diagnosesWrapper}>
            <div style={{ position: "relative" }}>
              <button
                className={`${styles.addNewBtn} btn`}
                style={{ fontSize: 18 }}
                onClick={() => {
                  dispatch(resetDiagnosis());
                  dispatch(setActiveModal("add-or-edit-diagnosis"));
                  dispatch(sidemodalExpand(true));
                }}
              >
                Kreiraj novu dijagnozu
              </button>

              {isLoading
                ? "loading"
                : diagnoses &&
                  diagnoses.length > 0 && (
                    <Table
                      data={diagnoses}
                      headers={headers}
                      columnClickHandler={(data: any) => {
                        // console.log(data);
                      }}
                      rowClickHandler={(data: any) => {
                        // console.log(data);
                        // dispatch(getDiagnosis(data.id));
                      }}
                      singlePageRedirection={true}
                      sortTableHandler={(data) => {
                        // console.log(data);
                        // setUrlQueryHandler(data);
                      }}
                      pagination={pagination}
                      changePage={(page) => {
                        // console.log(page);
                        // setUrlQueryHandler({ page });
                      }}
                      customClass="diagnosesTable"
                    />
                  )}
            </div>
            {/* <div style={{ marginLeft: "20px" }}>
              <DiagnosisForm indicators={indicators} diagnosis={diagnosis} />
            </div> */}
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Diagnoses;
