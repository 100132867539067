export const calculateIntervalScoreValue = (
  temperature: number,
  INTERVAL_SCORES: any
) => {
  //let temperature: number = 40.9999;
  if (typeof temperature !== 'number') {
    console.log('temperature must be number format - linearInterpolationFn');
    return false;
  }
  console.log(INTERVAL_SCORES);
  temperature =
    temperature > INTERVAL_SCORES[INTERVAL_SCORES.length - 1].highValue
      ? INTERVAL_SCORES[INTERVAL_SCORES.length - 1].highValue
      : temperature;
  if (
    temperature >= Number(INTERVAL_SCORES[INTERVAL_SCORES.length - 1].highValue)
  ) {
    const res = Number(INTERVAL_SCORES[INTERVAL_SCORES.length - 1].highValue);
    return res.toFixed(2);
  }
  let res: any;
  INTERVAL_SCORES.forEach((item: any, index: number) => {
    if (
      temperature >= Number(item.lowValue) &&
      temperature < Number(item.highValue)
    ) {
      const B_MIN = INTERVAL_SCORES[index - 1]
        ? INTERVAL_SCORES[index - 1].intervalScore
        : INTERVAL_SCORES[0].intervalScore;

      const B_MAX = INTERVAL_SCORES[index].intervalScore;
      const T_MIN = Number(INTERVAL_SCORES[index].lowValue);
      const T_MAX = Number(INTERVAL_SCORES[index].highValue);

      //   console.log({
      //     B_MIN,
      //     B_MAX,
      //     T_MAX,
      //     T_MIN,
      //   });

      let result =
        ((B_MAX - B_MIN) / (T_MAX - T_MIN)) * (temperature - T_MIN) + B_MIN;
      res = typeof result === 'number' && Number(result.toFixed(2));
      // console.log(result);
      return result;
    }
  });
  return res;
};

export const INTERVAL_SCORES = [
  {
    id: 1,
    indicatorId: 1,
    lowValue: '36.00',
    highValue: '36.90',
    intervalScore: 0,
  },
  {
    id: 2,
    indicatorId: 1,
    lowValue: '36.90',
    highValue: '37.80',
    intervalScore: 10,
  },
  {
    id: 3,
    indicatorId: 1,
    lowValue: '37.80',
    highValue: '39.00',
    intervalScore: 20,
  },
  {
    id: 4,
    indicatorId: 1,
    lowValue: '39.00',
    highValue: '40.00',
    intervalScore: 25,
  },
  {
    id: 5,
    indicatorId: 1,
    lowValue: '40.00',
    highValue: '41.00',
    intervalScore: 50,
  },
];
