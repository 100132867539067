import { useEffect, useState } from "react";
import styles from "../../style.module.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, SelectList } from "components/UI";
import rolesService from "shared/services/roles.service";
import { Roles } from "interfaces/enum";
import { useSelector, useDispatch } from "react-redux";
import { resetUser } from "store/actions/usersActions";
import userService from "shared/services/user.service";
import { enqueueSnackbar as enqueueSnackbarAction } from "store/actions/notifierAction";
import { isEmpty } from "lodash";
import { USER_ROLE } from "interfaces/enum";
import { sidemodalExpand } from "store/actions/globalActions";
import { toggleModal, handleModalReset } from 'store/actions/confirmModalActions';

const requiredString = "Polje je obavezno";
const maxLengthMessage = (num: number) =>
  `Polje mora da sadrzi manje od ${num} karaktera!`;
const UserSchema = Yup.object().shape({
  userName: Yup.string().required("Required"),
  firstName: Yup.string().max(255, maxLengthMessage(255)).required("Required"),
  lastName: Yup.string()
    .max(255, maxLengthMessage(255))
    .required(requiredString),
  phone: Yup.string().max(255, maxLengthMessage(255)),
  password: Yup.string().max(255, maxLengthMessage(255)),
  roleId: Yup.number().required(requiredString),
  // licenceNumber: Yup.string(),
});

const StaffForm = ({ fetchUsers }: any) => {
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [key, setKey] = useState((Math.random() + 1).toString(36).substring(7));

  const { selectedUser, fetchUsersFunction } = useSelector((store: any) => store.users || null);

  const { user } = useSelector((store: any) => store.auth || null);

  const { confirmActionName, confirmed, values } = useSelector((store: any) => store.confirmModal || null);

  const initialValues = {
    userName: selectedUser ? selectedUser.userName : "",
    firstName: selectedUser ? selectedUser.firstName : "",
    lastName: selectedUser ? selectedUser.lastName : "",
    phone: selectedUser ? selectedUser.phone : "",
    roleId: selectedUser ? selectedUser.role.id : "",
    password: "",
    licenceNumber:
      selectedUser && selectedUser.doctor
        ? selectedUser.doctor.licenceNumber
        : "",
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    setKey((Math.random() + 1).toString(36).substring(7));
  }, [selectedUser]);

  const fetchClients = async () => {
    try {
      const response = await rolesService.all();

      if (response.status === 201 || response.status === 200) {
        setClients(response.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createNew = async (values: any) => {
    try {
      const response = await userService.create(values);
      if (response.status === 201) {
        // setData(initialValues);
        dispatch(sidemodalExpand(false));
        dispatch(
          enqueueSnackbarAction({
            message: "Uspešno ste dodali korisnika.",
            options: { variant: "success" },
          })
        );
        fetchUsers();
        setKey((Math.random() + 1).toString(36).substring(7));
      } else {
        dispatch(sidemodalExpand(false));
        dispatch(
          enqueueSnackbarAction({
            message: response.data.mgs,
            options: { variant: "error" },
          })
        );
      }
    } catch (error: any) {
      dispatch(sidemodalExpand(false));
      dispatch(
        enqueueSnackbarAction({
          message: error.message,
          options: { variant: "error" },
        })
      );
    }
  };

  const updateClient = async (values: object) => {
    try {
      const response = await userService.update(selectedUser.id, values);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: "Uspešno ste izmenili korisnika.",
            options: { variant: "success" },
          })
        );
        dispatch(sidemodalExpand(false));
        fetchUsersFunction();
        setKey((Math.random() + 1).toString(36).substring(7));
      }
    } catch (error: any) {
      dispatch(sidemodalExpand(false));
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: "error" },
        })
      );
    }
  };

  useEffect(() => {
    if (confirmed && confirmActionName === 'editUser') {
      updateClient(values);
      dispatch(handleModalReset());
    }
  }, [confirmed])

  return (
    <div className={styles.formWrapper}>
      <Formik
        key={key}
        validationSchema={UserSchema}
        initialValues={initialValues}
        onSubmit={async (values) => {
          values.roleId = parseInt(values.roleId);
          if (values.licenceNumber) {
            values.licenceNumber = parseInt(values.licenceNumber);
          }
          if (selectedUser) {
            dispatch(toggleModal(true, 'Jeste li sigurni da želite da izmenite korisnika?', 'editUser', values));
          } else {
            createNew(values);
          }
        }}
      >
        {({ values, errors, touched }) => (
          <div className={styles.outerWrapper}>
            <h2>{selectedUser ? 'Izmena korisnika' : 'Novi korisnik'}</h2>
            <div className={styles.divider}></div>
            <Form>
              <div className="formControl">
                <label>Ime:</label>
                <Field type="text" name={"firstName"} />
              </div>
              <div className="formControl">
                <label>Prezime:</label>
                <Field type="text" name={"lastName"} />
              </div>
              <div className="formControl">
                <label>Email:</label>
                <Field type="text" name={"userName"} />
              </div>
              <div className="formControl">
                <label>Broj telefona:</label>
                <Field type="text" name={"phone"} />
              </div>
              {!selectedUser && (
                <div className="formControl">
                  <label>Šifra:</label>
                  <Field type="password" name={"password"} />
                </div>
              )}
              <div className="formControl">
                <label>Pozicija:</label>
                <Field name="roleId" as="select">
                  {!selectedUser && <option value="">Izaberite poziciju</option>}
                  {clients.map((client: any, index: number) => {
                    return (
                      <option key={index} value={Number(client.id)}>
                        {Roles.MAIN_DOCTOR === client.roleName && "Glavni doktor"}
                        {Roles.MEDICAL_STUFF === client.roleName &&
                          "Medicinsko osoblje"}
                        {Roles.DOCTOR === client.roleName && "Doktor"}
                      </option>
                    );
                  })}
                </Field>
              </div>
              {(Number(values.roleId) === USER_ROLE.MAIN_DOCTOR || Number(values.roleId) === USER_ROLE.DOCTOR) && (
                <div className="formControl">
                  <label>Broj licence:</label>
                  <Field type="text" name={"licenceNumber"} />
                </div>
              )}
              <div className={styles.buttonsWrapper}>
                {selectedUser && (
                  <button
                    type="button"
                    className={"btn"}
                    onClick={() => {
                      dispatch(resetUser());
                    }}
                  >
                    Otkaži
                  </button>
                )}
                <button
                  type="submit"
                  className={"btn"}
                  disabled={
                    !selectedUser && (!isEmpty(errors) || isEmpty(touched))
                  }
                >
                  {selectedUser ? "Izmeni" : "Kreiraj"}
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default StaffForm;
