import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "components/UI/CloseIcon";
import { useStyles } from "./style";
import styles from "./style.module.scss";

interface IProps {
  id?: string;
  header: string;
  actions?: boolean;
  children: any | any;
  open: boolean;
  setOpen: any;
  noOverlay?: boolean;
}
export default function BasicModal({
  id,
  header,
  actions = true,
  children,
  open = false,
  setOpen,
  noOverlay,
}: IProps) {
  const classes = useStyles();

  if (!open) return <span />;
  return (
    <div>
      <div
        style={{
          display: open ? "inline-block" : "none",
        }}
        className={`${classes.modalOverlay} ${
          noOverlay ? "" : classes.overlay
        }`}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div id={id} className={`${classes.modalContainer} ${styles.modalContainer}`} style={styles}>
          <header className={styles.header}>
            <h2>{header}</h2>
            <span className={styles.closeIcon} onClick={() => setOpen(false)} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </span>
          </header>
          <div className={classes.content}>{children}</div>
          {actions && <div className={classes.actions}>{actions}</div>}
        </div>
      </div>
    </div>
  );
}
