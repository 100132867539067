import BaseHttpService from './base-http.service';

const ClientService = () => {
  const baseHttp = BaseHttpService();

  const all = async (params: any = null) => {
    const url = params ? `users${params}` : 'users';
    return await baseHttp.get(url);
  };

  const getById = async (id: number) => {
    const result = await baseHttp.get(`clients/${id}`);
    return result;
  };
  const create = async (data: any) => {
    return await baseHttp.post('/clients/create', data);
  };
  const update = async (id: number, data: any) => {
    return await baseHttp.patch(`clients/${id}/update`, data);
  };
  const remove = async (id: number) => {
    return await baseHttp.remove(`clients/${id}`);
  };

  return {
    all,
    getById,
    create,
    update,
    remove,
  };
};

export default ClientService();
