import React from 'react'
import Layout from '../../components/layout'
import  { Headline}  from '../../components/UI'

const About = () => {
    return (
        <Layout>
            <Headline title="About" />
        </Layout>
    )
}

export default About
