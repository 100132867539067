import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  // table: {
  //   minWidth: 1050,
  // },
  tableHeader: {
    background: 'transparent',
    color: 'red',
    padding: '10px',
  },
  tableHeaderCell: {
    padding: '10px',
    fontWeight: 'bold',
  },
  arrow: {
    color: '#47478c',
    cursor: 'pointer',
    marginLeft: '5px',
  },
});

export { useStyles };
