import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Input from 'components/UI/Input';
import styles from './style.module.scss';
import { sidemodalExpand } from 'store/actions/globalActions';

interface IFilterItem {
  label?: string;
  value: string | number;
}

type FilterType = Record<string, IFilterItem>;

type applyedData<T> = Record<string, T>;

interface IProps {
  filters: FilterType;
  applyFilters: (data: any) => any;
}

const FilterForm = ({ filters, applyFilters }: IProps) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    setData(filters);
  }, []); //eslint-disable-line

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: any = e.target;
    setData({ ...data, [name]: { ...data[name], value } });
  };

  const onClickHandler = () => {
    applyFilters(dataForURLStringify(data));
    dispatch(sidemodalExpand(false));
    // setOpen(false);
  };

  const resetDataHandler = () => {
    setData(filters);
    applyFilters(dataForURLStringify(filters));
  };

  const dataForURLStringify = (data: FilterType) => {
    let applyedData: applyedData<string | number> = {};
    for (let key in data) {
      applyedData[`filter.${key}`] = data[key].value;
    }
    return applyedData;
  };
  return (
    <div className={styles.wrapper}>
      <h2>Filteri</h2>
      <div className={styles.divider}></div>
      {data &&
          Object.keys(data).map((key: any, index) => (
            <Input
              key={index}
              label={data[key].label || key}
              name={key}
              value={data[key].value}
              type={'text'}
              onChange={onChangeHandler}
              style={{ color: '#fff' }}
            />
          ))}
      <div className={styles.buttons}>
        <button className='btn' onClick={resetDataHandler}>
          Resetuj
        </button>
        <button className='btn' onClick={() => {
          onClickHandler();
          dispatch(sidemodalExpand(false));
        }}>
          Primeni
        </button>
      </div>
    </div>
  )
}

export default FilterForm;