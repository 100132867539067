import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  headline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    padding: '20px 10px', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export { useStyles };
