import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import Loader from 'components/UI/Loader';
import { useSelector } from 'react-redux';
import indicatorReducer from 'store/reducers/indicatorReducer';
Chart.register(...registerables);

const IndicatorGraph = ({ indicator }: any) => {
  const { darkTheme } = useSelector((store: any) => store.theme || null);

  const labels = indicator?.intervalScores.map((score: any) =>
    Number(score.highValue)
  );
  const mappedData = indicator?.intervalScores.map(
    (score: any) => score.intervalScore
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: indicator?.name,
        backgroundColor: darkTheme
          ? 'rgba(255, 255, 102, .7)'
          : 'rgba(30, 144, 255, .7)',
        borderColor: darkTheme ? '#ffff66' : '#1e90ff',
        data: mappedData,
      },
    ],
  };
  return (
    indicator?.intervalScores && (
      <div>
        <Line
          data={chartData}
          options={{
            scales: {
              x: {
                beginAtZero: true,
                ticks: {
                  color: darkTheme ? 'rgba(222, 222, 222, 1)' : '#696b6b',
                },
                grid: {
                  color: darkTheme ? 'rgba(170, 170, 170, 1)' : '#d7d8d9',
                  borderColor: darkTheme ? 'rgba(170, 170, 170, 1)' : '#d7d8d9',
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  color: darkTheme ? 'rgba(222, 222, 222, 1)' : '#696b6b',
                  //   count: labels.length + 2
                  //   stepSize: 50
                },
                grid: {
                  color: darkTheme ? 'rgba(170, 170, 170, 1)' : '#d7d8d9',
                  borderColor: darkTheme ? 'rgba(170, 170, 170, 1)' : '#d7d8d9',
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  color: darkTheme ? 'rgba(222, 222, 222, 1)' : '#696b6b',
                },
              },
            },
          }}
        ></Line>
      </div>
    )
  );
};

export default IndicatorGraph;
