import { enqueueSnackbar as enqueueSnackbarAction } from './notifierAction';
import { CreateNewDiagnosisInterface } from '../../interfaces/diagnosis.interfaces';
import { sidemodalExpand } from './globalActions';
import {
  GET_ALL_DIAGNOSES,
  GET_DIAGNOSIS,
  CREATE_DIAGNOSE,
  LOADED_FAIL_DIAGNOSES,
  RESET_DIAGNOSIS,
  DIAGNOSIS_LOADING,
  DIAGNOSIS_ERROR,
  EDIT_DIAGNOSIS,
  EDIT_DIAGNOSIS_LOADING,
  EDIT_DIAGNOSIS_ERROR,
  DIAGNOSIS_UPDATE_REFRESH_TIME,
  CREATE_INDICATOR_FOR_DIAGNOSIS,
} from './types';

import diagnosesServices from 'shared/services/diagnosis.service';
import { ErrorSharp } from '@mui/icons-material';

export const getAllDiagnoses =
  () => async (dispatch: Function, getState: Function) => {
    try {
      const res = await diagnosesServices.getAll();
      if (res.status !== 200) throw new Error('greska');
      dispatch({
        type: GET_ALL_DIAGNOSES,
        payload: res.data.rows,
      });
    } catch (error: any) {
      dispatch({
        type: LOADED_FAIL_DIAGNOSES,
      });
    }
  };

export const getDiagnosis =
  (id: number) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: DIAGNOSIS_LOADING }); //ovde ubaci type.
    try {
      const res = await diagnosesServices.getOne(id);
      if (res.status !== 200) throw new Error('greskas');
      dispatch({
        type: GET_DIAGNOSIS,
        payload: res.data,
      });
    } catch (err: any) {
      dispatch(
        enqueueSnackbarAction({
          message: err.message,
          options: { variant: 'error' },
        })
      );
      dispatch({ type: DIAGNOSIS_ERROR });
    }
  };

export const resetDiagnosis = () => async (dispatch: Function) => {
  dispatch({ type: RESET_DIAGNOSIS });
};
export const editDiagnosis =
  (id: number, values: any) => async (dispatch: Function) => {
    dispatch({ type: EDIT_DIAGNOSIS_LOADING });
    try {
      const res = await diagnosesServices.edit(id, values);
      if (res?.data?.error && res.data.errors.length > 0) {
        res?.data?.errors?.forEach((error: string) => {
          dispatch(
            enqueueSnackbarAction({
              message: ErrorSharp,
              options: { variant: 'error' },
            })
          );
        });
      } else {
        dispatch({
          type: EDIT_DIAGNOSIS,
          payload: res.data,
        });
        dispatch(
          enqueueSnackbarAction({
            message: 'Dijagnoza uspešno izmenjena',
            options: { variant: 'success' },
          })
        );
        dispatch(sidemodalExpand(false));
        dispatch(getAllDiagnoses());
        dispatch(resetDiagnosis());
      }
    } catch (error: any) {
      dispatch(
        enqueueSnackbarAction({
          message: error.message,
          options: { variant: 'error' },
        })
      );
      dispatch({ type: EDIT_DIAGNOSIS_ERROR });
    }
  };

export const addNewDiagnosis =
  (data: CreateNewDiagnosisInterface, cb?: Function) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res = await diagnosesServices.create(data);
      if (res.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: `Dijagnoza ${data.shortname} je uspešno dodata!`,
            options: { variant: 'success' },
          })
        );
        dispatch({
          type: CREATE_DIAGNOSE,
          payload: res.data,
        });
        return true;
      }
      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        enqueueSnackbarAction({
          message:
            error.data && error.data.msg ? error.data.msg : error.message,
          options: { variant: 'error' },
        })
      );
    }
  };

export const addNewIndicatorForDiagnosis =
  (data: any) => async (dispatch: Function) => {
    try {
      const res = await diagnosesServices.addIndicator(data);
      if (res.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: `Indikator je uspešno kreiran.`,
            options: { variant: 'success' },
          })
        );
        console.log(res);
        dispatch({
          type: CREATE_INDICATOR_FOR_DIAGNOSIS,
          payload: res.data,
        });
        dispatch(getDiagnosis(data.diagnosysId));
        return true;
      }
      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        enqueueSnackbarAction({
          message:
            error.data && error.data.msg ? error.data.msg : error.message,
          options: { variant: 'error' },
        })
      );
    }
  };

export const updateRefreshTime =
  (id: number, refreshTime: number, diagnosisId: number) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res = await diagnosesServices.updateRefreshTime(id, {
        refreshTime: Number(refreshTime),
      });

      if (res.status === 200) {
        dispatch({
          type: DIAGNOSIS_UPDATE_REFRESH_TIME,
          payload: {
            id: id,
            refreshTime: refreshTime,
          },
        });
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspešno izmenjen indikator za dijagnozu.',
            options: { variant: 'success' },
          })
        );
        dispatch(getDiagnosis(diagnosisId));
        return true;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      // console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.message,
          options: { variant: 'error' },
        })
      );
    }
  };

export const deleteIndicator =
  (id: number, diagnosisId: number) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res = await diagnosesServices.deleteIndicator(id);

      if (res.status === 200) {
        // dispatch({
        //   type: DIAGNOSIS_UPDATE_REFRESH_TIME,
        //   payload: {
        //     id: id,
        //     refreshTime: refreshTime,
        //   },
        // });
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspešno obrisan indikator za dijagnozu.',
            options: { variant: 'success' },
          })
        );
        dispatch(getDiagnosis(diagnosisId));
        return true;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      // console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.message,
          options: { variant: 'error' },
        })
      );
    }
  };
