const CommentIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={24}
      height={24}
      fill="currentColor"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
    >
      <metadata>
        {" "}
        Svg Vector Icons : http://www.onlinewebfonts.com/icon{" "}
      </metadata>
      <g>
        <path d="M500,56.7c-273,0-490,182-490,399c0,126,70,238,182,315c-35,70-70,112-175,168c203,21,336-35,420-84c21,0,49,0,63,0c273,0,490-182,490-399C990,238.7,773,56.7,500,56.7z M500,805.7c-28,0-49,0-77-7c-35,28-175,91-252,91c21-21,84-112,84-147c-119-63-196-168-196-287c0-196,196-350,441-350s441,154,441,350C941,644.7,745,805.7,500,805.7z" />
      </g>
    </svg>
  );
};

export default CommentIcon;
