import {
  GET_TREATMENT_ASSETS,
  GET_TREATMENT_BY_ID_FULL,
  LOADING_TREATMENT,
  UPDATE_TREATMENT_PATIENT,
  TREATMENT_EDIT_COMMENT,
  TREATMENT_DELETE_COMMENT,
  TREATMENT_ADD_DIAGNOSE,
  TREATMENT_REMOVE_DIAGNOSE,
  TREATMENT_PRIMARY_DIAGNOSE,
  TREATMENT_DELETE_INDICARTOR,
  TREATMENT_UPDATE_REFRESH_TIME,
  SET_LOADING,
  SET_PATIENTS_DATA,
  SET_LOADING_FALSE,
  GET_INSTRUCTIONS_BY_TREATMENT,
  GET_CARETAKERS_BY_TREATMENT,
  CLEAR_TREATMENT,
} from '../actions/types';

const initialState = {
  diagnoses: [],
  indicators: [],
  diagnosesIndicator: [],
  selected: {
    patient: null,
    treatment: null,
    indicators: [],
    diagnoses: [],
    indicatorValues: [],
    takeCareNotes: [],
    caretakers: [],
  },
  patients: [],
  paginate: { page: 1, totalPages: 0, count: 0 },
  isLoadingPatients: false,
  isLoadingTreatment: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_TREATMENT_ASSETS:
      return {
        ...state,
        isLoading: false,
      };
    case GET_TREATMENT_BY_ID_FULL:
      return {
        ...state,
        selected: action.payload,
        isLoadingTreatment: false,
      };
    case GET_INSTRUCTIONS_BY_TREATMENT:
      return {
        ...state,
        selected: {
          ...state.selected,
          takeCareNotes: action.payload,
        },
      };
    case GET_CARETAKERS_BY_TREATMENT:
      return {
        ...state,
        selected: {
          ...state.selected,
          caretakers: action.payload,
        },
      };
    case LOADING_TREATMENT:
      return {
        ...state,

        isLoadingTreatment: true,
      };
    case UPDATE_TREATMENT_PATIENT:
      return {
        ...state,
        selected: { ...state.selected, patient: action.payload },
      };
    case TREATMENT_DELETE_COMMENT:
      const takeCareNotes = state.selected.takeCareNotes.filter(
        (item: any) => item.id !== action.payload
      );
      return {
        ...state,
        selected: {
          ...state.selected,
          takeCareNotes,
        },
      };
    case TREATMENT_ADD_DIAGNOSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          diagnoses: [...state.selected.diagnoses, action.payload],
        },
      };
    case TREATMENT_REMOVE_DIAGNOSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          diagnoses: state.selected.diagnoses.filter(
            (item: any) => item.id !== action.payload
          ),
        },
      };
    case TREATMENT_PRIMARY_DIAGNOSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          diagnoses: state.selected.diagnoses.map((item: any) => {
            if (item.id === action.payload) {
              return { ...item, primary: true };
            }
            return { ...item, primary: false };
          }),
        },
      };
    case TREATMENT_DELETE_INDICARTOR:
      return {
        ...state,
        selected: {
          ...state.selected,
          indicators: state.selected.indicators.filter(
            (item: any) => item.id !== action.payload
          ),
        },
      };
    case TREATMENT_UPDATE_REFRESH_TIME:
      const indexIndicar = state.selected.indicators.findIndex(
        (item: any) => item.id === action.payload.id
      );
      const updated: any = state.selected.indicators;
      updated[indexIndicar].refreshTime = action.payload.refreshTime;
      return {
        ...state,
        selected: {
          ...state.selected,
          indicators: updated,
        },
      };
    case SET_PATIENTS_DATA:
      const patients = action.payload.rows;
      delete action.payload.rows;
      return {
        ...state,
        paginate: action.payload,
        patients: patients,
      };

    case CLEAR_TREATMENT:
      return {
        diagnoses: [],
        indicators: [],
        diagnosesIndicator: [],
        selected: {
          patient: null,
          treatment: null,
          indicators: [],
          diagnoses: [],
          indicatorValues: [],
          takeCareNotes: [],
          caretakers: [],
        },
        patients: [],
        isLoadingPatients: false,
        isLoadingTreatment: false,
      };
    default:
      return state;
  }
}
