import { TOGGLE_THEME } from "store/actions/types"

const darkThemeLS: string = localStorage.getItem('darkTheme') || '';

const initialState = {
  darkTheme: darkThemeLS ? JSON.parse(darkThemeLS) : false,
}

export default function (state = initialState, action: any) {
  switch(action.type) {
    case TOGGLE_THEME:
      return {
        ...state, 
        darkTheme: action.payload
      }
    default:
      return state;
  }
}