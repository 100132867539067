import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HashRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
//import {Router} from "react-router";
import { Provider } from 'react-redux';
import store from './store';
import history from './utils/history';
import i18n from './libs/languages/i18n';
// import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import Loader from './components/UI/Loader';
import "react-toastify/dist/ReactToastify.css";
//axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT_BASE_URL // the prefix of the URL
//axios.defaults.headers['Content-Type'] = 'application/json'   // default header for all get request

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      {/* <SnackbarProvider maxSnack={3}> */}
        <React.StrictMode>
          <Suspense fallback={<Loader />}>
            <HashRouter basename='/'>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
              <App />
            </HashRouter>
          </Suspense>
        </React.StrictMode>
      {/* </SnackbarProvider> */}
    </Provider>
  </I18nextProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
