import { HANDLE_CONFIRM_MODAL_SUCCESS, HANDLE_MODAL_TOGGLE, HANDLE_CONFIRM_MODAL_RESET } from "store/actions/types";

const initialState = {
  opened: false,
  confirmed: false,
  message: '',
  confirmActionName: '',
  values: {}
}

export default function (state = initialState, action: any) {
  switch(action.type) {
    case HANDLE_MODAL_TOGGLE:
      return {
        ...state,
        opened: action.payload.opened,
        message: action.payload.message,
        confirmActionName: action.payload.confirmActionName,
        values: action.payload.values
      }
    case HANDLE_CONFIRM_MODAL_SUCCESS:
      return {
        ...state,
        confirmed: true,
      }
      case HANDLE_CONFIRM_MODAL_RESET:
        return {
          ...state,
          confirmed: false,
          confirmActionName: '',
          values: {},
          opened: false,
        }
    default: 
      return state;
  }
}