import BaseHttpService from './base-http.service';
import { CreateNewPatientInterface } from '../../interfaces/patient.interfaces';
const URL_PATH_PREFIX = 'patient';

const PatientServices = () => {
  const baseHttp = BaseHttpService();

  /**
   * display all patients by logged user ofice Id
   */
  const getAll = async (params: any = null) => {
    const url = params ? `${URL_PATH_PREFIX}/${params}` : URL_PATH_PREFIX;
    const res = await baseHttp.get(url);
    return res;
  };

  const create = async (data: CreateNewPatientInterface) => {
    const url = `/${URL_PATH_PREFIX}`;
    const res = await baseHttp.post(url, data);
    return res;
  };
  const update = async (id: number, data: CreateNewPatientInterface) => {
    const url = `${URL_PATH_PREFIX}/${id}`;
    const res = await baseHttp.patch(url, data);
    return res;
  };

  return {
    getAll,
    create,
    update,
  };
};

export default PatientServices();
