import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeSnackbar } from "store/actions/notifierAction";
import { toast } from "react-toastify";
interface IDisplay {}
let displayed: IDisplay[] = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (store: any) => store.notifications.notifications || []
  );

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.length > 0 &&
      notifications.map((notification: any) => {
        const { key, message, options = {} } = notification;

        // Toast properties
        const defaultToastProperties: any = {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            dispatch(removeSnackbar(notification.key));
            removeDisplayed(key);
          },
        };
        // Do nothing if toast is already displayed
        if (displayed.includes(key)) return;

        // Toast creation
        if (options.variant === "error") {
          toast.error(message, defaultToastProperties);
        }
        if (options.variant === "warning") {
          toast.warning(message, defaultToastProperties);
        }
        if (options.variant === "success") {
          toast.success(message, defaultToastProperties);
        }
        storeDisplayed(key);
      });
  }, [notifications, dispatch]);
  return null;
};

export default Notifier;

// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
// import { removeSnackbar } from 'store/actions/notifierAction';
// interface IDisplay {}
// let displayed: IDisplay[] = [];

// const Notifier = () => {
//   const dispatch = useDispatch();
//   //@ts-ignore
//   const notifications = useSelector(
//     (store: any) => store.notifications.notifications || []
//   );
//   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

//   const storeDisplayed = (id: any) => {
//     displayed = [...displayed, id];
//   };

//   const removeDisplayed = (id: any) => {
//     displayed = [...displayed.filter((key) => id !== key)];
//   };

//   React.useEffect(() => {
//     notifications.length > 0 &&
//       notifications.forEach(
//         ({ key, message, options = {}, dismissed = false }: any) => {
//           if (dismissed) {
//             // dismiss snackbar using notistack
//             closeSnackbar(key);
//             return;
//           }

//           // do nothing if snackbar is already displayed
//           if (displayed.includes(key)) return;

//           // display snackbar using notistack
//           enqueueSnackbar(message, {
//             key,
//             ...options,
//             onClose: (event, reason, myKey) => {
//               if (options.onClose) {
//                 options.onClose(event, reason, myKey);
//               }
//             },
//             onExited: (event, myKey) => {
//               // remove this snackbar from redux store
//               dispatch(removeSnackbar(myKey));
//               removeDisplayed(myKey);
//             },
//           });

//           // keep track of snackbars that we've displayed
//           storeDisplayed(key);
//         }
//       );
//   }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

//   return null;
// };

// export default Notifier;
