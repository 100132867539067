import axiosInstance from 'libs/axiosInstance';

const BaseHttpService = () => {
  let BASE_URL = process.env.REACT_APP_ENDPOINT_BASE_URL + '/api/v1';
  let _accessToken: string | null = null;
  const get = async (endpoint: string, options = {}) => {
    options = { ...options, ..._getCommonOptions() };
    return axiosInstance
      .get(`${BASE_URL}/${endpoint}`, options)
      .catch((error) => {
        return error.response;
      });
  };

  const post = async (endpoint: string, data = {}, options = {}) => {
    options = { ...options, ..._getCommonOptions() };

    return axiosInstance
      .post(`${BASE_URL}${endpoint}`, data, options)
      .catch((error) => _handleHttpError(error));
  };

  const put = async (endpoint: string, data = {}, options = {}) => {
    options = { ...options, ..._getCommonOptions() };
    return axiosInstance
      .put(`${BASE_URL}/${endpoint}`, data, options)
      .catch((error) => _handleHttpError(error));
  };

  const remove = async (endpoint: string, options = {}) => {
    options = { ...options, ..._getCommonOptions() };
    return axiosInstance
      .delete(`${BASE_URL}/${endpoint}`, options)
      .catch((error) => _handleHttpError(error));
  };

  const patch = async (endpoint: string, data = {}, options = {}) => {
    options = { ...options, ..._getCommonOptions() };
    return axiosInstance
      .patch(`${BASE_URL}/${endpoint}`, data, options)
      .catch((error) => _handleHttpError(error));
  };

  const _handleHttpError = (error: any) => {
    const { status } = error.response;
    if (status === 404) {
      return _handle404();
    }
    if (status !== 401) {
      return error.response;
    } else {
      return _handle401();
    }
  };

  const _handle401 = () => {
    //unauthorised
    // history.push("/signin");
  };
  const _handle404 = () => {
    // Not Found
    // enqueueSnackbar("404 -  Api is not Found!" ,{variant:'error'});
  };

  const _getCommonOptions = () => {
    const token = loadToken();

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  const getAccessToken = () => {
    return _accessToken ? _accessToken : loadToken();
  };

  const saveToken = (accessToken: string) => {
    _accessToken = accessToken;
    return localStorage.setItem('token', accessToken);
  };

  const loadToken = () => {
    const token = localStorage.getItem('token');
    _accessToken = token;
    return token;
  };

  const removeToken = () => {
    localStorage.removeItem('token');
  };

  return {
    get,
    post,
    put,
    remove,
    patch,
    getAccessToken,
    saveToken,
    loadToken,
    removeToken,
  };
};

export default BaseHttpService;
