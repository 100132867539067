import BaseHttpService from "./base-http.service";
import { IndicatorTypeEnum } from "interfaces/enum";

const URL_PATH_PREFIX = "indicator";
const URL_PATH_PREFIX_DESCRIPTIVE = "descriptive_indicator";
const URL_PATH_PREFIX_NUMERIC = "interval_score";

const IndicatorService = () => {
  const baseHttp = BaseHttpService();

  /**
   * display all patients by logged user ofice Id for-new-treatment
   */
  // const getAll = async (params: any = null) => {
  //   const url = params ? `${URL_PATH_PREFIX}/${params}` : URL_PATH_PREFIX;
  //   const res = await baseHttp.get(url);
  //   return res;
  // };

  const create = async (body: any) => {
    const url = `/${URL_PATH_PREFIX}`;
    const res = await baseHttp.post(url, body);
    return res;
  };

  const getAll = async (query: any = null) => {
    const url = query ? `${URL_PATH_PREFIX}/${query}` : URL_PATH_PREFIX;
    const res = await baseHttp.get(url);
    return res;
  };

  const getSingle = async (id: number, query: any = null) => {
    const url = query
      ? `${URL_PATH_PREFIX}/${id}/${query}`
      : `${URL_PATH_PREFIX}/${id}`;
    const res = await baseHttp.get(url);
    return res;
  };

  const edit = async (id: number, data: any) => {
    const url = `${URL_PATH_PREFIX}/${id}`;
    const res = await baseHttp.patch(url, data);
    return res;
  };

  const createNewDescriptiveOrNumeric = async (id: number, typeId: number) => {
    let url, res;
    if (typeId === IndicatorTypeEnum.DESCRIPTIVE) {
      url = `/${URL_PATH_PREFIX_DESCRIPTIVE}`;
      res = await baseHttp.post(url, {
        indicatorId: id,
        descriptiveValue: " ",
        valueScore: 0,
      });
    } else {
      url = `/${URL_PATH_PREFIX_NUMERIC}`;
      res = await baseHttp.post(url, {
        indicatorId: id,
        lowValue: 0,
        highValue: 0,
        intervalScore: 0,
      });
    }
    return res;
  };

  const deleteDescriptiveOrNumeric = async (id: number, typeId: number) => {
    let url, res;
    if (typeId === IndicatorTypeEnum.DESCRIPTIVE) {
      url = `${URL_PATH_PREFIX_DESCRIPTIVE}/${id}`;
      res = await baseHttp.remove(url);
    } else {
      url = `${URL_PATH_PREFIX_NUMERIC}/${id}`;
      res = await baseHttp.remove(url);
    }
    return res;
  };

  /**
   * This service return grouped indicators by diagnoses with the smallest refreshTime
   */
  // const getAllWithIndcators = async (params: any = null) => {
  //   const url = params
  //     ? `${URL_PATH_PREFIX}/for-new-treatment${params}`
  //     : URL_PATH_PREFIX + '/for-new-treatment';
  //   const res = await baseHttp.get(url);
  //   return res;
  // };

  return {
    create,
    all: getAll,
    getSingle,
    edit,
    createNewDescriptiveOrNumeric,
    deleteDescriptiveOrNumeric
  };
};

export default IndicatorService();
