import React from 'react';
import { Button, Grid, Paper, Stack } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import styles from './style.module.scss';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';
import { useCreatePatientHook } from './useCreatePatientHook';
import { Input } from 'components/UI';
import { CreateNewPatientInterface } from '../../../../interfaces/patient.interfaces';
import patientService from 'shared/services/patient.service';
import { addNewPatient } from 'store/actions/patientAction';
const validationSchema = yup.object({
  firstName: yup
    .string()
    .required('Ime je obavezno')
    .max(255, 'Maksimalno karaktera 255'),
  lastName: yup
    .string()
    .required('Prezime je obavezno')
    .max(255, 'title must be at less 255  characters'),
  middleName: yup
    .string()
    .required('Srednje ime je obavezno')
    .max(255, 'title must be at less 255  characters'),
  dateOfBirth: yup
    .string()
    // .required('Datum rodjena je obavezan')
    .required()
    .max(255, 'title must be at less 255  characters'),
});

const initialValues: CreateNewPatientInterface = {
  firstName: '',
  lastName: '',
  middleName: '',
  dateOfBirth: '',
};

interface IProps {
  close?: any;
}
const CreatePatient: React.FC<IProps> = ({ close }) => {
  const dispatch = useDispatch();
  const patientHook = useCreatePatientHook();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,

    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      const data = { ...values };
      if (values) dispatch(addNewPatient(data));
      resetForm();
      close();
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid>
          <Grid item sm={12} xs={12}>
            <Input
              label='Ime'
              type='text'
              onChange={formik.handleChange}
              name={'firstName'}
              value={formik.values.firstName}
              disabled={false}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              errorText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Input
              label='Prezime'
              type='text'
              onChange={formik.handleChange}
              name={'lastName'}
              value={formik.values.lastName}
              disabled={false}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              errorText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Input
              label='Ime roditelja'
              style={{ width: '100%' }}
              type='text'
              onChange={formik.handleChange}
              name={'middleName'}
              value={formik.values.middleName}
              disabled={false}
              error={
                formik.touched.middleName && Boolean(formik.errors.middleName)
              }
              errorText={formik.touched.middleName && formik.errors.middleName}
            />
          </Grid>

          <Grid item sm={12} xs={12}>
            <Input
              type={'date'}
              label='Datum rodjena'
              onChange={formik.handleChange}
              name={'dateOfBirth'}
              value={formik.values.dateOfBirth}
              disabled={false}
              error={
                formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
              }
              errorText={
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
              }
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Stack direction='row' spacing={2}>
              <Button type={'submit'} variant='contained' color='success'>
                Snimi
              </Button>
              <Button variant='contained' color='error' onClick={() => close()}>
                otkazi
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreatePatient;
