import { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { RoughNotation } from 'react-rough-notation';
import { useParams } from 'react-router-dom';
import Moment from 'moment';
import { getCaretakersByTreatment } from 'store/actions/treatmentActions';
import { useDispatch, useSelector } from 'react-redux';
const CareTakerSectionV2 = ({ data }: any) => {
  const state = useSelector((store: any) => store.treatment.selected);
  console.log(state, 'selected');
  const dispatch = useDispatch();
  let { id } = useParams();
  useEffect(() => {
    dispatch(getCaretakersByTreatment(Number(id)));
    return () => {};
  }, []);
  return (
    <div className={styles.wrapper}>
      <h2>Staratelji pacijenata ({data ? data.length : 0})</h2>
      <div className={styles.divider}></div>
      <div className={styles.wrapper__caretakerList}>
        {state.caretakers &&
          state.caretakers.map((item: any, index: number) => {
            // console.log(item);
            return (
              <div
                key={index}
                className={`${item.active ? styles.active : ''}`}
              >
                <h3>
                  {item.caretaker.user.firstName} {item.caretaker.user.lastName}
                </h3>
                <div>
                  <label>Email:</label>
                  <span>{item.caretaker.user.email}</span>
                </div>
                <div>
                  <label>Broj telefona:</label>
                  <span>{item.caretaker.user.phone}</span>
                </div>
                <div>
                  <label>Adresa:</label>
                  <span>{item.caretaker.address}</span>
                </div>
                {item.active ? (
                  <div>
                    <label>Aktivan od:</label>
                    <span>
                      {Moment(item.activatedTime).format('DD.MM.YYYY. HH:mm')}
                    </span>
                  </div>
                ) : (
                  ''
                )}

                <div className={styles.divider}></div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CareTakerSectionV2;
