const ChartIcon = () => {
  return (
    <svg
      width="30px"
      height="30px"
      fill="currentColor"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 36h-2V26h-4v10h-2V24h8z" />
      <path d="M28 36h-2V20h-4v16h-2V18h8z" />
      <path d="M38 36h-2V14h-4v22h-2V12h8z" />
      <path d="M8 36h32v2H8z" />
    </svg>
  );
};

export default ChartIcon;
