import axiosInstance from 'libs/axiosInstance';
import { enqueueSnackbar as enqueueSnackbarAction } from './notifierAction';
import { USER_ROLE } from '../../interfaces/enum';
import { GET_PATIENT_LIST, ADD_NEW_PATIENT } from './types';
import { CreateNewPatientInterface } from '../../interfaces/patient.interfaces';
import patientService from 'shared/services/patient.service';
import { sidemodalExpand } from './globalActions';

export const getAllPatients =
  () => async (dispatch: Function, getState: Function) => {
    try {
      const res = await patientService.getAll(
        '?orderBy=firstName&direction=ASC'
      );
      if (res.status !== 200) throw new Error('greska');
      dispatch({
        type: GET_PATIENT_LIST,
        payload: res.data.rows,
      });
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
    }
  };

export const addNewPatient =
  (data: CreateNewPatientInterface, cb?: Function) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res = await patientService.create(data);
      if (res.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: `Pacijent ${data.firstName} je uspešno dodat.`,
            options: { variant: 'success' },
          })
        );

        dispatch({
          type: ADD_NEW_PATIENT,
          payload: res.data,
        });
        dispatch(sidemodalExpand(false));
        return true;
      }
      // cb(true);
      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        enqueueSnackbarAction({
          message:
            error.data && error.data.msg ? error.data.msg : error.message,
          options: { variant: 'error' },
        })
      );
    }
  };
