import {
  GET_ALL_DIAGNOSES,
  LOADED_FAIL_DIAGNOSES,
  LOADED_SUCCESS_DIAGNOSES,
  GET_DIAGNOSIS,
  RESET_DIAGNOSIS,
  DIAGNOSIS_LOADING,
  DIAGNOSIS_ERROR,
  EDIT_DIAGNOSIS,
  EDIT_DIAGNOSIS_LOADING,
  EDIT_DIAGNOSIS_ERROR,
  DIAGNOSIS_UPDATE_REFRESH_TIME,
  CREATE_INDICATOR_FOR_DIAGNOSIS,
} from "../actions/types";

const initialState = {
  diagnoses: [],
  isLoading: false,
  diagnosis: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_ALL_DIAGNOSES:
      return {
        ...state,
        diagnoses: action.payload,
        isLoading: false,
      };
    case LOADED_FAIL_DIAGNOSES:
      return {
        ...state,
        diagnoses: [],
        isLoading: false,
      };
    case LOADED_SUCCESS_DIAGNOSES:
      return {
        ...state,
        isLoading: false,
      };
    case GET_DIAGNOSIS:
      return {
        ...state,
        isLoading: false,
        diagnosis: action.payload,
      };
    case RESET_DIAGNOSIS:
      return {
        ...state,
        diagnosis: null,
      };
    case DIAGNOSIS_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case DIAGNOSIS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_DIAGNOSIS:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_DIAGNOSIS_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_DIAGNOSIS_ERROR: 
      return {
        ...state,
        isLoading: false,
      };
    case DIAGNOSIS_UPDATE_REFRESH_TIME: 
      return {
        ...state,
        // isLoading: false,
      };
    case CREATE_INDICATOR_FOR_DIAGNOSIS: 
      return {
        ...state,
        // isLoading: false,
      };
    default:
      return state;
  }
}
