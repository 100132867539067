import { enqueueSnackbar as enqueueSnackbarAction } from './notifierAction';
import axiosInstance from 'libs/axiosInstance';
import { USER_ROLE } from '../../interfaces/enum';
import {
  GET_TREATMENT_ASSETS,
  GET_TREATMENT_BY_ID_FULL,
  LOADING_TREATMENT,
  UPDATE_TREATMENT_PATIENT,
  TREATMENT_DELETE_COMMENT,
  TREATMENT_ADD_DIAGNOSE,
  TREATMENT_REMOVE_DIAGNOSE,
  TREATMENT_PRIMARY_DIAGNOSE,
  TREATMENT_DELETE_INDICARTOR,
  TREATMENT_UPDATE_REFRESH_TIME,
  SET_PATIENTS_DATA,
  SET_LOADING_FALSE,
  GET_INSTRUCTIONS_BY_TREATMENT,
  GET_CARETAKERS_BY_TREATMENT,
  CLEAR_TREATMENT,
} from './types';
import treatmentsServices from 'shared/services/treatments.services';
import patientServices from 'shared/services/patient.service';
import { sidemodalExpand } from './globalActions';
import {
  IAuthFunction,
  IConfigHeaders,
} from '../../interfaces/store.interfaces';

export const fetchClients =
  (urlQuery: any) => async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.getActiveMedicalTreatments(urlQuery);
      if (res.status !== 200) throw new Error('greska');
      dispatch({
        type: SET_PATIENTS_DATA,
        payload: res.data,
      });
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
    }
  };

export const getFormAssets =
  () => async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.getActiveMedicalTreatments();
      if (res.status !== 200) throw new Error('greska');
      dispatch({
        type: GET_TREATMENT_ASSETS,
        payload: res.data,
      });
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
    }
  };

export const getTreatmentByIdFull =
  (id: number) => async (dispatch: Function, getState: Function) => {
    try {
      dispatch({
        type: LOADING_TREATMENT,
      });
      const res = await treatmentsServices.getTreatmentByIdFull(id);

      if (res.status !== 200) throw new Error('greska');
      dispatch({
        type: GET_TREATMENT_BY_ID_FULL,
        payload: res.data,
      });
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
    }
  };
export const getCaretakersByTreatment =
  (id: number) => async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.getCaretakersByTreatment(id);
      if (res.status !== 200) throw new Error('greska');
      dispatch({
        type: GET_CARETAKERS_BY_TREATMENT,
        payload: res.data,
      });
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };
export const getInstructionsByTreatment =
  (id: number) => async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.getInstructionsByTreatment(id);
      if (res.status !== 200) throw new Error('greska');
      dispatch({
        type: GET_INSTRUCTIONS_BY_TREATMENT,
        payload: res.data,
      });
    } catch (error: any) {
      console.log(error, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

export const updatePatient =
  (data: any) => async (dispatch: Function, getState: Function) => {
    console.log(data);
    try {
      const res = await patientServices.update(data.id, data);
      if (res.status === 200) {
        dispatch({
          type: UPDATE_TREATMENT_PATIENT,
          payload: data,
        });
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspešno ste izmenili pacijenta!',
            options: { variant: 'success' },
          })
        );
        dispatch(sidemodalExpand(false));
        return;
      }
      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
      // dispatch(getTreatmentByIdFull(data.id));
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

export const removeCareTakerComment =
  (id: number, setShowEditModal: Function) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.removeTakeCareComment(id);

      if (res.status === 200) {
        setShowEditModal(false);
        // dispatch(getTreatmentByIdFull(data.id));
        dispatch({
          type: TREATMENT_DELETE_COMMENT,
          payload: id,
        });
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspešno ste izbrisali komentar!',
            options: { variant: 'success' },
          })
        );
        return;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

export const addDiagnose =
  (data: any) => async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.addDiagnose(data);

      if (res.status === 201) {
        dispatch({
          type: TREATMENT_ADD_DIAGNOSE,
          payload: res.data,
        });
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspesno ste dodali diagnozu!',
            options: { variant: 'success' },
          })
        );
        return true;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

export const removeDiagnose =
  (id: number) => async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.removeDiagnose(id);

      if (res.status === 200) {
        dispatch({
          type: TREATMENT_REMOVE_DIAGNOSE,
          payload: id,
        });
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspesno ste izbrisali diagnozu!',
            options: { variant: 'success' },
          })
        );
        return true;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };
export const removeTreatmentIndicator =
  (id: number) => async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.removeTreatmentIndicator(id);

      if (res.status === 200) {
        dispatch({
          type: TREATMENT_DELETE_INDICARTOR,
          payload: id,
        });
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspesno ste izbrisali indikator!',
            options: { variant: 'success' },
          })
        );
        return true;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };
export const addTreatmentIndicator =
  (treatmentId: number, data: any, formik: any, setOpen: any) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res: any = await treatmentsServices.addNewIndicator(data);
      if (res.status === 201) {
        setOpen(false);
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspesno ste dodatli indikator!',
            options: { variant: 'success' },
          })
        );
        dispatch(getTreatmentByIdFull(treatmentId));
        return true;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

export const addTreatmentIndicatorV2 =
  (treatmentId: number, data: any, setKey: any, setFormExpanded: any) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res: any = await treatmentsServices.addNewIndicator(data);
      if (res.status === 201) {
        setKey((Math.random() + 1).toString(36).substring(7));
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspešno ste dodali indikator.',
            options: { variant: 'success' },
          })
        );
        dispatch(getTreatmentByIdFull(treatmentId));
        dispatch(sidemodalExpand(false));
        return true;
      }
      setKey((Math.random() + 1).toString(36).substring(7));
      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      setKey((Math.random() + 1).toString(36).substring(7));
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

export const changeActiveDiagnose =
  (treatmentId: number, diagnoseId: number) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.changeActiveDiagnose(
        treatmentId,
        diagnoseId
      );

      if (res.status === 200) {
        dispatch({
          type: TREATMENT_PRIMARY_DIAGNOSE,
          payload: diagnoseId,
        });
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspešno ste promenili primarnu dijagnozu!',
            options: { variant: 'success' },
          })
        );
        return true;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

export const updateRefreshTime =
  (id: number, refreshTime: number) =>
  async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.updateRefreshTime(id, {
        refreshTime: Number(refreshTime),
      });

      if (res.status === 200) {
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspešno ste izmenili indikator.',
            options: { variant: 'success' },
          })
        );
        dispatch({
          type: TREATMENT_UPDATE_REFRESH_TIME,
          payload: {
            id: id,
            refreshTime: refreshTime,
          },
        });
        return true;
      }

      dispatch(
        enqueueSnackbarAction({
          message: res.data.msg,
          options: { variant: 'error' },
        })
      );
    } catch (error: any) {
      console.log(error.message, 'treatmentActions');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };

export const addIndictorValue =
  (data: any) => async (dispatch: Function, getState: Function) => {
    try {
      const res = await treatmentsServices.addNewIndicatorValue(data);

      if (res.status === 201) {
        dispatch(
          enqueueSnackbarAction({
            message: 'Uspešno ste dodali vrednost indikatora.',
            options: { variant: 'success' },
          })
        );
        dispatch(getTreatmentByIdFull(data.treatmentId));
      }
    } catch (error: any) {
      console.log(error.message, 'addIndictorValue');
      dispatch(
        enqueueSnackbarAction({
          message: error.data.msg,
          options: { variant: 'error' },
        })
      );
    }
  };
export const clearSingleTreatment =
  () => async (dispatch: Function, getState: Function) => {
    dispatch({
      type: CLEAR_TREATMENT,
    });
  };
