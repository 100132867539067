import {
  GET_ALL_INDICATORS,
  INDICATORS_LOADING,
  INDICATORS_ERROR,
  INDICATOR_LOADING,
  GET_INDICATOR,
  INDICATOR_ERROR,
  RESET_INDICATOR,
  EDIT_INDICATOR,
  EDIT_INDICATOR_LOADING,
  EDIT_INDICATOR_ERROR,
  NEW_DESCRIPTIVE_OR_NUMERIC_LOADING,
  CREATE_NEW_DESCRIPTIVE_OR_NUMERIC,
  NEW_DESCRIPTIVE_OR_NUMERIC_ERROR,
} from "../actions/types";

const initialState = {
  indicators: [],
  indicator: null,
  indicatorIsLoading: false,
  editIndicatorIsLoading: false,
  descriptiveIsLoading: false,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case INDICATORS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_INDICATORS:
      return {
        ...state,
        isLoading: false,
        indicators: action.payload,
      };
    case INDICATORS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case INDICATOR_LOADING:
      return {
        ...state,
        indicatorIsLoading: true,
      };
    case GET_INDICATOR:
      return {
        ...state,
        indicatorIsLoading: false,
        indicator: action.payload,
      };
    case INDICATOR_ERROR:
      return {
        ...state,
        indicatorIsLoading: false,
      };
    case RESET_INDICATOR:
      return {
        ...state,
        indicator: null,
      };
    case EDIT_INDICATOR_LOADING:
      return {
        ...state,
        editIndicatorIsLoading: true,
      };
    case EDIT_INDICATOR:
      return {
        ...state,
        editIndicatorIsLoading: false,
      };
    case EDIT_INDICATOR_ERROR:
      return {
        ...state,
        editIndicatorIsLoading: false,
      };
    case NEW_DESCRIPTIVE_OR_NUMERIC_LOADING:
      return {
        ...state,
        descriptiveIsLoading: true,
      };
    case CREATE_NEW_DESCRIPTIVE_OR_NUMERIC:
      return {
        ...state,
        descriptiveIsLoading: false,
      };
    case NEW_DESCRIPTIVE_OR_NUMERIC_ERROR:
      return {
        ...state,
        descriptiveIsLoading: false,
      };
    default:
      return state;
  }
}
