
import { SET_USER, RESET_USER, SET_USER_CALLBACK } from "store/actions/types";

const initialState = {
  selectedUser: null,
  fetchUsersFunction: () => {},
}

export default function (state = initialState, action: any) {
  switch(action.type) {
    case SET_USER:
      return {
        ...state,
        selectedUser: action.payload
      }
    case RESET_USER:
      return {
        ...state,
        selectedUser: null
      }
    case SET_USER_CALLBACK:
      return {
        ...state,
        fetchUsersFunction: action.payload
      }
    default: 
      return state;
  }
}