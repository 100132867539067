import { create } from 'domain';
import BaseHttpService from './base-http.service';

const URL_PATH_PREFIX = 'treatment';

const TreatmentServices = () => {
  const baseHttp = BaseHttpService();

  /**
   * provide data for particular treatment on single treatment view
   *  treatments, takekares, diagnosis, indicators etc.
   * */
  const getTreatmentByIdFull = async (id: number) => {
    const url = `${URL_PATH_PREFIX}/${id}/full`;
    const res = await baseHttp.get(url);
    return res;
  };

  const getCaretakersByTreatment = async (id: number) => {
    const url = `${URL_PATH_PREFIX}/${id}/caretakers`;
    const res = await baseHttp.get(url);
    return res;
  };
  const getInstructionsByTreatment = async (id: number) => {
    const url = `${URL_PATH_PREFIX}/${id}/instructions`;
    const res = await baseHttp.get(url);
    return res;
  };

  /**
   * display all patients with active treatments
   */
  const getActiveMedicalTreatments = async (params: any = null) => {
    const url = params
      ? `${URL_PATH_PREFIX}/active-treatments/${params}`
      : URL_PATH_PREFIX + '/active-treatments';
    const res = await baseHttp.get(url);
    return res;
  };

  const create = async (data: any) => {
    const url = `/${URL_PATH_PREFIX}`;
    const res = await baseHttp.post(url, data);
    return res;
  };

  const formAssets = async (params: any = null) => {
    const url = params
      ? `${URL_PATH_PREFIX}/form-assets/${params}`
      : URL_PATH_PREFIX + '/form-assets';
    const res = await baseHttp.get(url);
    return res;
  };
  const closeTreatment = async (id: number, data: any = null) => {
    const url = `/${URL_PATH_PREFIX}/${id}/close`;

    const res = await baseHttp.post(url, data);
    return res;
  };
  const addTakeCareComment = async (data: any = null) => {
    const url = `/takecare_note`;

    const res = await baseHttp.post(url, data);
    return res;
  };
  const editTakeCareComment = async (id: number, data: any = null) => {
    const url = `takecare_note/${id}`;

    const res = await baseHttp.patch(url, data);
    return res;
  };

  const removeTakeCareComment = async (id: number) => {
    const url = `takecare_note/${id}`;

    const res = await baseHttp.remove(url);
    return res;
  };
  const addDiagnose = async (data: any) => {
    const url = `/treatment_diagnosis`;

    const res = await baseHttp.post(url, data);
    return res;
  };

  const removeDiagnose = async (id: number) => {
    const url = `treatment_diagnosis/${id}`;

    const res = await baseHttp.remove(url);
    return res;
  };
  const removeTreatmentIndicator = async (indicatorId: number) => {
    const url = `treatment_indicator/${indicatorId}`;

    const res = await baseHttp.remove(url);
    return res;
  };
  const updateDiagnose = async (id: number, data: any) => {
    const url = `treatment_diagnosis/${id}`;

    const res = await baseHttp.patch(url, data);
    return res;
  };
  const updateRefreshTime = async (id: number, data: any) => {
    const url = `treatment_indicator/${id}/edit-refreshtime`;

    const res = await baseHttp.patch(url, data);
    return res;
  };
  const changeActiveDiagnose = async (
    treatmentId: number,
    diagnoseId: number
  ) => {
    const url = `treatment/${treatmentId}/change-active-diagnose/${diagnoseId}`;

    const res = await baseHttp.get(url);
    return res;
  };
  const addNewIndicator = async (data: any) => {
    const url = `/treatment/new-indicator`;

    const res = await baseHttp.post(url, data);
    return res;
  };
  const addNewIndicatorValue = async (data: any) => {
    const url = `/doctor/add-new-indicator-value`;

    const res = await baseHttp.post(url, data);
    return res;
  };

  return {
    formAssets,
    getActiveMedicalTreatments,
    getTreatmentByIdFull,
    create,
    closeTreatment,
    addTakeCareComment,
    removeTakeCareComment,
    editTakeCareComment,
    addDiagnose,
    updateDiagnose,
    removeDiagnose,
    changeActiveDiagnose,
    removeTreatmentIndicator,
    addNewIndicator,
    updateRefreshTime,
    addNewIndicatorValue,
    getCaretakersByTreatment,
    getInstructionsByTreatment,
  };
};

export default TreatmentServices();
