import Layout from 'components/layout/AppLayout';
import { Headline } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';
const Home = () => {
  //console.clear()
  const { t } = useTranslation();
  const auth = useSelector((state: any) => state.auth);

  return (
    <Layout>
      <>
        <Headline title={'Pacijenti'} />
        <Paper></Paper>
      </>
    </Layout>
  );
};

export default Home;
