import BaseHttpService from './base-http.service';

const AuthService = () => {
  const baseHttp = BaseHttpService();

  const signin = async (userName: string, password: string) => {
    const result = await baseHttp.post(`/auth/login`, { userName, password });

    if (result.status === 200 || result.status === 201) {
      const accessToken = result.data.token;
      baseHttp.saveToken(accessToken);
    }
    return result;
  };

  const signup = async (username: string, password: string) => {
    return await baseHttp.post(`/auth/signup`, { username, password });
  };

  const signout = async () => {
    baseHttp.removeToken();
  };

  return {
    signin,
    signup,
    signout,
  };
};

export default AuthService;
