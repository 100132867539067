import React from 'react';
import { useStyles } from './styles';
import styles from './styles.module.scss';

type InputType = 'text' | 'textarea' | 'checkbox' | 'radio' | 'number' | 'date';
interface IProps {
  onChange?: (e: any) => any;
  name: string;
  value: any;
  label?: string;
  placeholder?: string;
  type?: InputType;
  error?: boolean;
  errorText?: string | any;
  checked?: boolean;
  disabled?: boolean;
  style?: Record<string, string>;
  fullWidth?: any;
}

const Input: React.FC<IProps> = ({
  onChange,
  name,
  value,
  label,
  placeholder,
  type = 'text',
  error = false,
  errorText,
  checked = false,
  disabled = false,
  style,
  fullWidth,
  ...props
}: IProps) => {
  const classes = useStyles();

  if (type === 'textarea') {
    return (
      <div
        className={`${classes.formGroup} ${styles.wrapper} formControl`}
        style={{ ...style, width: fullWidth && '100%' }}
      >
        {label && <label className={styles.label}>{label}</label>}

        <textarea
          className={classes.textarea}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          style={style}
        />

        {error && errorText && (
          <span className={classes.error}>{errorText}</span>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`${classes.formGroup} ${styles.wrapper} formControl`}
        style={{ width: fullWidth && '100%' }}
      >
        {label && <label className={styles.label}>{label}</label>}

        <input
          className={classes.input}
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          style={style}
          {...props}
          checked={checked}
        />
        {error && errorText && (
          <span className={classes.error}>{errorText}</span>
        )}
      </div>
    );
  }
};

export default Input;
