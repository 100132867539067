 export const queryParser = (query:any) => {
    let result: string | undefined = "?";
    let row:number = 0;
    if(typeof query === 'object' && Object.keys( query ).length > 0) {
          for(let key in query) {
            if(query[key] === undefined || query[key] === '') continue;
            const params = `${key}=${query[key]}`
            result +=   row === 0 ?  params : `&${params}`
      
            row++;
          }
          return result;
      }
  }