import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import styles from './style.module.scss';
import { Autocomplete, TextField } from '@mui/material';
import indicatorService from 'shared/services/indicator.service';
import { IndicatorTypeEnum } from 'interfaces/enum';
import { Input } from 'components/UI';
import { isEmpty } from 'lodash';

const validationNumeric = yup.object({
  numericValue: yup.number(),
  refreshTime: yup.number().required('Obavezno polje!'),
  comment: yup.string().max(255, 'title must be at less 255  characters'),
});

const validationDescriptive = yup.object({
  descriptiveIndicatorId: yup.number().required('Obavezno polje!'),
  refreshTime: yup.number().required('Obavezno polje!'),
  comment: yup.string().max(255, 'komentar ne sme biti veci od 255  karaktera'),
});

const initialNumericValue = {
  refreshTime: '',
  numericValue: '',
  comment: '',
};

const initialDescriptiveValue = {
  refreshTime: '',
  descriptiveIndicatorId: '',
  comment: '',
};

const NewIndicatorFormV2 = ({ setFormExpanded, onSubmitHandler }: any) => {
  const [selected, setSelected] = useState<any>(null);
  const [indicators, setIndicators] = useState([]);
  const [key, setKey] = useState((Math.random() + 1).toString(36).substring(7));

  useEffect(() => {
    fetchAllIndicators();
  }, []);

  useEffect(() => {
    setKey((Math.random() + 1).toString(36).substring(7));
  }, [selected]);

  const fetchAllIndicators = async () => {
    try {
      const result = await indicatorService.all(
        '?include=Valuetype&include=DescriptiveIndicator&include=IntervalScore'
      );
      setIndicators(result.data.rows);
    } catch (error) {}
  };

  // useEffect(() => {
  //   setKey((Math.random() + 1).toString(36).substring(7));
  // }, [selected]);

  return (
    <div className={styles.outerWrapper}>
      <h2>Novi indikator</h2>
      <div className={styles.divider}></div>
      <div className={styles.wrapper}>
        <div className='formControl' style={{ maxWidth: 380 }}>
          <label>Izaberite indikator koji želite da dodate:</label>
          <Autocomplete
            getOptionLabel={(option: any) => option.name}
            className={styles.autocomplete}
            options={indicators}
            onChange={(el: any, value) => {
              setSelected(value);
            }}
            style={{ width: '100%' }}
            renderInput={(params: any) => {
              return (
                <TextField
                  style={{ background: '#fff' }}
                  name='typeId'
                  {...params}
                  variant='standard'
                />
              );
            }}
          />
        </div>
        <Formik
          key={key}
          validationSchema={
            selected?.typeId === IndicatorTypeEnum.NUMERIC
              ? validationNumeric
              : validationDescriptive
          }
          initialValues={
            selected?.typeId === IndicatorTypeEnum.NUMERIC
              ? initialNumericValue
              : initialDescriptiveValue
          }
          onSubmit={async (values: any, { setSubmitting }) => {
            const formData: any = { ...values };

            // TODO: regulate descrtiptive indicator id error on backend
            formData.indicatorId = selected.id;
            // formData.descriptiveIndicatorId = null;
            if (selected?.typeId === IndicatorTypeEnum.DESCRIPTIVE) {
              formData.descriptiveIndicatorId = Number(
                values.descriptiveIndicatorId
              );
              const itemInSelected = selected.descriptiveIndicator.find(
                (item: any) => item.id === formData.descriptiveIndicatorId
              );
              formData.valueScore = itemInSelected.valueScore;
            }
            formData.refreshTime = Number(values.refreshTime);
            formData.numericValue = Number(values.numericValue);

            onSubmitHandler(formData, setKey, setFormExpanded);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              {selected && (
                <>
                  {/* <div className='formControl'>
                  <label>Naziv</label>
                  <div className={styles.inputStyle}>{selected.name}</div>
                </div> */}
                  {selected.typeId === IndicatorTypeEnum.NUMERIC && (
                    <>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <div
                            style={{ width: '160px' }}
                            className='formControl'
                          >
                            <label
                              htmlFor='refreshTime'
                              style={{ lineHeight: '20px' }}
                            >
                              Interval unosa
                            </label>
                            <Field
                              name='refreshTime'
                              type='text'
                              id='refreshTime'
                            />
                            {errors.refreshTime && (
                              <span style={{ color: 'red' }}>
                                {errors.refreshTime}
                              </span>
                            )}
                          </div>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            style={{ width: '160px' }}
                            className='formControl'
                          >
                            <label
                              htmlFor='numericValue'
                              style={{ lineHeight: '20px' }}
                            >
                              Trenutna vrednost
                            </label>
                            <Field
                              name='numericValue'
                              type='text'
                              id='numericValue'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='formControl'>
                        <label htmlFor='comment'>Instrukcija lekara</label>
                        <Field name='comment' as='textarea' id='comment' />
                      </div>
                      <div className={styles.btns}>
                        {/* <button
                        type='button'
                        className='btn'
                        onClick={() => {
                          setFormExpanded(false);
                        }}
                      >
                        Otkaži
                      </button> */}
                        <button
                          type='submit'
                          className='btn'
                          // disabled={!isEmpty(errors) || isEmpty(touched)}
                        >
                          Dodaj
                        </button>
                      </div>
                    </>
                  )}
                  {selected.typeId === IndicatorTypeEnum.DESCRIPTIVE && (
                    <>
                      <div style={{ display: 'flex' }}>
                        <div
                          className='formControl'
                          style={{ width: '160px', marginBottom: 0 }}
                        >
                          <label htmlFor='refreshTime'>Interval unosa</label>
                          <Field
                            name='refreshTime'
                            type='textarea'
                            id='refreshTime'
                          />
                          {errors.refreshTime && (
                            <span style={{ color: 'red' }}>
                              {errors.refreshTime}
                            </span>
                          )}
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='formControl'
                            style={{ width: '160px' }}
                          >
                            <label>Trenutna vrednost</label>
                            <Field name='descriptiveIndicatorId' as='select'>
                              <option value='0'>----------</option>
                              {selected.descriptiveIndicator.length > 0 &&
                                selected.descriptiveIndicator.map(
                                  (desc: any, ind: number) => {
                                    return (
                                      <option value={desc.id} key={ind}>
                                        {desc.descriptiveValue}
                                      </option>
                                    );
                                  }
                                )}
                            </Field>
                            {errors.descriptiveIndicatorId && (
                              <span style={{ color: 'red' }}>
                                {errors.descriptiveIndicatorId}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='formControl'>
                        <label htmlFor='comment'>Instrukcija lekara</label>
                        <Field name='comment' as='textarea' id='comment' />
                      </div>
                      <div className={styles.btns}>
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            setFormExpanded(false);
                          }}
                        >
                          Otkaži
                        </button>
                        <button
                          type='submit'
                          className='btn'
                          // disabled={!isEmpty(errors) || isEmpty(touched)}
                        >
                          Dodaj
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewIndicatorFormV2;
