import { useEffect, useState, useMemo } from "react";
import Layout from "components/layout/AppLayout";
import { Headline } from "components/UI";
import IndicatorForm from "./components/IndicatorForm";
import useUrlQueryHook from 'shared/hooks/useUrlQueryHook';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllIndicators,
  getIndicator,
  resetIndicator,
} from 'store/actions/indicatorActions';
import Table from 'components/common/Table';
import { IndicatorTypeEnum } from '../../interfaces/enum';
import Loader from 'components/UI/Loader';
import { setActiveModal, sidemodalExpand } from "store/actions/globalActions";
import EditIcon from 'components/UI/EditIcon';

const Indikatori = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});
  const { urlQuery, setUrlQueryHandler } = useUrlQueryHook({
    page: '1',
    include: 'Role;fields:name',
    // direction: 'DESC',
    // orderBy: 'BVHNummer',
  });
  const { indicators, isLoading, indicator, indicatorIsLoading } = useSelector(
    (store: any) => store.indicator || null
  );

  useEffect(() => {
    dispatch(getAllIndicators(setPagination));
  }, []);

  const headers = useMemo(() => {
    return {
      id: {
        headerName: 'R.b.',
        cellCallback: (params: any, index: number) => {
          return index + 1;
        },
      },
      name: {
        headerName: 'Naziv',
      },
      description: {
        headerName: 'Opis',
      },
      shortName: {
        headerName: 'Skraćenica',
      },
      unitOfMeasure: {
        headerName: 'J.m.',
      },
      ['type.name']: {
        headerName: 'Tip',
        cellCallback: (params: any) => {
          return params.typeId === IndicatorTypeEnum.DESCRIPTIVE
            ? 'Deskriptivni'
            : 'Numerički';
        },
      },
      [' ']: {
        headerName: '',
        cellCallback: (params: any, index: number) => {
          return (
            <div
              className={styles.edit}
              onClick={() => {
                dispatch(getIndicator(params.id));
                dispatch(setActiveModal("add-or-edit-new-indicator"));
                dispatch(sidemodalExpand(true));
              }}
            >
              <EditIcon />
            </div>
          );
        },
      },
    };
  }, []);

  return (
    <Layout>
      <>
        <Headline title={'Indikatori'} />
        <div>
          <div className={styles.indicatorWrapper}>
            <div style={{ position: "relative" }}>
            {<button className={styles.createNewBtn}  onClick={() => {
                  dispatch(resetIndicator());
                  dispatch(setActiveModal("add-or-edit-new-indicator"));
                  dispatch(sidemodalExpand(true));
                }}>
            Kreiraj novi indikator
          </button>}
              {isLoading
                ? <Loader />
                : indicators &&
                  indicators.length > 0 && (
                    <Table
                      data={indicators}
                      headers={headers}
                      columnClickHandler={(data: any) => {
                        //console.log(data);
                      }}
                      rowClickHandler={(data: any) => {
                        // dispatch(getIndicator(data.id));
                      }}
                      singlePageRedirection={true}
                      sortTableHandler={(data) => {
                        // console.log(data);
                        // setUrlQueryHandler(data);
                      }}
                      pagination={pagination}
                      changePage={(page) => {
                        // console.log(page);
                        setUrlQueryHandler({ page });
                      }}
                      customClass="indicatorsTable"
                    />
                  )}
        
            </div>
            {/* <div style={{ marginLeft: '20px' }}>
              <IndicatorForm indicator={indicator} />
            </div> */}
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Indikatori;
