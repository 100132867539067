const Diagnosis = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      fill="currentColor"
    >
      <metadata>
        {" "}
        Svg Vector Icons : http://www.onlinewebfonts.com/icon{" "}
      </metadata>
      <g>
        <path d="M784.2,544.8c-44.2,0-80.8,32.1-88.1,74.3c-24.1,13.1-57.4,49.2-57.4,149.1c0,156.5-71.2,158-110.6,158c-39.4,0-103.7-9.9-103.7-133l-0.5,0V557h39.4c175.8-59.3,181.9-281.1,181.9-281.1V127c0-80.5-56.1-79-56.1-79l-6.3-0.4c-2.4-0.7-5-1.1-7.7-1.1h-54.6c-3.2,0-6.2,0.6-9.1,1.6V37.3c0-15.1-12.2-27.3-27.3-27.3c-15.1,0-27.3,12.2-27.3,27.3v72.9c0,15.1,12.2,27.3,27.3,27.3c15.1,0,27.3-12.2,27.3-27.3V99.6c2.8,1,5.9,1.6,9.1,1.6h44.3c16.7,0,13.6,22.8,13.6,22.8v165.6c-1.5,158-141,212.7-141,212.7H334c0,0-139.4-54.7-141-212.7V124c0,0-3-22.8,13.6-22.8v-0.4c1.5,0.2,3,0.4,4.5,0.4h45.5v9.1c0,15.1,12.2,27.3,27.3,27.3c15.1,0,27.3-12.2,27.3-27.3V37.3c0-15.1-12.2-27.3-27.3-27.3c-15.1,0-27.3,12.2-27.3,27.3v9.1h-45.5c-1.6,0-3.1,0.1-4.5,0.4v-0.4L182.5,48c0,0-56.1-1.5-56.1,79v148.9c0,0,6.1,221.8,181.9,281.1h39.4v232.5c0,0-21.2,200.5,175.8,200.5c197,0,175.8-212.7,175.8-212.7s-2.3-71.4,13.5-88.9c16.3,21.7,42.2,35.7,71.4,35.7c49.4,0,89.4-40.1,89.4-89.7C873.6,585,833.6,544.8,784.2,544.8L784.2,544.8z M784.2,687.6c-29.3,0-53-23.8-53-53.1c0-29.3,23.7-53.1,53-53.1c29.2,0,53,23.8,53,53.1C837.2,663.8,813.4,687.6,784.2,687.6z" />
      </g>
    </svg>
  );
};

export default Diagnosis;
