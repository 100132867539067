import styles from './style.module.scss';
import CreatePatientV2 from 'components/common/ActivePatientsLayout/CreatePatientV2';

const UpdatePatientForm = ({ data }: any) => {
  return (
    <div className={styles.wrapper}>
      <CreatePatientV2 edit data={data} />
    </div>
  );
};

export default UpdatePatientForm;
