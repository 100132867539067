import React, { useState, useEffect, useMemo } from 'react';
import Moment from 'moment';
import UpdatePatient from 'components/common/UpdatePatient';
import AutoCompleteWithChip from 'components/common/AutoCompleteWithChip';
// import AccordionWrapper from "../../AcordionWrapp";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getAllDiagnoses } from 'store/actions/diagnosesActions';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/common/Table';
import { useNavigate } from 'react-router-dom';
import useUrlQueryHook from 'shared/hooks/useUrlQueryHook';
import * as treatmentActions from 'store/actions/treatmentActions';
import moment from 'moment';
import { IndicatorTypeEnum } from 'interfaces/enum';
import DeleteIcon from 'components/UI/DeleteIcon';
import EditIcon from 'components/UI/EditIcon';
import styles from './style.module.scss';
import SaveIcon from 'components/UI/SaveIcon';
import Input from 'components/UI/Input';
import ChartIcon from 'components/UI/ChartIcon';
import BasicModal from 'components/common/Modal/Modal';
import LineChart from 'components/common/Charts/LineChart';
import MultiLineChart from 'components/common/Charts/MultiLineChart';
import Loader from 'components/UI/Loader';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { setActiveModal, sidemodalExpand } from 'store/actions/globalActions';
import {
  toggleModal,
  handleModalReset,
} from 'store/actions/confirmModalActions';

interface IProps {
  indicators: any[];
  indicatorValues: any;
  setFormExpanded?: Function;
  formExpanded?: boolean;
}

function IndicatorsSectionV2({
  indicators,
  indicatorValues,
  setFormExpanded,
  formExpanded,
}: IProps) {
  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState<any>([]);
  const [descriptiveHistory, setDescriptiveHistory] = useState<any>([]);
  const diagnoses = useSelector((store: any) => store.diagnoses.diagnoses);
  const treatment = useSelector((store: any) => store.treatment.selected);
  const [selectedDiagnoseId, setSelectedDiagnoseId] = useState(null);
  const { urlQuery, setUrlQueryHandler } = useUrlQueryHook({
    page: '1',
    include: 'Role;fields:name',
    // direction: 'DESC',
    // orderBy: 'BVHNummer',
  });
  const [pagination, setPagination] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { confirmActionName, confirmed, values } = useSelector(
    (store: any) => store.confirmModal || null
  );

  const lastValueOfIndicator = (indicatorId: number, field: string = 'id') => {
    const currentIndicatorValue =
      indicatorValues && indicatorValues[indicatorId];
    if (typeof currentIndicatorValue === 'undefined') return false;
    if (
      typeof currentIndicatorValue !== 'object' ||
      currentIndicatorValue.length === 0
    )
      return false;
    const result = currentIndicatorValue[0];
    return result;
  };

  const lastValueTitle = (item: any) => {
    if (!item) return '-';
    if (item.indicator.typeId === IndicatorTypeEnum.NUMERIC) {
      return (item && item.numericValue) || '-';
    }
    if (item.indicator.typeId === IndicatorTypeEnum.DESCRIPTIVE) {
      return (
        (item &&
          item.descriptiveIndicator &&
          item.descriptiveIndicator.descriptiveValue) ||
        '-'
      );
    }
    return 'test';
  };

  useEffect(() => {
    if (confirmed && confirmActionName === 'deleteTreatmantIndicator') {
      dispatch(treatmentActions.removeTreatmentIndicator(values.id));
      dispatch(handleModalReset());
    }
  }, [confirmed]);

  const headers = useMemo(() => {
    return {
      id: {
        headerName: 'R.b.',
        // sortable: true,
        cellCallback: (params: any, index: number) => {
          return index + 1;
        },
      },
      ['naziv']: {
        headerName: 'Indikator',
        cellCallback: (params: any, index: number) => {
          return params.indicator.name;
        },
      },
      ['interval']: {
        headerName: 'Interval unosa',
        cellCallback: (params: any, index: number) => {
          return <EditInterval indicator={params} />;
        },
      },

      ['vrednost']: {
        headerName: 'Zadnja vrednost',
        cellCallback: (params: any, index: number) => {
          const lastIndicatorValueObj = lastValueOfIndicator(
            params.indicatorId
          );
          const lastValue = lastValueTitle(lastIndicatorValueObj);
          return (
            <EditIndicatorValue
              indicator={params}
              indicatorValues={indicatorValues}
            />
          );
        },
      },

      ['jm']: {
        headerName: 'J.m.',
        cellCallback: (params: any, index: number) => {
          return params.indicator.unitOfMeasure;
        },
      },
      ['zadnijiUnos']: {
        headerName: 'Zadnji unos',
        cellCallback: (params: any, index: number) => {
          const lastIndicatorValueObj = lastValueOfIndicator(
            params.indicatorId
          );
          const time =
            lastIndicatorValueObj && lastIndicatorValueObj.valueTime
              ? moment(lastIndicatorValueObj.valueTime).format(
                  'DD.MM.YYYY. HH:mm'
                )
              : '-';

          return time;
        },
      },

      ['sledeciUnos']: {
        headerName: 'Sledeći unos',
        cellCallback: (params: any, index: number) => {
          const nextTime = moment(params.nextEntryTime).format('x');
          const currentTime = moment().format('x');
          let time =
            nextTime > currentTime ? (
              moment(params.nextEntryTime).format('DD.MM.YYYY. HH:mm')
            ) : (
              <span
                style={{ color: '#fff', background: 'red', padding: '5px' }}
              >
                {moment(params.nextEntryTime).format('DD.MM.YYYY. HH:mm')}
              </span>
            );

          return time;
        },
      },
      ['skor']: {
        headerName: 'Skor rizika',
        cellCallback: (params: any, index: number) => {
          const lastIndicatorValueObj = lastValueOfIndicator(
            params.indicatorId
          );
          return lastIndicatorValueObj && lastIndicatorValueObj.valueScore;
        },
      },
      [' ']: {
        headerName: 'Period',
        cellCallback: (params: any, index: number) => {
          return indicatorValues[params.indicatorId] ? (
            <span
              className={styles.chart}
              onClick={() => {
                setOpen(true);
                if (params.indicator.typeId === IndicatorTypeEnum.NUMERIC) {
                  setChartData(indicatorValues[params.indicatorId]);
                  setDescriptiveHistory([]);

                  return;
                }
                setDescriptiveHistory(indicatorValues[params.indicatorId]);
                setChartData([]);
              }}
            >
              <ChartIcon />
            </span>
          ) : (
            ''
          );
        },
      },
      ['']: {
        headerName: 'Obriši',
        cellCallback: (params: any, index: number) => {
          return (
            <span
              className={styles.remove}
              onClick={() => {
                dispatch(
                  toggleModal(
                    true,
                    'Da li ste sigurni da želite obrisati indikator za ovaj tretman?',
                    'deleteTreatmantIndicator',
                    params
                  )
                );
              }}
            >
              <DeleteIcon />
            </span>
          );
        },
      },
    };
  }, [indicatorValues]); //eslint-disable-line

  const descriptiveTableHeaders = useMemo(() => {
    return {
      ['date']: {
        headerName: 'Datum',
        cellCallback: (params: any, index: number) => {
          return moment(params.valueTime).format('DD.MM.YYYY.');
        },
      },
      ['time']: {
        headerName: 'Vreme',
        cellCallback: (params: any, index: number) => {
          return moment(params.valueTime).format('HH:mm');
        },
      },
      ['value']: {
        headerName: 'Vrednost',
        cellCallback: (params: any, index: number) => {
          return (
            (params.descriptiveIndicator &&
              params.descriptiveIndicator.descriptiveValue) ||
            '-'
          );
        },
      },
      ['score']: {
        headerName: 'Skor',
        cellCallback: (params: any, index: number) => {
          return params.valueScore;
        },
      },
      ['comment']: {
        headerName: 'Komentar',
        cellCallback: (params: any, index: number) => {
          return (
            (params.descriptiveIndicator && params.caretakerComment) || '-'
          );
        },
      },
    };
  }, [indicatorValues]); //eslint-disable-line

  useEffect(() => {
    dispatch(getAllDiagnoses());
  }, []);

  const setChartDataHandler = (data: any) => {
    setChartData(data);
  };
  return (
    <div>
      {!formExpanded && (
        <button
          className={`${styles.addNewBtn} btn`}
          onClick={() => {
            // setFormExpanded && setFormExpanded(true);
            dispatch(setActiveModal('add-new-indicator-for-diagnosis'));
            dispatch(sidemodalExpand(true));
          }}
        >
          Dodaj novi indikator
        </button>
      )}
      {formExpanded && (
        <button
          className={`${styles.addNewBtn} btn`}
          onClick={() => {
            setFormExpanded && setFormExpanded(false);
          }}
        >
          Zatvori formu
        </button>
      )}
      {/* {chartData.length > 0 && <LineChart data={chartData} />} */}
      <BasicModal
        open={open}
        setOpen={setOpen}
        header={
          chartData && chartData.length > 0
            ? chartData[0].indicator.name
            : descriptiveHistory && descriptiveHistory.length > 0
            ? descriptiveHistory[0].indicator.name
            : ''
        }
      >
        {chartData.length > 0 ? (
          <LineChart data={chartData} />
        ) : descriptiveHistory && descriptiveHistory.length > 0 ? (
          <LineChart data={descriptiveHistory} />
        ) : (
          <Loader />
        )}
      </BasicModal>
      {indicators && indicatorValues && (
        <Table
          headers={headers}
          data={indicators}
          customClass={'treatmantIndicatorsTable'}
          columnClickHandler={(data: any) => {
            //console.log(data);
          }}
          rowClickHandler={(data: any) => {
            // console.log(data);
            // navigate("/treatment/" + data.id);
            //console.log(data);
          }}
          singlePageRedirection={true}
          sortTableHandler={(data) => {
            setUrlQueryHandler(data);
          }}
          pagination={pagination}
          changePage={(page) => {
            setUrlQueryHandler({ page });
          }}
        />
      )}
    </div>
  );
}

const EditInterval = ({ indicator }: any) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(indicator.refreshTime);
  const dispatch = useDispatch();

  return (
    <div
      className={styles.editable}
      onClick={() => {
        setActive(true);
      }}
    >
      {!active ? (
        <div style={{ cursor: 'pointer' }}>
          <EditIcon />
          <span>{indicator.refreshTime}</span>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type={'number'}
            value={value}
            style={{ textAlign: 'center' }}
            className={styles.indicatorInput}
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            name={'interval'}
          ></input>
          <div
            style={{
              marginLeft: 4,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              if (value) {
                dispatch(
                  treatmentActions.updateRefreshTime(indicator.id, value)
                );
              }
              setActive(false);
            }}
          >
            <SaveIcon />
          </div>
        </div>
      )}
    </div>
  );
};

const EditIndicatorValue = ({ indicator, indicatorValues }: any) => {
  const lastValueTitle = (item: any) => {
    if (!item) return '-';
    if (item.indicator.typeId === IndicatorTypeEnum.NUMERIC) {
      return (item && item.numericValue) || '-';
    }
    if (item.indicator.typeId === IndicatorTypeEnum.DESCRIPTIVE) {
      return (
        (item &&
          item.descriptiveIndicator &&
          item.descriptiveIndicator.descriptiveValue) ||
        '-'
      );
    }
    return 'test';
  };
  const lastValueOfIndicator = (indicatorId: number, field: string = 'id') => {
    const currentIndicatorValue =
      indicatorValues && indicatorValues[indicatorId];
    if (typeof currentIndicatorValue === 'undefined') return false;
    if (
      typeof currentIndicatorValue !== 'object' ||
      currentIndicatorValue.length === 0
    )
      return false;
    const result = currentIndicatorValue[0];
    return result;
  };

  const lastIndicatorValueObj = lastValueOfIndicator(indicator.indicatorId);
  const lastValue = lastValueTitle(lastIndicatorValueObj);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(lastValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (indicator.indicator.typeId === IndicatorTypeEnum.DESCRIPTIVE) {
      const selected = indicator.indicator.descriptiveIndicator.find(
        (item: any) => lastValue === item.descriptiveValue
      );
      if (selected) {
        setValue(selected.id);
      }
    }
  }, []);

  const handleChange = (e: any) => {
    // console.log(e.target.value);
    setValue(e.target.value);
  };
  return (
    <div
      className={styles.editable}
      onClick={() => {
        setActive(true);
      }}
    >
      {!active ? (
        <div style={{ cursor: 'pointer' }}>
          <EditIcon />
          <span>{lastValue}</span>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {indicator.indicator.typeId === IndicatorTypeEnum.DESCRIPTIVE && (
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={value}
              label='Descriptive indicator'
              onChange={handleChange}
            >
              <MenuItem>-</MenuItem>
              {indicator.indicator.descriptiveIndicator.map(
                (item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.descriptiveValue}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          )}
          {indicator.indicator.typeId === IndicatorTypeEnum.NUMERIC && (
            <input
              type={'number'}
              value={value}
              style={{ textAlign: 'center' }}
              className={styles.indicatorInput}
              onChange={(e: any) => {
                setValue(e.target.value);
              }}
              name={'interval'}
            />
          )}
          <div
            style={{
              marginLeft: 4,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              setActive(false);
              const selectedDescriptiveIndicator =
                indicator.indicator.descriptiveIndicator.find((item: any) => {
                  return item.id === Number(value);
                });
              let data = null;
              if (
                indicator.indicator.typeId === IndicatorTypeEnum.DESCRIPTIVE
              ) {
                if (
                  !selectedDescriptiveIndicator ||
                  typeof value === 'undefined'
                )
                  return;
                data = {
                  descriptiveIndicatorId: value,
                  treatmentId: indicator.treatmentId,
                  indicatorId: indicator.indicatorId,
                  numericValue: null,
                  valueScore: selectedDescriptiveIndicator.valueScore,
                };
              }
              if (indicator.indicator.typeId === IndicatorTypeEnum.NUMERIC) {
                data = {
                  descriptiveIndicatorId: null,
                  treatmentId: indicator.treatmentId,
                  indicatorId: indicator.indicatorId,
                  numericValue: Number(value),
                };
              }
              dispatch(treatmentActions.addIndictorValue(data));
            }}
          >
            <SaveIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default IndicatorsSectionV2;
