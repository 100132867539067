import styles from './styles.module.scss';

const Loader = () => {
  return (
    <div className={styles.box}>
      <div className={styles.loader}>

      </div>
    </div>
  )
}

export default Loader;