// Components
import Home from '../views/home';
import Projects from '../views/_resources/users';
import UserForms from '../views/_resources/users/FormsView';
import { ChangePassword } from '../views/auth';
import About from 'views/about';
import PacijentiView from 'views/pacijenti';
import DijangozeView from 'views/dijagnoze';
import IndikatoriView from 'views/indikatori';
import PreglediView from 'views/pregledi';
import { TreatmentSingleView } from 'views/treatment';
import UsersView from 'views/_resources/users/UsersView';
// Icons
import LayersIcon from '@mui/icons-material/Layers';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { USER_ROLE } from 'interfaces/enum';
import AdminHome from '../views/admin/Home';
export const protectedRoute = {
  // adminHome: {
  //   path: '/admin',
  //   element: AdminHome,
  //   roles: '*',
  //   dashboard: true,
  //   icon: DashboardIcon,
  //   title: 'menu.dashboard',
  // },
  // doctorHome: {
  //   path: '/',
  //   element: Home,
  //   roles: '*',
  //   dashboard: true,
  //   icon: DashboardIcon,
  //   title: 'menu.dashboard',
  // },
  usersSingle: {
    path: '/osoblje/:id',
    element: UserForms,
    roles: '*',
  },
  about: {
    path: '/about',
    element: About,
    roles: [USER_ROLE.ADMIN, USER_ROLE.MAIN_DOCTOR],
    title: 'Novi Pacijent',
  },

  usersCreate: {
    path: 'osoblje/create',
    element: UserForms,
    roles: [USER_ROLE.ADMIN, USER_ROLE.DOCTOR, USER_ROLE.MAIN_DOCTOR],
  },
  users: {
    path: '/osoblje',
    element: UsersView,
    roles: [USER_ROLE.ADMIN, USER_ROLE.DOCTOR, USER_ROLE.MAIN_DOCTOR],
    dashboard: true,
    title: 'Osoblje',
  },
  changePassword: {
    path: '/change-password',
    element: ChangePassword,
    roles: '*',
  },
  newChengPassword: {
    path: '/change-password',
    element: ChangePassword,
    roles: [USER_ROLE.ADMIN],
    dashboard: true,
    icon: LayersIcon,
    title: 'menu.projects',
  },

  pacijenti: {
    path: '/pacijenti',
    element: PacijentiView,
    roles: [USER_ROLE.ADMIN, USER_ROLE.DOCTOR, USER_ROLE.MAIN_DOCTOR],
    dashboard: true,
    icon: LayersIcon,
    title: 'Pacijenti',
  },
  dijagnoze: {
    path: '/dijagnoze',
    element: DijangozeView,
    roles: [USER_ROLE.ADMIN, USER_ROLE.DOCTOR, USER_ROLE.MAIN_DOCTOR],
    dashboard: true,
    title: 'Dijagnoze',
  },
  indikatori: {
    path: '/indikatori',
    element: IndikatoriView,
    roles: [USER_ROLE.ADMIN, USER_ROLE.DOCTOR, USER_ROLE.MAIN_DOCTOR],
    dashboard: true,
    title: 'Indikatori',
  },
  pregledi: {
    path: '/pregledi',
    element: PreglediView,
    roles: [USER_ROLE.ADMIN, USER_ROLE.DOCTOR, USER_ROLE.MAIN_DOCTOR],
    dashboard: true,
    title: 'Pregledi',
  },
  singleTreatment: {
    path: '/treatment/:id',
    element: TreatmentSingleView,
    roles: [USER_ROLE.ADMIN, USER_ROLE.DOCTOR, USER_ROLE.MAIN_DOCTOR],
    dashboard: true,
    title: 'Tretman',
  },
};
