import { useRef } from "react";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "shared/hooks/useOnClickOutside";
import { sidebarExpand, sidemodalExpand } from "store/actions/globalActions";
import { motion, AnimatePresence } from "framer-motion";
import CloseIcon from 'components/UI/CloseIcon'

const SideModal = ({ children }: { children?: any }) => {
  const content = useRef<any>(null);
  const dispatch = useDispatch();
  const { sidemodalExpanded } = useSelector((store: any) => store.global);
  useOnClickOutside(content, () => dispatch(sidemodalExpand(false)));

  return (
    <AnimatePresence>
      {sidemodalExpanded && (
        <motion.div
          className={`${styles.wrapper}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            initial={{ x: '110%' }}
            animate={{ x: 0 }}
            exit={{ x: '110%' }}
            className={styles.wrapper__inner}
            transition={{ duration: .4 , ease: [.52,.01,0,1], delay: 0.1 }}
            ref={content}
          >
            <div className={styles.closeBtnWrapper}>
              <div onClick={() => {
                 dispatch(sidemodalExpand(false));
              }}>
                <CloseIcon />
              </div>
            </div>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SideModal;
