const Indicator = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      fill="currentColor"
      viewBox="0 0 505.394 505.395"
    >
      <g>
        <g>
          <path
            d="M267.681,349.456l-6.47-2.092V105.862c0-5.225-4.77-9.448-10.66-9.448s-10.651,4.217-10.651,9.448v242.484l-5.925,2.482
       c-18.22,7.589-29.991,25.238-29.991,44.957c0,26.87,21.849,48.717,48.71,48.717c26.862,0,48.713-21.841,48.713-48.717
       C301.408,374.601,287.849,355.982,267.681,349.456z"
          />
          <path
            d="M307.496,300.846v-243C307.496,25.948,281.547,0,249.65,0c-31.897,0-57.846,25.948-57.846,57.846v246.783
       c-30.136,20.073-48.71,53.989-48.71,91.156c0,60.443,49.171,109.609,109.6,109.609c60.434,0,109.606-49.166,109.606-109.609
       C362.294,355.935,340.986,320.115,307.496,300.846z M252.694,474.953c-43.713,0-79.157-35.446-79.157-79.168
       c0-32.911,20.114-61.141,48.713-73.069V57.846c0-15.12,12.262-27.399,27.399-27.399c15.134,0,27.399,12.28,27.399,27.399v98.949
       h-8.375v12.188h8.375v31.209h-8.375v12.176h8.375v34.247h-8.375v12.176h8.375v61.697c31.788,10.29,54.799,40.086,54.799,75.297
       C331.848,439.501,296.414,474.953,252.694,474.953z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Indicator;
