import React from 'react';
import { Button, colors } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    content: {
      minWidth: '300px',
      maxWidth: '350px',
      padding: '10px 20px',
    },

    title: {
      padding: '5px 20px',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '18px',
    },
    actions: {
      padding: '0 10px 20px 10px',
      display: 'flex',
      justifyContent: 'center',
    },
    btns: {
      flex: '50%',
    },
    btnClose: {
      flex: '50%',
      color: colors.orange[600],
      border: `1px solid ${colors.orange[600]}`,
    },
  };
});

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: string;
  description?: string;
  confirmHandler?: (status: boolean) => void;
}
export default function AlertDialog({
  open,
  setOpen,
  title,
  description,
  confirmHandler,
}: IProps) {
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(false);
    if (confirmHandler) confirmHandler(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (confirmHandler) confirmHandler(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={'sm'}
      >
        <p id='alert-dialog-title' className={classes.title}>
          {title ? title : 'Confirm?'}
        </p>
        <DialogContent className={classes.content}>
          {description && (
            <DialogContentText id='alert-dialog-description'>
              {description}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            className={classes.btnClose}
            onClick={handleClose}
            variant='outlined'
            color='secondary'
          >
            Disagree
          </Button>
          <Button
            className={classes.btns}
            onClick={handleClickOpen}
            variant='outlined'
            color='primary'
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
