import Moment from 'moment';
import styles from './style.module.scss';
import EditIcon from 'components/UI/EditIcon';
import Loader from 'components/UI/Loader';
import { useDispatch } from 'react-redux';
import { sidemodalExpand, setActiveModal } from 'store/actions/globalActions';
import CopyIcon from 'components/UI/CopyIcon';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifierAction';

const PatientInfoSectionV2 = ({ data }: any) => {
  const dispatch = useDispatch();
  const fullName = data?.firstName + ' ' + data?.lastName;
  const gender = data?.gender === 'M' ? 'Muški' : 'Ženski';
  const years =
    // @ts-ignore
    new Date().getFullYear() - Moment(data?.dateOfBirth).format('YYYY');
  const dateOfActivation = Moment(data?.activaionTime).format(
    'DD.MM.YYYY. HH:mm'
  );
  return (
    <div className={styles.wrapper}>
      {data ? (
        <>
          <div>
            <div className={styles.flexNew}>
              <h1>{fullName}</h1>
              <span>, {years} god.</span>
              <span>, {gender}</span>{' '}
              <span
                className={styles.editIcon}
                onClick={() => {
                  dispatch(setActiveModal('edit-patient-form'));
                  dispatch(sidemodalExpand(true));
                }}
              >
                <EditIcon />
              </span>
            </div>
            <div className={styles.flexNew} style={{ marginTop: 6 }}>
              <label>Aktiviran:</label>
              <span>{dateOfActivation}</span>
              <div className={styles.codeRow}>
                <span>, Aktivacioni kod: </span>
                <span
                  style={{
                    display: 'inline-block',
                    marginLeft: 4,
                    fontSize: 24,
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(data?.activationCode);
                    dispatch(
                      enqueueSnackbarAction({
                        message: 'Kod uspešno kopiran.',
                        options: { variant: 'success' },
                      })
                    );
                  }}
                >
                  {data?.activationCode}
                  <CopyIcon />
                </span>
              </div>
            </div>
          </div>
          <button
            className={`${styles.closeTreatmentBtn} btn`}
            style={{ fontSize: '25px' }}
            onClick={() => {
              dispatch(setActiveModal('close-treatment-form'));
              dispatch(sidemodalExpand(true));
            }}
          >
            Zaključi lečenje
          </button>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PatientInfoSectionV2;
